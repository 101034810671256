import { collection, doc, getFirestore, setDoc, writeBatch } from 'firebase/firestore';
import { FlatList, StyleSheet } from 'react-native';
import { Button, Chip, FAB, IconButton, TextInput } from 'react-native-paper';
import { useSelector } from 'react-redux';
import { useContext } from 'react'

import { Text, View } from '../components/Themed';
import { useEffect, useState } from 'react';
import PaymentRow from '../components/PaymentRow';
import dayjs from 'dayjs';
import Prferances from '../constants/Preferances';

import { RootStackScreenProps } from '../types';
import BackButton from '../components/BackButton';
import Global from '../constants/global';
import Hemberger from '../components/Hemberger';


let baselist: [] = []

export default function Payments({ route, navigation }: RootStackScreenProps<'Payments'>) {
    const ulist = useSelector(state => state.list.Payments)
    const olist = useSelector(state => state.list.Users)
    const plist = useSelector(state => state.list.Properties)
    const accounts = useSelector(state => state.list.Entries)
    const [search, setSearch] = useState("");
    const [list, setList] = useState({ list: [], years: [], choice: "0" });

    const [state, setState] = useState({ open: false });

    const onStateChange = ({ open }) => setState({ open });

    const { open } = state;

    function apply(p, i) {
        const db = getFirestore()
        const batch = writeBatch(db)
        const l = accounts.filter(e => e.headId == p.id)
        try {
            l.map(e => {
                const ref = doc(db, "Entries", e.id)
                batch.delete(ref)
                console.log("deleted entries")
            })
            const appliedTo: [] = []
            console.log("prpecssing payment", p)
            p.properties.map(im => {
                let dt = p.date
                try {
                    dt = (p.date + "").length > 10 ? parseInt((p.date + "").substring(0, 10)) : p.date
                } catch (e) { }
                const accont = {
                    property: im.pno,                                   // Property No
                    amount: i[im.pno] ? i[im.pno] : 0,
                    side: 1,                   // 0 is expences-Dr // 1 is Revenue cr

                    edited: false,
                    charge: false,
                    headName: p.receiptNo,
                    remarks: p?.remarks ? p.remarks : "",
                    headId: p.id,
                    year: p.year,
                    date: dt ? dt : 0,
                    stamp: dayjs().unix()
                }
                if (accont.amount > 0) {
                    const reff = doc(collection(db, "Entries"));
                    accont.id = reff.id
                    batch.set(reff, accont, { merge: true })
                }
                appliedTo.push({ Property: im.pno, Amount: i[im.pno] ? i[im.pno] : 0 })
            })
            const Preff = doc(db, "Payments", p.id);
            batch.set(Preff, { appliedTo: appliedTo, allocated: true }, { merge: true })
            batch.commit()
            alert("Done")

        } catch (e) { console.log("apply err", e) }

    }

    useEffect(() => {
        const e: [] = []
        if (ulist && plist && olist) {
            // const yrs1 = [...new Set(ulist.map(item => item.year))];
            // const yrs2 = yrs1.filter(item => (item !== "0"))
            // const yrs = yrs2.sort((a, b) => parseInt(a.replace("-", "")) - parseInt(b.replace("-", "")))
            const yrs = Global.years
            ulist.map(u => {
                const ele = { ...u }
                let apto = ""
                ele?.appliedTo?.map(ap => apto = apto + ap.Property)
                let search = ele.amount + ele.remarks + ele.receiptNo + apto
                if (ele?.paidBy) {
                    const got = olist.find(on => on.id == ele.paidBy)
                    if (got) {
                        search = search + got.name + got.mobile
                        ele.user = got
                        const gotp = plist.filter(on => on.currentOwner == ele.paidBy)
                        ele.properties = gotp
                    }
                }
                ele.search = search.toLowerCase()
                e.push(ele)

            })
            const elex = e.sort((a, b) => b.stamp - a.stamp)
            baselist = [...elex]
            setList(p => ({ ...p, years: yrs, list: elex }))
        }
    }, [ulist, plist, olist])

    useEffect(() => {
        if (list.choice == "0") { // all
            setList(p => ({ ...p, list: [...baselist] }))
        } else if (list.choice == "1") {  // failed
            const flist = baselist.filter(e => (e?.status !== 11 && e?.status !== 0))
            setList(p => ({ ...p, list: flist }))
        } else if (list.choice == "2") {  // unalloc
            const flist = baselist.filter(e => (e?.allocated == undefined || e?.allocated == false))
            setList(p => ({ ...p, list: flist }))
        } else if (list.choice == "3") {  // unalloc
            const flist = baselist.filter(e => (e?.status == 0))
            setList(p => ({ ...p, list: flist }))
        } else {
            const flist = baselist.filter(e => e.year == list.choice)
            setList(p => ({ ...p, list: flist }))
        }
    }, [list.choice])



    useEffect(() => {
        let l: [] = []
        if (search) {
            const s = search.toLowerCase()
            l = baselist.filter(e => e?.search?.includes(s))
            setList(p => ({ ...p, list: l }))
        } else {
            setList(p => ({ ...p, list: baselist }))
        }
    }, [search])

    const pref = useContext(Prferances)

    return (
        <View style={{ flexDirection: "row" }}>
            <Hemberger navigation={navigation} />
            <View style={{ flex: 1, alignItems: "stretch" }}>
                <View style={{ flexDirection: "row", alignSelf: "stretch" }}>

                    <Chip selected={list.choice == "0"} style={{ margin: 5, marginLeft: 15 }} onPress={() => setList(p => ({ ...p, choice: "0" }))}>All </Chip>
                    <Chip selected={list.choice == "3"} style={{ margin: 5, marginLeft: 15 }} onPress={() => setList(p => ({ ...p, choice: "3" }))}>Pending</Chip>
                    <Chip selected={list.choice == "1"} style={{ margin: 5, marginLeft: 15 }} onPress={() => setList(p => ({ ...p, choice: "1" }))}>UnSuccessfull</Chip>
                    <Chip selected={list.choice == "2"} style={{ margin: 5, marginLeft: 15 }} onPress={() => setList(p => ({ ...p, choice: "2" }))}>Unallocated</Chip>
                    {
                        list?.years?.map(y => <Chip selected={list.choice == y} style={{ margin: 5 }} onPress={() => setList(p => ({ ...p, choice: y }))}>{y}</Chip>)
                    }
                </View>
                {/* <Button onPress={() => allocate()}>Delete</Button> */}
                <View style={{ flexDirection: "row", alignSelf: "stretch" }}>
                    <TextInput
                        autoFocus
                        placeholder="Search..."
                        style={{ alignSelf: "stretch", flex: 1 }}
                        dense
                        onChangeText={(text) => {
                            setSearch(text)
                        }}
                    />
                    {/* <IconButton icon='eye' />
                <Text>{list.list?.filter(e => e.year == list.selection)?.length}</Text> */}
                </View>
                <FlatList
                    data={list.list}
                    renderItem={u =>
                        <PaymentRow committee={pref?.current?.type == "Committee"} super={pref?.current?.isSuperAdmin} navigation={navigation} info={u.item} index={u.index} apply={(p, i) => apply(p, i)} />} />


            </View>
            {/* <Button onPress={() => upload()}>Upload</Button> */}
            {pref?.current?.isSuperAdmin && <FAB.Group
                open={open}
                icon={'plus'}
                actions={[
                    {
                        icon: 'file',
                        label: 'DD',
                        onPress: () => {
                            navigation.navigate('PaymentForm', { data: { paymentMode: 4 } })
                        },
                    },
                    {
                        icon: 'cash-fast',
                        label: 'Online',
                        onPress: () => {
                            navigation.navigate('PaymentForm', { data: { paymentMode: 3 } })
                        },
                    },
                    {
                        icon: 'checkbook',
                        label: 'Cheque',
                        onPress: () => {
                            navigation.navigate('PaymentForm', { data: { paymentMode: 2 } })
                        }

                    },
                    {
                        icon: 'cash-check',
                        label: 'Cash',
                        onPress: () =>
                            navigation.navigate('PaymentForm', { data: { paymentMode: 1 } })
                    },
                ]}
                onStateChange={onStateChange}
                onPress={() => {
                    if (open) {
                        // do something if the speed dial is open
                    }
                }}
            />
            }
        </View>

    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
