import { Dimensions, Pressable } from "react-native"
import { View, Text } from "./Themed"
import Modal from "react-native-modal"
import { IconButton, useTheme } from "react-native-paper"
import DIcon from "./DIcon"

interface options {
    isVisible: boolean,
    onBackdropPress: any,
    in: string,
    out: string
    style: {}
}
export default function DModal(op) {
    const theme = useTheme()
    const sty = op.center ? { justifyContent: "center", alignItems: "center" } : {}
    return <Modal
        backdropOpacity={0.5}
        propagateSwipe
        style={{ margin: 0 }}
        isVisible={op.isVisible}
        onBackdropPress={() => op.onBackdropPress ? op.onBackdropPress() : op.close ? op.close() : () => { }}
        animationIn={op.center ? "zoomIn" : op.in ? op.in : "slideInUp"}
        animationOut={op.center ? "zoomOut" : op.out ? op.out : "slideOutDown"}>
        <View style={{
            width: op.center ? undefined : op.width ? op.width : Dimensions.get("window").width,
            height: op.height ? op.height: undefined,
            bottom: op.center ? undefined : 0,
            position: op.center ? "relative" : op.override ? "relative" : "absolute",
            backgroundColor: theme.colors.secondaryContainer,
            padding: 20, ...sty, ...op.style
        }}>
            {op.showIcon !== undefined && <DIcon icon={op.showIcon == true ? "check-circle" : "close-circle"} size={80} color={op.showIcon == true ? "green" : "red"} />}
            {typeof op.close == "function" && <IconButton style={{ zIndex: 9000, position: "absolute", top: 0, right: 5 }} iconColor="red" icon={"close"} size={24} onPress={() => op.close()} />}
            {op.children}
            {typeof op.close == "function" && op.closeRow &&
                <Pressable style={({ pressed }) => [{ backgroundColor: pressed ? 'rgb(210, 230, 255)' : 'white' },]}
                    onPress={() => op.close()}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <DIcon inverse name="close" />
                        <Text style={{ padding: 10, fontSize: 16 }}>Close</Text>
                    </View>
                </Pressable>

            }
            {op.nearClose && <>
                {op.nearClose}
            </>

            }
        </View>
    </Modal >

}