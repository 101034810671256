import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import Global from "../constants/global"
import { useSelector } from 'react-redux';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import DAvatar from './DAvatar'
import { Text, Card, IconButton, Portal, Dialog, Button, Divider, HelperText } from 'react-native-paper';
import Modal from "react-native-modal"
import Toast from 'react-native-root-toast';
import DModal from './DModal';

interface option {
    info: any
    apply(charge: any): void
    deleteCharge(charge: any): void
    refreash(): void
    entries: void,
    properties: void,
    amount: number,
    navigation: any
    extra: any
    index: number
}
const ChargeRow = (op: option) => {
    const [isOpen, setOpen] = useState(false);
    const [list, showList] = useState(0);
    function onClose() { setOpen(false) }
    const col = Global.getColor(op.index)
    const [deleConfirmation, setDeleConfirmation] = useState(false);

    const nav = useNavigation()

    function navigate() {
        return (op.navigation ? op.navigation : nav)
    }
    return (
        <View style={{ flexDirection: "row", marginTop: 8, marginBottom: 8, flex: 1 }}>
            <DAvatar title={op.info.description} color={col} file={op.info?.dp} type={Global.ROW_DP} />
            <TouchableOpacity onPress={() => setOpen(true)} style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", marginLeft: 4, alignSelf: "stretch" }}>
                    <View style={{ flex: 1, flexDirection: "row" }}>
                        <Text variant="bodyLarge" style={{ color: col, fontWeight: "bold" }}>{op.info.description}</Text>
                    </View>
                    <Text style={{ color: col }}>{Global.RS + " " + op.info.rate}</Text>
                    <Text style={{ paddingLeft: 13, paddingRight: 13, fontSize: 18 }}>⌵</Text>
                </View>
                <View style={{ flex: 1, flexDirection: "row" }}>
                    <Text style={{ color: col, flex: 1 }}>{op.info.isClosed == 0 ? "All - " : (op.info.isClosed == 1 ? "Open -" : "Close -")}{Global.getRuleName(op.info.selection)} {op.info.input ? " 👉 " + op.info.input : ""}</Text>
                    <Text style={{ color: col, fontWeight: "bold" }}>{Global.RS + " " + op.amount}</Text>
                </View>
                <Text style={{ color: col }}>{op.info.remakrs}</Text>
                <Divider />
            </TouchableOpacity>

            <DModal
                isVisible={isOpen}
                onBackdropPress={() => setOpen(false)}
                style={{ flex: 1, alignSelf: "stretch" }}

                close={() => setOpen(false)}>
                <View style={{ justifyContent: "center" }}>
                    <Text style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{op.info.description}...</Text>
                    <TouchableOpacity onPress={() => op.apply(op.info)}>
                        <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                            <IconButton icon="cog-play-outline" />
                            <Text style={{ paddingLeft: 10 }}>Apply {op.info?.applied ? "" : "(pending to apply)"}</Text>
                            {
                                op.info.applied ? <HelperText >this charge is previously applied in current year, so all previous entries will be removed and new entries will be created </HelperText> : <></>
                            }

                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => op.refreash(op.info)}>
                        <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                            <IconButton icon="refresh" />
                            <Text style={{ paddingLeft: 10 }}>Refreash balances of all Properties for all years</Text>

                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { setDeleConfirmation(true); onClose(); }}>
                        <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                            <IconButton icon="delete" />
                            <Text style={{ paddingLeft: 10 }}>{"Delete All Entries of this charge"}</Text>
                        </View>
                    </TouchableOpacity>
                    <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity style={{ flex: 1 }} onPress={() => { showList(1); onClose(); }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton icon="check" />
                                <Text style={{ paddingLeft: 10 }}>{"Applied to"}</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flex: 1 }} onPress={() => { showList(2); onClose(); }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton icon="cancel" />
                                <Text style={{ paddingLeft: 10 }}>{"Not Applied to"}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>
                    <TouchableOpacity onPress={() => {
                        onClose();
                        op.navigation.navigate('ChargeForm', { data: op.info })
                    }}  >
                        <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                            <IconButton icon="pencil-outline" />
                            <Text style={{ paddingLeft: 10 }} >Edit Charge</Text>
                        </View>
                    </TouchableOpacity>
                    {/* <TouchableOpacity onPress={() => {
                            onClose();
                            console.log(op.info.remarks, "List of properties who does not have this charge")
                            op.properties.map((p, i) => {
                                const got = op.entries.find(a => a.property == p.pno && a.year == op.info.year)
                                if (!got) {
                                    console.log(p.pno)
                                }
                            })
                        }}  >
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton icon="pencil-outline" />
                                <Text style={{ paddingLeft: 10 }} >See Which Property not has this charge</Text>
                            </View>
                        </TouchableOpacity> */}

                </View>
            </DModal>
            <DModal
                close={() => setDeleConfirmation(false)}
                style={{ flex: 1, alignSelf: "stretch" }}
                isVisible={deleConfirmation}  >
                <View style={{ justifyContent: "center" }}>
                    <Text variant='headlineSmall'>Delete Charge ({op.info.remarks})? </Text>
                    <Text style={{ padding: 10 }}>It is highly advisable to Take Backup before proceding</Text>
                    <Text style={{ padding: 10 }}>Note : Beaware, Deleting Charge will delete it's entries with all properties. Balances of all properties will be refreshed</Text>

                    <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent" }}>
                        <Button style={{ flex: 1 }} onPress={() => setDeleConfirmation(false)}>Cancel</Button>
                        <Button style={{ flex: 1 }} onPress={() => op.deleteCharge(op.info)}>Delete Charge & Its Accounting Entries</Button>
                    </View>
                </View>

            </DModal>
            <DModal
                close={() => showList(0)}
                style={{ flex: 1, alignSelf: "stretch" }}
                isVisible={list !== 0}>
                <View style={{ justifyContent: "center" }}>
                    <Text variant='headlineSmall'>Showing {list == 1 ? "Applied Entries" : " Skipped Entries"} </Text>
                    <ScrollView style={{ flex: 1, maxHeight: 500 }}>
                        <View style={{ alignSelf: "stretch", flex: 1 }}>
                            <View style={{ flexDirection: "row", alignSelf: "stretch", flex: 3 }}>
                                <Text style={{ flex: 1, textAlign: "center" }}>Property</Text>
                                {list == 2 && <Text style={{ flex: 1, textAlign: "center" }}>Rule</Text>}
                                {list == 2 && <Text style={{ flex: 1, textAlign: "center" }}>Open/Rent</Text>}
                                <Text style={{ flex: 1, width: 70, textAlign: "center" }}>Saved</Text>
                            </View>
                            {
                                op.info.appliedTo?.filter(f => f.action !== "Deleted"
                                    && (list == 1 ?
                                        f?.stages?.find(pp => pp?.saved)?.saved :
                                        !f?.stages?.find(pp => pp?.saved)?.saved)
                                ).map((e, ii) => {
                                    return <View style={{ flexDirection: "row", alignSelf: "stretch", flex: 1 }}>
                                        <View style={{ width: 400, flex: 1, alignSelf: "stretch" }}>
                                            <Text >{ii + 1}.    {e.property}</Text>
                                        </View>
                                        {list == 2 ?
                                            <View style={{ flexDirection: "row", alignSelf: "stretch", flex: 3 }}>
                                                <Text style={{ flex: 1, textAlign: "center" }}>
                                                    {e?.stages?.find(ff => ff?.rule) ? "✓" : "X"}
                                                </Text>
                                                <Text style={{ flex: 1, textAlign: "center" }}>
                                                    {e?.stages?.find(ff => ff?.closeRent) ? "✓" : "X"}
                                                </Text>
                                                <Text style={{ flex: 1, width: 70, textAlign: "center" }}>
                                                    {e?.stages?.find(ff => ff?.saved) ? "✓" : "X"}
                                                </Text>
                                            </View>
                                            : <Text style={{ flex: 1, width: 70, textAlign: "center" }}>✓</Text>

                                        }
                                    </View>
                                })
                            }
                        </View>
                    </ScrollView>
                </View>
            </DModal >

        </View >)
}


const styles = StyleSheet.create({
    item: {
        textColor: 'white',
        fontWeight: '900',
        flex: 1,
        alignItems: "center",
        marginVertical: 2,
        marginHorizontal: 2,
        paddingLeft: 20,
        flexDirection: "row"
    },
    phone: {
        color: "teal"
    },
    title: {
        fontWeight: "bold"
    },
    city: {
        fontStyle: "italic",
    },
    subset: {
        fontSize: 9,
    },
    onletter: {
        fontSize: 20
    }
});
//const Bhudev = React.memo(OBhudev)
export default ChargeRow