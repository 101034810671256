import { MaterialCommunityIcons } from "@expo/vector-icons"
import React from "react"
import { useTheme } from "react-native-paper"
import {  View } from "./Themed"

interface options {
    path: string,
    icon: string,
    name: string,
    color: string
    size: number
    style: any
}
const DIcon = (op: options) => {
    const theme = useTheme()
    const sty = op.path ? op.path : op.icon ? op.icon : op.name ? op.name : "help"
    return <View style={op.style ? op.style : {}}>
        <MaterialCommunityIcons name={sty} size={op.size ? op.size : 24} color={op.color ? op.color : theme.dark ? (op.inverse ? "black" : "white") : (op.inverse ? "white" : "black")} />
    </View>

}
export default React.memo(DIcon)