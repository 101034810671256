import React     from 'react';
import Global from "../constants/global"
import { Avatar } from 'react-native-paper'
//    file: string, title: string, type: number = Global.ROW_DP, style: any, size: number = 64

function DAvatar(op) {
    const msize = op.type == -1 ? op.size : getSize(op.type)
    const tsize = op?.textSize ? op.textSize : msize
    const source = op?.base ? op.base : op?.file ? Global.getUrl(op.file) : ""
    console.log("source",source)
    if (source)
        return <Avatar.Image size={msize} source={{ uri: source }} style={op.style} />
    else {
        return op.icon ?
            <Avatar.Icon icon={op.icon} size={msize} />
            :
            <Avatar.Text size={tsize} label={op.title?.length > 1 ? op.title.substring(0, 1) : ""} />
    }

}

function getSize(type: number) {
    if (type) {
        if (type == Global.ROW_DP) {
            return 64
        }
        if (type == Global.FORM_DP) {
            return 120
        }
        if (type == Global.FULL_DP) {
            return 240
        }
        if (type == Global.SMALL_DP) {
            return 48
        }
        if (type == Global.BIG_ROW_DP) {
            return 70
        }
    }
    return 48
}
export default React.memo(DAvatar)
