import { FlatList, StyleSheet, ScrollView } from 'react-native';
import { Button, Chip, FAB, IconButton, TextInput } from 'react-native-paper';
import { useSelector } from 'react-redux';

import EditScreenInfo from '../components/EditScreenInfo';
import Hemberger from '../components/Hemberger';
import { Text, View } from '../components/Themed';
import PropertyRow from '../components/PropertyRow';
import { useContext, useEffect, useState } from 'react';
import Prferances from '../constants/Preferances';
import Global from '../constants/global';
import { getFirestore, writeBatch } from 'firebase/firestore';
import Toast from 'react-native-root-toast';
import { RootStackScreenProps } from '../types';
import BackButton from '../components/BackButton';


let baselist: [] = []

function check(newObj, obj, yr) {
  let o = { year: yr }
  if (obj[yr]) {
    o = { ...o, amount: obj[yr] }
  }
  newObj.push(o)
}

export default function Properties({ route, navigation }: RootStackScreenProps<'Properties'>) {
  const ulist = useSelector(state => state.list.Properties)
  const olist = useSelector(state => state.list.Users)
  const accounts = useSelector(state => state.list.Entries)
  const charges = useSelector(state => state.list.Charges)
  const [verYear, setVerYear] = useState({ year: "2019:20", type: true });

  const [search, setSearch] = useState("");
  const [select, setSelect] = useState("all");
  const [list, setList] = useState([]);
  const pref = useContext(Prferances)

  useEffect(() => {
    const e: [] = []
    if (ulist && olist) {
      ulist.map(u => {
        const ele = { ...u }
        let s: string = ele.pno + Global.type[ele.type]
        if (ele?.currentOwner) {
          const got = olist.find(on => on.id == u.currentOwner)
          if (got) {
            s = s + got.name
            ele.cowner = got.name
          } else {
            s = s + "<no owner>"
            ele.cowner = "<no owner>"
          }
        }
        if (ele?.currentTenant) {
          const got = olist.find(on => on.id == u.currentTenant)
          if (got) {
            s = s + got.name
            ele.ctenant = got.name
          }
        }
        ele.search = s.toLowerCase()
        e.push(ele)
      })
      baselist = [...e]
      setList(e)

    }
  }, [ulist, olist])

  useEffect(() => {
    const lsearch = search.toLowerCase()
    let flist
    if (lsearch.includes("floor")) {
      const r = lsearch.replace("floor", "")
      const fl = baselist.filter(e => (e.floor + "").includes(r))
      flist = fl.sort((a, b) => a.floor - b.floor)
    } else {
      flist = baselist.filter(e => e.search.includes(lsearch) || (e.floor + "").includes(lsearch))
    }
    setList(flist)
  }, [search])
  useEffect(() => {
    if (verYear.type) {
      let flist = baselist.filter(e => e?.verified?.find(y => y == verYear.year))
      setList(flist)
    } else {
      let flist = baselist.filter(e => e?.verified?.find(y => y == verYear.year) == undefined)
      setList(flist)
    }
  }, [verYear])

  useEffect(() => {
    let flist: [] = []
    if (select == "all") {
      flist = [...baselist]
    } if (select == "r") {
      flist = baselist.filter(e => e?.rentalMonths?.filter(f => f?.status > 0).length > 0)
    } if (select == "c") {
      flist = baselist.filter(e => e?.clMonths?.filter(f => f?.status > 0).length > 0)
    } if (select == "h") {
      flist = baselist.filter(e => e?.type == 1)
    } if (select == "cl") {
      flist = baselist.filter(e => e?.type == 2)
    } if (select == "ht") {
      flist = baselist.filter(e => e?.type == 4)
    } if (select == "hp") {
      flist = baselist.filter(e => e?.type == 3)
    } if (select == "of") {
      flist = baselist.filter(e => e?.type == 0)
    } if (select == "dp") {
      flist = baselist.filter(e => e?.depositPending > 500)
    } if (select == "tp") {
      flist = baselist.filter(e => e?.transferFeePending > 500)
    }
    setList(flist)
  }, [select])

  function reapply(property, year) {
    const capp = charges.filter(f => f.year == year)
    const db = getFirestore()
    const batch = writeBatch(db)
    const action =[]
    capp.map(charge => {
      Global.reapplyRule(property, charge, accounts, db, batch,action)
    })
    // batch.commit()
    Toast.show("Property Charges Re-applied. Dont forget to Refreash Balances after couple of mins")
  }
  return (
    <View style={styles.container}>

      <View style={{ flexDirection: 'row' }}>
        <Hemberger navigation={navigation} />
        <View style={{ alignItems: "baseline", flex: 1 }}>
          <ScrollView style={{ maxHeight: 50 }} horizontal contentContainerStyle={{ maxHeight: 50 }}>
            <Chip selected={select == "all"} style={{ margin: 5, height: 40 }} onPress={() => setSelect("all")}>{"All"}</Chip>
            <Chip selected={select == "r"} style={{ margin: 5, height: 40 }} onPress={() => setSelect("r")}>{"Rented"}</Chip>
            <Chip selected={select == "c"} style={{ margin: 5, height: 40 }} onPress={() => setSelect("c")}>{"Closed"}</Chip>
            <Chip selected={select == "h"} style={{ margin: 5, height: 40 }} onPress={() => setSelect("h")}>{"Hotel"}</Chip>
            <Chip selected={select == "cl"} style={{ margin: 5, height: 40 }} onPress={() => setSelect("cl")}>{"Classes"}</Chip>
            <Chip selected={select == "ht"} style={{ margin: 5, height: 40 }} onPress={() => setSelect("ht")}>{"Hostel"}</Chip>
            <Chip selected={select == "hp"} style={{ margin: 5, height: 40 }} onPress={() => setSelect("hp")}>{"Hospital"}</Chip>
            <Chip selected={select == "of"} style={{ margin: 5, height: 40 }} onPress={() => setSelect("of")}>{"Office"}</Chip>
            <Chip selected={select == "dp"} style={{ margin: 5, height: 40 }} onPress={() => setSelect("dp")}>{"Deposit Pending"}</Chip>
            <Chip selected={select == "tp"} style={{ margin: 5, height: 40 }} onPress={() => setSelect("tp")}>{"Transfer Fee Pending"}</Chip>
            <Chip selected={select == "ver"} style={{ margin: 5, height: 40 }} onPress={() => setSelect(select == "ver" ? "all" : "ver")}>{"Verified"}</Chip>
          </ScrollView>
          {select == "ver" && <View>
            <ScrollView horizontal>
              <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                <Text>Verified</Text>
                {Global.years.map(y => {
                  return <Chip selected={verYear.year == y && verYear.type == true} style={{ margin: 5 }} onPress={() => setVerYear({ year: y, type: true })}>{y}</Chip>
                })}
              </View>
            </ScrollView></View>}
          {select == "ver" && <View>

            <ScrollView horizontal>
              <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                <Text>UN-Verified :</Text>
                {Global.years.map(y => {
                  return <Chip selected={verYear.year == y && verYear.type == false} style={{ margin: 5 }} onPress={() => setVerYear({ year: y, type: false })}>{y}</Chip>
                })}
              </View>
            </ScrollView></View>}

          <View style={{ flexDirection: "row", alignSelf: 'stretch' }}>
            <TextInput
              autoFocus
              placeholder="Search..."
              style={{ alignSelf: "stretch", flex: 1 }}
              dense
              onChangeText={(text) => {
                setSearch(text)
              }}
            />
            <View style={{ alignItems: "center", justifyContent: "center" }}>
              <IconButton icon='eye' />
              <Text>{list?.length}</Text>
            </View>
          </View>
          {/* <Button onPress={() => upload()}>Upload</Button> */}
          <FlatList
            data={list}
            renderItem={u =>
              <PropertyRow navigation={navigation} info={u.item} index={u.index} committee={pref?.current?.type == "Committee"} super={pref?.current?.isSuperAdmin} reapply={(property, year) => reapply(property, year)} />
            }
          />

        </View>
      </View>

      {
        pref?.current?.isSuperAdmin && <FAB
          icon="plus"
          style={{
            position: 'absolute',
            margin: 16,
            right: 0,
            bottom: 0,
          }}
          onPress={() => navigation.navigate("PropertiesForm")}
        />
      }

    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
