import { FlatList, StyleSheet } from 'react-native';
import { Button, Chip, FAB, IconButton, TextInput } from 'react-native-paper';
import { useSelector } from 'react-redux';

import Hemberger from '../components/Hemberger';
import { Text, View } from '../components/Themed';
import { useEffect, useState } from 'react';
import InExRow from '../components/InExRow';
import { CommonActions } from '@react-navigation/native';
import { RootStackScreenProps } from '../types';
import BackButton from '../components/BackButton';


let baselist: [] = []
export default function InEx({ route, navigation }: RootStackScreenProps<'InEx'>) {
    const ulist = useSelector(state => state.list.InEx)
    const [search, setSearch] = useState("");
    const [list, setList] = useState({ list: [], years: [], selection: "2019-20", type: -1 });

    const [state, setState] = useState({ open: false });

    const onStateChange = ({ open }) => setState({ open });

    const { open } = state;

    useEffect(() => {
        const e: [] = []
        if (ulist) {
            const yrs1 = [...new Set(ulist.map(item => item.year))];
            const yrs2 = yrs1.filter(item => (item !== "0"))
            const yrs: [] = yrs2.sort((a, b) => parseInt(a.replace("-", "")) - parseInt(b.replace("-", "")))

            ulist.map(u => {
                if (list.type == -1 ? true : list.type == u.type) {
                    const ele = { ...u }
                    let search = ele.amount + "" + ele?.remarks ? ele?.remarks : "" + ele?.head ? ele.head : ""
                    ele.search = search ? search.toLowerCase() : ""
                    e.push(ele)
                }
            })
            baselist = [...e]
            setList(p => ({ ...p, years: yrs, list: e }))
        }
    }, [ulist, list.type])

    return (
        <View style={{ flexDirection: "row" }}>
            <Hemberger navigation={navigation} />
            <View style={{ flex: 1, alignSelf: "stretch" }}>
                <View style={{ flexDirection: "row", alignSelf: "stretch" }}>
                    
                    <Chip selected={list.type == -1} style={{ margin: 5 }} onPress={() => setList(p => ({ ...p, type: -1 }))}>All</Chip>
                    <Chip selected={list.type == 1} style={{ margin: 5 }} onPress={() => setList(p => ({ ...p, type: 1 }))}>Income</Chip>
                    <Chip selected={list.type == 0} style={{ margin: 5 }} onPress={() => setList(p => ({ ...p, type: 0 }))}>Expences</Chip>
                </View>
                <View style={{ flexDirection: "row", alignSelf: "stretch" }}>
                    {
                        list.years.map((y, i) => <Chip key={i + "1"} selected={list.selection == y} style={{ margin: 5 }} onPress={() => setList(p => ({ ...p, selection: y }))}>{y}</Chip>)
                    }
                </View>
                <View style={{ flexDirection: "row", alignSelf: "stretch" }}>
                    <TextInput
                        autoFocus
                        placeholder="Search Inome / Expences..."
                        style={{ alignSelf: "stretch", flex: 1 }}
                        dense
                        onChangeText={(text) => {
                            setSearch(text)
                        }}
                    />
                </View>
                <FlatList
                    data={list.list.filter(e => e.year == list.selection)}
                    renderItem={(u) =>
                        <InExRow key={u.index + "1"} navigation={navigation} info={u.item} index={u.index} />} />


            </View>
            <FAB.Group
                open={open}
                icon={'plus'}
                actions={[
                    {
                        icon: 'credit-card-refund',
                        label: 'Expence',
                        onPress: () => {
                            navigation.navigate('InExForm',
                                { data: { type: 0 } }
                            )
                        }
                    },
                    {
                        icon: 'cash-multiple',
                        label: 'Income',
                        onPress: () =>
                            navigation.dispatch(
                                CommonActions.reset({
                                    index: 0,
                                    routes: [
                                        { name: 'InExForm', params: { data: { type: 1 } } }
                                    ],
                                })
                            )
                    },
                ]}
                onStateChange={onStateChange}
                onPress={() => {
                    if (open) {
                        // do something if the speed dial is open
                    }
                }}
            />

        </View>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
