import React, { useCallback, useContext, useEffect, useState } from 'react';
import {  Share, StyleSheet, TouchableOpacity } from 'react-native';
import Global from "../constants/global"
import { useSelector } from 'react-redux';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import DAvatar from './DAvatar'
import { Text, Card, IconButton, Modal, Portal, Dialog, Button } from 'react-native-paper';
import Toast from 'react-native-root-toast';
import { StackActions } from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';


interface option {
    info: any
    callback(user: any): void
    navigation: any
    extra: any
    index: number

}
const UserRow = (op: option) => {
    const col = Global.getColor(op.index)


    return (
        <View style={{ flexDirection: "row", marginTop: 8, marginBottom: 8, flex: 1 }}>
            <DAvatar title={op.info?.name?.trim()} color={col} file={op.info?.dp} type={Global.ROW_DP} />
            <TouchableOpacity onPress={() => op.open()} style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", marginLeft: 4, alignSelf: "stretch", flex: 1 }}>
                    <View style={{ alignSelf: "stretch", flex: 1 }}>
                        <View style={{ flexDirection: "row", flex: 1, alignItems: "baseline" }}>
                            <Text variant="bodyLarge" style={{ color: col, fontWeight: "bold" }}>{op.info?.name}</Text>
                            <Text>{op.info?.type == "Committee" ? " (Committee)" : "    "}</Text>
                        </View>
                        <Text style={{ color: col }}>{op.info?.mobile}</Text>
                        <Text style={{ color: col }}>{op.info?.designation}</Text>

                        {op.info?.status !== "Active" ? <Text style={{ color: col }}>{op.info?.status}</Text> : <></>}


                        {op.info?.isOwnerOf ?
                            <View style={{ flexDirection: "row" }}>
                                <Text style={{ color: col }}>Owner: </Text>
                                {op.info?.isOwnerOf?.map((o, i) => <Text style={{ color: col }}>{i > 0 ? " ," : ""} {o} </Text>)}
                            </View>
                            : <></>
                        }
                        {op.info?.isTenantOf ?

                            <View style={{ flexDirection: "row" }}>
                                <Text style={{ color: col }}>Tenent: </Text>
                                {op.info?.isTenantOf?.map((o, i) => <Text style={{ color: col }}>{i > 0 ? " ," : ""} {o} </Text>)}
                            </View>
                            : <></>
                        }

                    </View>
                    <Text style={{ padding: 13, fontSize: 18 }}>⌵</Text>
                </View>
            </TouchableOpacity>

        </View>)
}


const styles = StyleSheet.create({
    item: {
        textColor: 'white',
        fontWeight: '900',
        flex: 1,
        alignItems: "center",
        marginVertical: 2,
        marginHorizontal: 2,
        paddingLeft: 20,
        flexDirection: "row"
    },
    phone: {
        color: "teal"
    },
    title: {
        fontWeight: "bold"
    },
    city: {
        fontStyle: "italic",
    },
    subset: {
        fontSize: 9,
    },
    onletter: {
        fontSize: 20
    }
});
//const Bhudev = React.memo(OBhudev)
export default React.memo(UserRow)