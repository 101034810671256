import { useEffect, useState } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { Text, IconButton, TextInput, Chip, FAB } from 'react-native-paper';
import { useSelector } from 'react-redux';

import EditScreenInfo from '../components/EditScreenInfo';
import Hemberger from '../components/Hemberger';
import { View } from '../components/Themed';
import ChargeRow from '../components/ChargeRow';
import { doc, getFirestore, setDoc, writeBatch } from 'firebase/firestore';
import Global from '../constants/global';
import dayjs from 'dayjs';
import Toast from 'react-native-root-toast';
import { CommonActions } from '@react-navigation/native';
import { RootStackScreenProps } from '../types';
import BackButton from '../components/BackButton';
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

let baselist: [] = []
export default function Charges({ route, navigation }: RootStackScreenProps<'Charges'>) {
    const ulist = useSelector(state => state.list.Charges)
    const accounts = useSelector(state => state.list.Entries)
    const properties = useSelector(state => state.list.Properties)
    const [search, setSearch] = useState("");
    const [list, setList] = useState({ list: [], years: [], selection: "2019-20", accounts: [], properties: [] });

    function deleteCharge(charge) {
        const db = getFirestore()
        const batch = writeBatch(db)
        const l = list.accounts.filter(e => e.headId == charge.id)
        const deletedProps: [] = []
        const inEnts: [] = []

        try {
            l.map(e => {
                const ref = doc(db, "Entries", e.id)
                deletedProps.push(e.property)
                inEnts.push(e.id)
                batch.delete(ref)
            })
            const ref = doc(db, "Charges", charge.id)
            batch.delete(ref)
            batch.commit()
            Toast.show("Charge Deleted. Dont forget to Refreash Balance")


            // deletedProps.map(d => {
            //     const prop = properties.find(pd => pd.pno == d)
            //     if (prop) {
            //         const property = JSON.parse(JSON.stringify(prop))
            //         property.balance = property.balance.sort((a, b) => parseInt(a.year.replace("-", "")) - parseInt(b.year.replace("-", "")))

            //         property.balance.map((y, i) => {
            //             const lst = accounts.filter(a => a.property == prop.pno && a.year == y.year)
            //             const pure: [] = []
            //             lst.map(l => {
            //                 const isThere = inEnts.findIndex(ie => ie == l.id)
            //                 if (isThere == -1) {
            //                     pure.push(l)
            //                 }
            //             })
            //             let dr = 0
            //             let cr = 0
            //             pure.map(e => {
            //                 if (e.side == 0) {
            //                     dr = dr + e.amount
            //                 } else {
            //                     cr = cr + e.amount
            //                 }
            //             })
            //             if (property.balance[i + 1]) {
            //                 property.balance[i + 1].amount = y.amount + cr - dr
            //             }
            //         })
            //         const db = getFirestore()
            //         const ref = doc(db, "Properties", prop.id)
            //         batch.set(ref, { balance: property.balance }, { merge: true })

            //     }

            // })
        } catch (e) { }
    }

    function refreash() {
        const db = getFirestore()
        const batch = writeBatch(db)
        properties.map(p =>
            Global.resetOpeningBalances(p, accounts, (bal) => {
                const ref = doc(db, "Properties", p.id)
                batch.set(ref, { balance: bal }, { merge: true })
            })

            // recalc(p, info, (bal) => {
            //     const ref = doc(db, "Properties", p.id)
            //     batch.set(ref, { balance: bal }, { merge: true })
            // })

        )
        batch.commit()
        Toast.show(" Balance Refreashed")
    }

    function recalc(prop, year, saveFun) {
        // const property = JSON.parse(JSON.stringify(prop))
        // property.balance = property.balance.sort((a, b) => parseInt(a.year.replace("-", "")) - parseInt(b.year.replace("-", "")))
        // const ys = year + "-" + (year - 1999)
        // const has = property.balance.find(f => f.year == ys)
        // if (!has) {
        //     property.balance.push({ year: ys, amount: 0 })
        // }
        // property.balance.map((y, i) => {
        //     const lst = accounts.filter(a => a.property == prop.pno && a.year == y.year)
        //     let dr = 0
        //     let cr = 0
        //     lst.map(e => {
        //         if (e.side == 0) {
        //             dr = dr + e.amount
        //         } else {
        //             cr = cr + e.amount
        //         }
        //     })
        //     if (property.balance[i + 1]) {
        //         property.balance[i + 1].amount = y.amount + cr - dr
        //     }
        // })
        // saveFun(property.balance)
    }

    // function refreshBalance(plist, db, batch) {
    //     plist.map(d => {
    //         const prop = properties.find(pd => pd.pno == d)
    //         if (prop) {
    //             recalc(prop, db, batch)
    //         }

    //     })
    // }




    useEffect(() => {
        if (ulist && accounts && properties) {
            const e: [] = []
            // const yrs1 = [...new Set(ulist.map(item => item.year))];
            // const yrs = yrs1.filter(item => (item !== "0"))
            const yrs = Global.years
            ulist.map(u => {
                const ele = { ...u }
                let s: string = (ele?.description ? ele?.description : "") + (ele?.remarks ? ele?.remarks : "")
                ele.search = s.toLowerCase()
                e.push(ele)
            })

            baselist = [...e]
            let yr = list.selection ? list.selection : "2019-20"
            if (yrs.length > 0) {
                yr = yrs[yrs.length]
            }
            setList({ list: e, years: yrs, selection: yr, accounts: accounts, properties: properties })
        }

    }, [ulist, accounts, properties])

    useEffect(() => {
        const lsearch = search.toLowerCase()
        const flist = baselist.filter(e => e.search.includes(lsearch))
        setList(p => ({ ...p, list: flist }))
    }, [search])

    function apply(charge: any) {
        const db = getFirestore()
        const batch = writeBatch(db)
        const l = list.accounts.filter(e => e.headId == charge.id)
        const action: [] = []
        l.map(e => {
            const ref = doc(db, "Entries", e.id)
            batch.delete(ref)
            action.push({ action: "Deleted", property: e.property, amount: e.amount, headName: e.headName })
        })


        try {
            list.properties.map(p => {
                Global.applyRule(p, charge, db, batch, action)
            })
            const ref = doc(db, "Charges", charge.id)
            batch.set(ref, { appliedTo: action }, { merge: true })
            batch.commit()
            Toast.show("Charges applied. Dont forget to Refreash Balances after couple of mins")
        } catch (e) {
            console.log("applyc charge error", e)
        }
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: "row" }}>
                <Hemberger navigation={navigation} />
                <View style={{ flex: 1, alignSelf: "stretch" }}>
                    <View style={{ flexDirection: "row" }}>
                        {
                            list.years.map(y => <Chip selected={list.selection == y} style={{ margin: 5 }} onPress={() => setList(p => ({ ...p, selection: y }))}>{y}</Chip>)
                        }
                    </View>            <View style={{ flexDirection: 'row', alignItems: "center" }}>
                        <TextInput
                            autoFocus
                            placeholder="Search..."
                            style={{ alignSelf: "stretch", flex: 1 }}
                            dense
                            value={search}
                            onChangeText={(text) => {
                                setSearch(text)
                            }}
                        />
                        <IconButton icon='eye' />
                        <Text>{list.list?.length}</Text>
                    </View>

                    <FlatList
                        data={list.list.filter(e => e.year == list.selection)}
                        renderItem={u => {
                            const al = accounts.filter(f => f.year == list.selection && f.headId == u.item.id)
                            const amt = al.reduce((a, b) => a + b.amount, 0)
                            return <ChargeRow entries={accounts} properties={properties} navigation={navigation} info={u.item} index={u.index} apply={(charge) => apply(charge)} deleteCharge={(charge) => deleteCharge(charge)} refreash={() => refreash()} amount={amt} />
                        }
                        }
                    />

                </View>
            </View>
            <FAB
                icon="plus"
                style={{
                    position: 'absolute',
                    margin: 16,
                    right: 0,
                    bottom: 0,
                }}
                onPress={() =>
                    navigation.navigate(
                        'ChargeForm'
                    )}
            />

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignSelf: "stretch"
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
