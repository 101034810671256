import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import { Button, Text, TextInput } from "react-native-paper"
import Toast from "react-native-root-toast"
import Global from "../constants/global"
import { View } from "./Themed"

export default function OpBalOptions(op) {
    const [opbal, setOpbal] = useState({ year: "", property: undefined, opbal: 0, value: 0 })

    function setupOpbal() {
        const prop = { ...opbal.property }
        let changed = false
        let nYear: [] = []
        prop.balance.map(yr => {
            const aYear = { ...yr }
            if (aYear.year == opbal.year) {
                aYear.amount = opbal.value
                changed = true
                console.log("changed")
            }
            nYear.push(aYear)
        })
        prop.balance = nYear

        if (changed) {
            const db = getFirestore()
            const ref = doc(db, "Properties", prop.id)
            setDoc(ref, { balance: nYear }, { merge: true })
        } else {
            alert("Year not found")
        }
        op.close()
    }

    useEffect(() => {
        setOpbal({ year: op.year, property: op.property, opbal: op.opbal, value: op.opbal })
    }, [op])

    return <View style={{ justifyContent: "center", backgroundColor: Global.THEME.surfaceVariant }}>
        <Text style={{ color: "black", }} variant='headlineSmall'>Set Opening balance ({opbal?.year?.year})</Text>
        <Text style={{ color: "black", }}>Property : {opbal?.property?.pno}</Text>
        <Text style={{ color: "black", }}>Opening Balance : {opbal?.opbal}</Text>
        <TextInput
            value={"" + opbal?.value}
            onChangeText={(t) => {
                const v = t.replace(/[^0-9.-]/g, '')
                let vn = 0
                if (v) {
                    vn = parseFloat(v)
                }
                setOpbal(p => ({ ...p, value: vn }))

            }}
            label={"New Opening Balance"}
        />
        <Text style={{ color: "black", }}>Note : Changing opening balance will change closing balance and reset all balances for all subsequent years</Text>
        <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent" }}>
            <Button onPress={() => op.close()}>Cancel</Button>
            <Button onPress={() => setupOpbal()}>Save</Button>
        </View>
    </View>
}