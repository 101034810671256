import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const listTask = createSlice({
  name: 'list',
  initialState: {
    Users: [],
    Properties: [],
    Payments: [],
    Charges: [],
    Entries: [],
    Dashboard: [],
    InEx: []
  },
  reducers: {
    remove(state, action) {
      let data = action.payload.data;
      let type = action.payload.type;
      let index = state[type].findIndex(el => el.id == data.id)
      if (index >= 0) {
        try {
          state[type].splice(index, 1)
        } catch (e) { console.log(e) }
      }
    },
    updateList(state, action) {
      let data = action.payload.data;
      let type = action.payload.type;
      let tochk = "id";
      if (action.payload.tocheck) {
        tochk = action.payload.tocheck;
      }
//      console.log("listtask", tochk)
      if (action.payload?.isArray) {
  //      console.log("listtask isArray")
        for (let i = 0; i < action.payload.data.length; i++) {
          try {
            let row = action.payload.data[i]
    //        console.log("listtask row", row)
            if (row) {
              if (row.data[tochk]) {
                let index = state[type].findIndex(el => el[tochk] === row.data[tochk])
                if (index >= 0) {
                  state[type][index] = row.data;
                 // console.log("listtask replacing")
                } else {
                  state[type].push(row.data);
                 // console.log("listtask adding")
                }
              }
            }
          } catch (err) {
            console.log("redux list updation error", err)
          }

        }
      } else {
//        console.log("listtask elsw work")
        if (data) {
          if (data[tochk]) {
            let index = state[type].findIndex(el => el[tochk] === data[tochk])
            if (index >= 0) {
              state[type][index] = data;
            } else {
              state[type].push(data);
            }
          }
        }
      }
    },
  },
})


export default listTask.reducer