import { Pressable, ScrollView, StyleSheet } from 'react-native';
import React, { useEffect } from "react"
import Approvals from '../components/Approvals';
import { View } from '../components/Themed';
import Chart from '';

import { Button, Chip, Text } from 'react-native-paper';

import { collection, doc, getDoc, getFirestore, onSnapshot, setDoc, writeBatch } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import Global from '../constants/global';
function iyear(y) {
    const cy = y.substring(0, 4)
    return parseInt(cy)

}
export default function Doctor({ navigation }) {
    const charges = useSelector(state => state.list.Charges)
    const accounts = useSelector(state => state.list.Entries)
    const properties = useSelector(state => state.list.Properties)
    const [list, setList] = React.useState([])
    const [trinch, setTrinch] = React.useState({ one: [], many: [] })
    React.useEffect(() => {
        if (charges && accounts?.length > 800 && properties) {
            const l: [] = []
            properties.map(p => {
                charges.map(c => {
                    if (c.year !== "0") {
                        const ny = iyear(c.year)
                        if (ny > 2022) {
                            let ll: [] = []
                            Global.applyRuleView(p, c, undefined, undefined, ll)
                            let gt
                            if (c.isClose !== Global.ALL) {
                                gt = ll.find(ff => ff.stages.find(gg => gg.closeRent == true) !== undefined)
                            } else {
                                gt = ll.find(ff => ff.stages.find(gg => gg.rule == true) !== undefined)
                            }
                            if (gt) {
                                const got = accounts.find(a => a.property == p.pno && a.year == c.year && a.headId == c.id)
                                if (!got) {
                                    l.push({ property: p, charge: c, type: 0 })
                                } else {
                                    // if (p.pno == "102") {
                                    //     console.log("LL", c.id, c.year, got, gt)
                                    // }

                                }
                            }

                        }
                    }
                })
            })
            accounts.map(a => {
                const ny = iyear(a.year)
                if (a.side == 0 && ny > 2022) {
                    const char = charges.find(a => a.headId == a.id)
                    const prop = properties.find(a => a.property == a.pno)
                    if (char && prop) {
                        const ll: [] = []
                        Global.applyRuleView(prop, char, undefined, undefined, ll)
                        const gt = ll.find(ff => ff.stages.find(gg => gg.rule == true))
                        if (!gt) {
                            l.push({ property: p, charge: c, type: 1 })
                        }

                    }
                }

            })
            const bal = []
            const oneChar: [] = []
            const manyChar: [] = []

            properties.map(pro => {
                const property = JSON.parse(JSON.stringify(pro))
                property.balance = property.balance?.sort((a, b) => parseInt(a.year.replace("-", "")) - parseInt(b.year.replace("-", "")))
                const a = { pno: property.pno, year: [] }
                let one = true
                Global.years.map(y => {
                    const af = accounts.filter(a => a.property == property.pno && a.year == y)
                    if (one) {
                        if (af?.length !== 1) {
                            one = false
                        }
                    }
                    a.year.push({ year: y, entries: af })
                })
                if (one) {
                    oneChar.push(a)
                } else {
                    manyChar.push(a)
                }

                let paraOpbal = property?.balance[0]?.amount
                property.balance.map((y, i) => {
                    const lst = accounts.filter(a => a.property == property.pno && a.year == y.year)
                    let dr = 0
                    let cr = 0
                    lst.map(e => {
                        if (e.side == 0) {
                            dr = dr + e.amount
                        } else {
                            cr = cr + e.amount
                        }
                    })
                    paraOpbal = paraOpbal + cr - dr
                    if (property.balance[i + 1]) {
                        if (property.balance[i + 1].amount !== paraOpbal) {
                            // console.log(property.pno, paraOpbal, cr, dr, property.balance)
                            l.push({
                                property: property,
                                year: i + 1,
                                charge: undefined,
                                type: 2,
                                bal: {
                                    year: i + 1,
                                    ori: property.balance[i + 1].amount,
                                    should: paraOpbal
                                }
                            })
                        }
                    }

                })
                bal.push({ ...pro })
            })

            const ll = l.sort((a, b) => a.property.pno.localeCompare(b.property.pno))

            setTrinch({ one: oneChar, many: manyChar })
            setList(ll)
        }
    }, [charges, accounts, properties])

    const ManyRow = ({ bt, l, i }) => {
        return <View key={i + ""} style={{ backgroundColor: bt ? "#FFB6C1" : undefined, flexDirection: "row", alignItems: "center" }}>
            <Text style={{ width: 100 }}>{l.pno}</Text>

            {Global.years.map(y => {
                const ln = l.year.find(f => f.year == y)?.entries?.length
                return <Text style={{ borderRightWidth: 1, width: 100, textAlign: "center" }}>{ln !== 1 ? ln : " "}</Text>
            })}
            <Button onPress={() => {
                navigation.navigate(
                    'Statement', { pno: l.pno, editable: true, user: undefined, party: undefined, type: 2 }
                )

            }}>Statement</Button>
        </View>
    }

    const DRow = ({ l, i }) => {
        const [working, setWorking] = React.useState(false)
        const v = i + ""
        const lc = v.charAt(v.length - 1)
        const stripe = lc == "0" || lc == "2" || lc == "4" || lc == "6" || lc == "8"
        return <View style={{ backgroundColor: stripe ? (l.type == 2 ? "dodgerblue" : "orange") : "transparent", flexDirection: "row", alignItems: "center" }}>
            <Text style={{ width: 100 }}>{l.type == 0 ? "Missing" : (l.type == 1 ? "Extra" : "Balance")}</Text>
            {/* <Text style={{ width: 100 }}>{l.charge?.id}</Text> */}
            <Text style={{ width: 100 }}>{l.charge?.year ? l.charge?.year : Global.years[l.year]}</Text>
            <Text style={{ width: 100 }}>{l.property.pno}</Text>
            {l.type !== 2 && <Text style={{ width: 100 }}>{l.charge.rate}</Text>}
            {l.type !== 2 && <Text style={{ width: 300 }}>{l.charge.remarks}</Text>}
            {l.type !== 2 && <Text style={{}}>{l.charge.isClosed == 0 ? "All - " : (l.charge.isClosed == 1 ? "Open -" : "Close -")}{Global.getRuleName(l.charge.selection)} {l.charge.input ? " 👉 " + l.charge.input : ""}</Text>}
            {l.type == 2 && <Text style={{}}>In records = {l.bal.ori}, should be ={l.bal.should}</Text>}
            <Button onPress={() => {
                if (l.type !== 2) {
                    setTimeout(() => { setWorking(true) })
                    const db = getFirestore()
                    const batch = writeBatch(db)
                    const action: [] = []
                    Global.applyRule(l.property, l.charge, db, batch, action)
                    batch.commit().then(() => {
                        setTimeout(() => { setWorking(false) })
                    })
                } else {
                    setTimeout(() => { setWorking(true) })
                    l.property.balance = l.property.balance.sort((a, b) => parseInt(a.year.replace("-", "")) - parseInt(b.year.replace("-", "")))
                    let paraOpbal = l.property.balance[0].amount
                    l.property.balance.map((y, i) => {
                        const lst = accounts.filter(a => a.property == l.property.pno && a.year == y.year)
                        let dr = 0
                        let cr = 0
                        lst.map(e => {
                            if (e.side == 0) {
                                dr = dr + e.amount
                            } else {
                                cr = cr + e.amount
                            }
                        })
                        if (l.property.balance[i + 1]) {
                            paraOpbal = paraOpbal + cr - dr
                            l.property.balance[i + 1].amount = paraOpbal
                        }
                    })
                    const db = getFirestore()
                    const ref = doc(db, "Properties", l.property.id)
                    setDoc(ref, { balance: l.property.balance }, { merge: true }).then(() => {
                        setTimeout(() => { setWorking(false) })
                    })
                }
            }
            } loading={working}>Fix</Button>
        </View>

    }
    const accessCode = 'AVST82KF23BY82TSYB'
    const workingKey = "BE05B18FFDDC7DC481E94381E321B5F6"
    const amount = "10.00"
    const currency = "INR"
    let ccRequest = ""
    let pname = ""
    let pvalue = ""
    let bt = true
    return (
        <View style={styles.container}>
            {/* <Button onPress={() => {
                const url = "https://test.ccavenue.com/transaction.do?command=getJsonData&access_code=" + accessCode + "&currency=INR&amount=11.00"
                fetch(url).then(e => console.log(e)).catch(e => console.log("err", e))
            }}>Pay</Button> */}
            {/* <div class='pm-button'><a href='https://www.payumoney.com/paybypayumoney/#/E72B00E08EF5F2BF6ACD47CA50DBB025'><img src='https://www.payumoney.com/media/images/payby_payumoney/new_buttons/21.png' /></a></div> */}
            {/* <form action='https://test.payu.in/_payment' method='post'>
                <input type="hidden" name="key" value="JP***g" />
                <input type="hidden" name="txnid" value="t6svtqtjRdl4ws" />
                <input type="hidden" name="productinfo" value="Charges" />
                <input type="hidden" name="amount" value="10" />
                <input type="hidden" name="email" value="test@gmail.com" />
                <input type="hidden" name="firstname" value="Ashish" />
                <input type="hidden" name="lastname" value="Kumar" />
                <input type="hidden" name="surl" value="https://apiplayground-response.herokuapp.com/" />
                <input type="hidden" name="furl" value="https://apiplayground-response.herokuapp.com/" />
                <input type="hidden" name="phone" value="9988776655" />
                <input type="hidden" name="hash" value="eabec285da28fd0e3054d41a4d24fe9f7599c9d0b66646f7a9984303fd6124044b6206daf831e9a8bda28a6200d318293a13d6c193109b60bd4b4f8b09c90972" />
                <input type="submit" value="submit" /> </form> */}
            <Text variant="titleLarge">Action Required</Text>
            {
                list.filter(l => l.type !== 2).map((l, i) => <DRow key={i + ""} l={l} i={i} />)
            }
            <Text variant="titleLarge">Charges Applied (!=1)</Text>
            <View style={{ flexDirection: "row", marginLeft: 100 }}>
                {Global.years.map(y => {
                    return <Text style={{ textAlign: "center", width: 100 }}>{y}</Text>
                })}
            </View>
            {

                trinch.many.map((l, i) => {
                    bt = !bt
                    return <ManyRow bt={bt} key={i + ""} l={l} i={i} />
                })
            }
            {
                list.filter(l => l.type == 2 && Global.years[l.year]).map((l, i) => <DRow key={i + ""} l={l} i={i} />)
            }
            <Text style={{ marginTop: 30 }} variant="titleLarge">Balance Descripancy</Text>
            {
                list.filter(l => l.type == 2 && Global.years[l.year]).map((l, i) => <DRow key={i + ""} l={l} i={i} />)
            }
            <Balances navigation={navigation} />
        </View >
    );
}

const Balances = (o) => {
    const [ballist, setBalList] = React.useState([])
    const properties = useSelector(state => state.list.Properties)
    const [yr, setYr] = React.useState("")
    useEffect(() => {
        if (properties && yr) {
            let bl = []
            properties.map(p => {
                const bb = p.balance.find(pb => pb.year == yr)
                bl.push({ ...p, nbal: bb?.amount })
            })
            bl = bl.sort((a, b) => a.nbal - b.nbal)
            setBalList(bl)
        }
    }, [properties, yr])

    let bt = true
    return <View style={{ margin: 10, marginTop: 20 }}>
        <Text variant="titleLarge">Current Balances</Text>
        <View style={{ flexDirection: "row" }}>
            {
                Global.years.map((y, s) => {
                    return <Chip key={s} selected={Global.allyears[s + 1] == yr} onPress={() => { setYr(Global.allyears[s + 1]) }} >{y}</Chip>
                })
            }
        </View>
        {yr ?
            ballist.map((y, sr) => {
                bt = !bt
                return <Pressable onPress={() => {
                    o.navigation.navigate(
                        'Statement', { pno: y.pno, editable: true, user: undefined, party: undefined, type: 2 }
                    )
                    // console.log(o.navigation)
                    // o.navigation.navigate(
                    //     'Statement', {pno: y.pno, editable: true, user: undefined, party: undefined, type: 2 }
                    // )

                }}>
                    <View style={{ backgroundColor: bt ? "lightgray" : "white", flexDirection: "row", paddingTop: 5, paddingBottom: 5 }}>
                        <Text style={{ width: 50 }}>{sr + 1}</Text>
                        <Text style={{ width: 100, marginLeft: 5 }}>{y.pno}</Text>
                        <Text style={{ width: 500, marginLeft: 5 }}>{y.cowner}</Text>
                        <Text style={{ width: 150, marginLeft: 5 }}>{y.nbal}</Text>
                        <Text style={{ marginLeft: 10 }}>View Statement</Text>
                    </View>
                </Pressable>
            })
            :
            <Text style={{ backgroundColor: "lightgray", paddingTop: 100, paddingBottom: 100, textAlign: "center", flex: 1 }}>Select Year</Text>
        }

    </View >
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 10,
        fontWeight: "bold"
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
