import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dimensions, Share, StyleSheet, TouchableOpacity } from 'react-native';
import Global from "../constants/global"
import { useSelector } from 'react-redux';
import { doc, getFirestore, setDoc, writeBatch } from 'firebase/firestore';

import { View } from 'react-native';
import { Text, Card, IconButton, Portal, Dialog, Button, Divider, HelperText, TextInput } from 'react-native-paper';
import Toast, { ToastContainer } from 'react-native-root-toast';
import * as Clipboard from 'expo-clipboard';
import Modal from "react-native-modal"

interface option {
    info: any
    apply(payment: any, pinput: any): void
    navigation: any
    index: number
}
const PaymentRow = (op: option) => {
    const [deleConfirmation, setDeleConfirmation] = useState(false);
    const allAcc = useSelector(state => state.list.Entries)
    const allProp = useSelector(state => state.list.Properties)
    const [isOpen, setOpen] = useState(false);
    const [isPayment, setPDrawer] = useState(false);
    function onClose() { setOpen(false) }
    const col = Global.getColor(op.index)
    const [pinput, setPayment] = useState({});
    const [sum, setSum] = useState(0);

    const copyToClipboard = async (st: string) => {
        await Clipboard.setStringAsync(st);
        //        await Clipboard.setStringAsync("Hello, \nStatement Link\nhttps://ashwameghta.web.app/stat/" + op.info.id + "\nThank You Rahul Joshi");
        Toast.show("Link Copied")
    };

    function mark(no: number) {
        const db = getFirestore()
        const batch = writeBatch(db)
        const ref = doc(db, "Payments", op.info.id)
        batch.set(ref, { status: no, allocated: false, appliedTo: [] }, { merge: true })
        if (no == -1) {
            delEntriesNRefresh(db, batch)
        }
        batch.commit()
        Toast.show("Saved")
    }

    function delEntriesNRefresh(db, batch) {
        const ents = allAcc.filter(e => e.headId == op.info.id)
        const deletedProps: [] = []
        const inEnts: [] = []
        ents.map(e => {
            deletedProps.push(e.property)
            inEnts.push(e.id)
            const ref = doc(db, "Entries", e.id)
            batch.delete(ref)
        })

        deletedProps.map(d => {
            const prop = allProp.find(pd => pd.pno == d)
            if (prop) {
                const property = JSON.parse(JSON.stringify(prop))
                property.balance = property.balance.sort((a, b) => parseInt(a.year.replace("-", "")) - parseInt(b.year.replace("-", "")))

                property.balance.map((y, i) => {
                    const lst = allAcc.filter(a => a.property == prop.pno && a.year == y.year)
                    const pure: [] = []
                    lst.map(l => {
                        const isThere = inEnts.findIndex(ie => ie == l.id)
                        if (isThere == -1) {
                            pure.push(l)
                        }
                    })
                    let dr = 0
                    let cr = 0
                    pure.map(e => {
                        if (e.side == 0) {
                            dr = dr + e.amount
                        } else {
                            cr = cr + e.amount
                        }
                    })
                    if (property.balance[i + 1]) {
                        property.balance[i + 1].amount = y.amount + cr - dr
                    }
                })
                const db = getFirestore()
                const ref = doc(db, "Properties", prop.id)
                batch.set(ref, { balance: property.balance }, { merge: true })
            }
        })

    }
    function DeleteEntry(type: number) {
        const db = getFirestore()
        const ref = doc(db, "Payments", op.info.id)
        const batch = writeBatch(db)
        batch.delete(ref)
        setDeleConfirmation(false)
        delEntriesNRefresh(db, batch)
        batch.commit()
        Toast.show("Payment Hard Deleted & Balance Refreshed")
    }

    useEffect(() => {
        let s = 0
        op.info.properties.map(p => {
            if (pinput[p.pno]) {
                s = s + pinput[p.pno]
            }
        })
        setSum(s)

    }, [pinput])

    const onShare = async (url: string, title: string) => {
        try {
            const result = await Share.share({
                message:
                    (title + '\n' + url)
            });
            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    alert("1")
                    // shared with activity type of result.activityType
                } else {
                    alert("2")
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {
            alert(error.message);
        }
    }

    return (
        <View style={{ flexDirection: "row", marginTop: 8, marginBottom: 8, flex: 1 }}>
            <TouchableOpacity onPress={() => setOpen(true)} style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", marginLeft: 4, alignSelf: "stretch" }}>
                    <Text variant="bodyLarge" style={{ color: col, fontWeight: "bold" }}>{op.info.receiptNo}</Text>
                    <Text style={{ color: col, flex: 1, fontSize: 9, alignItems: "baseline" }}>   {
                        op.info?.paymentMode == 1 ? "Cash" :
                            op.info?.paymentMode == 2 ? "Cheque" :
                                op.info?.paymentMode == 3 ? "Online" : "DD"}</Text>
                    {op.info.status !== 11 && <Text style={{ color: "red" }}>{op.info.status == 0 ? "Pending" : "Failed"}</Text>}
                    <Text style={{ color: col }}>{Global.RS + " " + op.info.amount}</Text>
                    <Text style={{ paddingLeft: 13, paddingRight: 13, fontSize: 18 }}>⌵</Text>
                </View>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    {(op.info?.allocated !== undefined && op.info?.allocated !== false) && <IconButton style={{ margin: 0 }} size={15} icon={"check"} iconColor="green" />}
                    <Text style={{ color: col }}>{op.info.user.name} {op.info.user?.mobile ? "(" + op.info.user?.mobile + ")" : ""}</Text>
                </View>
                <Text style={{ color: col }}>{op.info?.appliedTo?.length > 0 ? "Allocated to :" : ""}
                    {op.info?.appliedTo?.map((io, i) => {
                        return <Text>{i > 0 ? ", " : ""} {io.Property + "=" + Global.RS + io.Amount}</Text>
                    })}
                </Text>
                <Text style={{ color: col }}>{op.info.year} {(op.info.remakrs ? " - " + op.info.remakrs : "")}</Text>
                <Divider />
                {/* <Text>{op.info.stamp}</Text> */}
            </TouchableOpacity>

            {isOpen && <Modal
                isVisible={isOpen}
                style={{ margin: 0 }}
                onBackdropPress={() => setTimeout(() => { setOpen(false) })}
                onDismiss={() => setOpen(false)}>
                <View style={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width, justifyContent: "center", backgroundColor: Global.THEME.surfaceVariant }}>
                    <Text style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{op.info.receiptNo}...</Text>
                    <Text style={{ padding: 13 }}>{op.info.user.name} (Owner of
                        {op.info.properties?.map((io, i) => {
                            return <Text>{i > 0 ? ", " : ""} {io.pno}</Text>
                        })})
                    </Text>
                    <Text style={{ padding: 13 }}>{op.info?.appliedTo?.length > 0 ? "Allocated to :" : ""}
                        {op.info?.appliedTo?.map((io, i) => {
                            return <Text>{i > 0 ? ", " : ""} {io.Property + "=" + Global.RS + io.Amount}</Text>
                        })}
                    </Text>

                    <TouchableOpacity onPress={() => {
                        onClose();
                        op.navigation.navigate('Receipt',
                            { receipt: op.info.id })
                    }}  >

                        <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                            <IconButton icon="printer-outline" />
                            <Text style={{ flex: 1, paddingLeft: 10 }} >Print Receipt</Text>
                            <IconButton icon="content-copy" onPress={() => copyToClipboard("https://ashwameghta.web.app/rec/" + op.info.id)} />
                            <IconButton icon="share" onPress={() => onShare("Sharing Receipt", "https://ashwameghta.web.app/rec/" + op.info.id)} />
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        onClose();
                        op.navigation.navigate(
                            'Statement', { user: op.info.paidBy, editable: op?.super, pno: undefined, party: undefined, type: op?.super ? 2 : op?.committee ? 1 : 0 }
                        )
                    }}>
                        <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                            <IconButton icon="format-list-bulleted" />
                            <Text style={{ paddingLeft: 10, flex: 1 }}>{"View User Statement "}</Text>
                            <IconButton icon="content-copy" onPress={() => copyToClipboard("https://ashwameghta.web.app/stat/" + op.info.paidBy)} />
                            <IconButton icon="share" onPress={() => onShare("Statement Sharing", "https://ashwameghta.web.app/stat/" + op.info.paidBy)} />
                        </View>
                    </TouchableOpacity>
                    {
                        op.super &&
                        <TouchableOpacity onPress={() => {
                            onClose();
                            op.navigation.navigate(
                                'PaymentForm', { data: op.info }
                            )
                        }}  >
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton icon="pencil-outline" />
                                <Text style={{ paddingLeft: 10 }} >Edit Payment</Text>
                            </View>
                        </TouchableOpacity>
                    }

                    {
                        (op.super || op.committee) &&

                        <TouchableOpacity onPress={() => { setOpen(false), op.info.status == 11 ? setPDrawer(true) : Toast.show("Only Successfull Payments can be allocated") }}>
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton icon="cog-play-outline" />
                                <Text style={{ paddingLeft: 10 }}>Allocate to Properties</Text>
                            </View>
                        </TouchableOpacity>
                    }

                    <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                        <IconButton icon="help" />
                        <Text style={{ paddingLeft: 10 }}>Mark as </Text>
                        {
                            op.super && <Button labelStyle={{ color: "green" }} onPress={() => mark(11)} icon="check">Successfull</Button>
                        }
                        {(op.super || op.committee) && <Button labelStyle={{ color: "red" }} onPress={() => mark(-1)} icon="close-circle">Failed</Button>}
                    </View>


                    {op?.super && <TouchableOpacity onPress={() => { onClose(); setDeleConfirmation(true) }}  >
                        <View style={{ flexDirection: "row", alignItems: "center", }}>
                            <IconButton icon="delete" />
                            <Text style={{ paddingLeft: 10 }} >Delete Payment</Text>
                        </View>
                    </TouchableOpacity>}
                    <IconButton icon="close" style={{ position: "absolute", right: 0, top: 0 }} size={24} onPress={() => onClose()} />
                </View>
            </Modal>}
            {isPayment && <Modal
                backdropOpacity={0.5}
                style={{ margin: 0 }}
                isVisible={isPayment}
                onBackdropPress={() => setTimeout(() => { setPDrawer(false) })}

                onDismiss={() => setPDrawer(false)}>
                <View style={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width, justifyContent: "center", backgroundColor: Global.THEME.surfaceVariant }}>
                    <Text style={{ padding: 13, fontWeight: "bold", flex: 1 }}>Apply {op.info.receiptNo}, Amount ({op.info.amount})...</Text>
                    <View style={{ alignSelf: "stretch", flex: 1 }}>
                        {op.info.properties.map((p, pii) => {
                            return <View style={{ alignItems: "center", justifyContent: "center", flexDirection: "row", alignSelf: "stretch", flex: 1 }}>
                                <Text >{pii + 1}.</Text>
                                <TextInput placeholder={p.pno}
                                    style={{ alignSelf: "stretch", flex: 1 }}
                                    onChangeText={(t) => {
                                        const k = p.pno
                                        const ob = { ...pinput }
                                        ob[k] = parseInt(t)
                                        setPayment(ob)
                                    }} />
                            </View>
                        })}
                        <Text>Total : {sum}</Text>
                        <Text style={{ fontWeight: "bold", color: (op.info.amount - sum) == 0 ? "green" : (op.info.amount - sum) > 0 ? "red" : "orange" }}>Pending : {op.info.amount - sum}</Text>


                    </View>
                    <Button
                        labelStyle={{ fontSize: 20, fontWeight: "bold", color: ((op.info.amount - sum) !== 0) ? "gray" : "green" }}
                        disabled={(op.info.amount - sum) !== 0} onPress={() => {
                            if ((op.info.amount - sum) == 0) {
                                op.apply(op.info, pinput)
                                setPDrawer(false)
                            }
                        }
                        }>Apply</Button>
                    <IconButton icon="close" style={{ position: "absolute", right: 0, top: 0 }} size={24} onPress={() => setPDrawer(false)} />
                </View>
            </Modal>}
            {deleConfirmation && <Modal
                style={{ margin: 0 }}
                onBackdropPress={() => setTimeout(() => { setDeleConfirmation(false) })}
                onDismiss={() => setDeleConfirmation(false)}
                isVisible={deleConfirmation} >
                <View style={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width, justifyContent: "center", backgroundColor: Global.THEME.surfaceVariant }}>
                    <Text variant='headlineSmall'>Delete Payment ({op.info.receiptNo})? </Text>
                    <Text style={{ padding: 10 }}>Note : Deleting  will delete payment and it related entries into account</Text>
                    <Text style={{ padding: 10 }}>Note : However receipt no. used in payment cannot be reused</Text>
                    <Text style={{ padding: 10 }}>Note : It is advisable to take backup before proceeding</Text>
                    <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent" }}>
                        <Button style={{ flex: 1 }} onPress={() => setDeleConfirmation(false)}>Cancel</Button>
                        <Button style={{ flex: 1 }} onPress={() => DeleteEntry(0)}>Delete Payment</Button>
                    </View>
                </View>

            </Modal>
            }
        </View >)
}


export default React.memo(PaymentRow)