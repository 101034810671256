import { collection, doc, getFirestore, setDoc, writeBatch } from 'firebase/firestore';
import { FlatList, ScrollView, StyleSheet } from 'react-native';
import { Button, Chip, FAB, IconButton, TextInput } from 'react-native-paper';
import { Text, View } from '../components/Themed';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DCalendar from '../components/DCalendar';
import Toast from 'react-native-root-toast';
import DInput from '../components/DInput';
import { useSelector } from 'react-redux';
import Global from '../constants/global';



export default function InExForm({ route, navigation }) {
    const [data, setData] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const inexs = useSelector(state => state.list.InEx)
    const [hlist, setHList] = useState([])

    function setStatus(entry) {
        const v: [] = []
        data.approvals.map(mn => {
            const m = { ...mn }
            if (m.month == entry.month) {
                m.amount = entry.amount
                if (m.amount > 0) {
                    m.status = 1
                } else {
                    m.status = 0
                }
            }
            v.push(m)
        })
        setData(p => ({ ...p, approvals: v }))
    }

    useEffect(() => {
        let d = route?.params?.data
        if (d?.frequency == undefined) {
            d.frequency = 1
        }
        if (!d?.approvals) {
            d.approvals = [
                { month: 3, amount: 0, status: 0 },
                { month: 4, amount: 0, status: 0 },
                { month: 5, amount: 0, status: 0 },
                { month: 6, amount: 0, status: 0 },
                { month: 7, amount: 0, status: 0 },
                { month: 8, amount: 0, status: 0 },
                { month: 9, amount: 0, status: 0 },
                { month: 10, amount: 0, status: 0 },
                { month: 11, amount: 0, status: 0 },
                { month: 0, amount: 0, status: 0 },
                { month: 1, amount: 0, status: 0 },
                { month: 2, amount: 0, status: 0 }
            ]
        }
        setData(d)
    }, [route?.params])

    useEffect(() => {
        const yrs1 = [...new Set(inexs.map(item => item.head))];
        const yrs = yrs1.filter(item => (item !== ""))
        setHList(yrs)
    }, [inexs])


    function save() {
        console.log("data", data)
        if (!data.head) {
            Toast.show("Error: Head cannot be empty")
            return
        }
        if (!data.amount) {
            Toast.show("Error: Amount cannot be empty")
            return
        }
        if (!data.year) {
            Toast.show("Error: Year Not selected")
            return
        }
        setLoading(true)
        setTimeout(() => {
            try {
                const db = getFirestore()
                const working = { ...data }
                let ref
                if (working?.id) {
                    ref = doc(db, "InEx", working.id)
                } else {
                    ref = doc(collection(db, "InEx"))
                    working.id = ref.id

                }
                setDoc(ref, working, { merge: true })
                Toast.show("Saved")
                setTimeout(() => {
                    navigation.goBack()
                }, 1000)
            } catch (e) {
                Toast.show("Error while saving record : Try later again")
            }
            setLoading(false)
        })
    }

    return (
        <ScrollView>
            <View style={{ flex: 1, alignSelf: "stretch" }}>
                <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                    <Chip selected={data?.year == "2019-20"} onPress={() => setData(p => ({ ...p, year: "2019-20" }))}>2019-20</Chip>
                    <Chip selected={data?.year == "2020-21"} onPress={() => setData(p => ({ ...p, year: "2020-21" }))}>2020-21</Chip>
                    <Chip selected={data?.year == "2021-22"} onPress={() => setData(p => ({ ...p, year: "2021-22" }))}>2021-22</Chip>
                    <Chip selected={data?.year == "2022-23"} onPress={() => setData(p => ({ ...p, year: "2022-23" }))}>2022-23</Chip>
                    <Chip selected={data?.year == "2023-24"} onPress={() => setData(p => ({ ...p, year: "2023-24" }))}>2023-24</Chip>
                    <Chip selected={data?.year == "2024-25"} onPress={() => setData(p => ({ ...p, year: "2024-25" }))}>2024-25</Chip>
                </View>

                {hlist && data && <DInput
                    data_list={hlist}
                    isObject={false}
                    key={data?.head}
                    value={data?.head}
                    placeholder={"Head Name"}
                    onChange={(i) =>
                        setData(p => ({ ...p, head: i }))
                    }
                    onSelection={(i) =>
                        setData(p => ({ ...p, head: i.item }))
                    }
                    renderItem={(i) =>
                        <Text style={{ padding: 10 }}>{i.item}</Text>
                    }
                />
                }
                <TextInput
                    placeholder="Title of Entry"
                    style={{ alignSelf: "stretch", }}
                    value={data?.title ? data.title : ""}
                    onChangeText={(text) => setData(p => ({ ...p, title: text }))} />

                <View style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <Text>Frequency :</Text>
                    <View style={{ flexDirection: "row" }}>
                        <Chip selected={data?.frequency == 0} onPress={() => setData(p => ({ ...p, frequency: 0 }))}>Monthly</Chip>
                        <Chip selected={data?.frequency == 3} onPress={() => setData(p => ({ ...p, frequency: 3 }))}>One Time</Chip>
                    </View>
                </View>

                <ScrollView horizontal>
                    {
                        data?.frequency == 0 ?
                            <View style={{ flexDirection: "row", alignSelf: "stretch", flex: 1 }}>

                                {
                                    data.approvals.map((m, i) =>
                                        <View key={i + ""} style={{ width: 125 }}>
                                            <TextInput
                                                value={m.amount + ""}
                                                style={{ width: 125 }}
                                                onChangeText={(text) => {
                                                    const oyr = text.replace(/[^0-9]/g, '')
                                                    let vn = 0
                                                    if (oyr) {
                                                        vn = parseFloat(oyr)
                                                    }
                                                    const mm = { ...m }
                                                    mm.amount = vn
                                                    if (mm.amount > 0) {
                                                        mm.status = 1
                                                    }
                                                    setStatus(mm)
                                                }}
                                                label={Global.getMonthName(m.month)}
                                            />
                                            <Text style={{ width: 125 }}>{m.status == 1 ? "Approved" : m.status == 2 ? "Rejected" : ""}</Text>
                                        </View>
                                    )
                                }
                            </View>
                            : <></>
                    }
                </ScrollView>
                <TextInput
                    placeholder="Description"
                    style={{ alignSelf: "stretch", }}
                    value={data?.desc ? data.desc : ""}
                    onChangeText={(text) => setData(p => ({ ...p, desc: text }))} />

                <TextInput
                    placeholder="Remarks"
                    style={{ alignSelf: "stretch", }}
                    value={data?.remarks ? data.remarks : ""}
                    onChangeText={(text) => setData(p => ({ ...p, remarks: text }))} />
                <DCalendar
                    label={"Date"}
                    current={data?.date ? data.date : dayjs().unix()}
                    callback={(stamp: number, isValid: boolean, obj: any) => {
                        if (isValid) {
                            setData(prev => ({ ...prev, date: stamp }))
                        }
                    }} />


                <TextInput
                    placeholder="Amount"
                    style={{ alignSelf: "stretch", }}
                    value={data?.amount ? "" + data.amount : ""}
                    keyboardType="number-pad"
                    onChangeText={(text) => {
                        const oyr = text.replace(/[^0-9]/g, '')
                        let vn = 0
                        if (oyr) {
                            vn = parseFloat(oyr)
                        }
                        setData(p => ({ ...p, amount: vn }))
                    }} />

                <View style={{ flexDirection: "row", paddingTop: 20 }}>
                    <Button style={{ flex: 1 }} onPress={() => navigation.goBack()}>Back</Button>
                    <Button loading={loading} mode="contained" style={{ flex: 1 }} onPress={() => save()}>Save</Button>
                </View>
            </View>
        </ScrollView>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
