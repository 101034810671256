import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, ScrollView, Image } from 'react-native';
import * as Notifications from 'expo-notifications';
import { View } from '../components/Themed';
import { TextInput, Button, HelperText, ActivityIndicator, Text } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux'
import { RootTabScreenProps } from '../types';
import { loginTasks } from '../task/loginTasks';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Global from '../constants/global';
import { useTheme } from 'react-native-paper';
import { doc, getFirestore, setDoc, collection, query, where, getDocs } from 'firebase/firestore';

export default function Logout({ navigation }) {
    return <View style={{ alignSelf: "stretch", alignItems: "center", justifyContent: 'center', flex: 1 }}>
        <Image style={{ width: 150, height: 200 }} source={{ uri: require("../assets/images/logout.png") }} />

        <Text style={{ paddingTop: 20, paddingBottom: 20, alignSelf: "stretch", textAlign: "center" }}>Are you sure to Logout ?</Text>
        <View style={{ flexDirection: "row" }}>
            <Button mode="contained" onPress={() => navigation.goBack()}>Back</Button>
            <Button onPress={() => {
                setTimeout(() => {
                    AsyncStorage.setItem('savedLoginId', "").then(e => {
                        navigation.navigate("Login")
                    })
                })
            }}>Logout</Button>
        </View>
    </View>
}


