import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dimensions, Share, StyleSheet, TouchableOpacity } from 'react-native';
import Global from "../constants/global"
import { useSelector } from 'react-redux';
import { doc, getFirestore, setDoc, writeBatch } from 'firebase/firestore';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import DAvatar from './DAvatar'
import { Text, Card, IconButton, Modal, Portal, Dialog, Button, Divider, HelperText, TextInput } from 'react-native-paper';
import Toast from 'react-native-root-toast';
import { applyMiddleware } from '@reduxjs/toolkit';
import * as Clipboard from 'expo-clipboard';

interface option {
    info: any
    apply(payment: any, pinput: any): void
    navigation: any
    index: number
}
const InExRow = (op: option) => {
    const [deleConfirmation, setDeleConfirmation] = useState(false);
    const [isOpen, setOpen] = useState(false);
    function onClose() { setOpen(false) }
    const col = Global.getColor(op.index)

    function DeleteEntry(type: number) {
        const db = getFirestore()
        const ref = doc(db, "InEx", op.info.id)
        const batch = writeBatch(db)
        batch.delete(ref)
        batch.commit()
        setDeleConfirmation(false)
        Toast.show("Entry Hard Deleted")
    }


    return (
        <View style={{ flexDirection: "row", marginTop: 8, marginBottom: 8, flex: 1 }}>
            <TouchableOpacity onPress={() => setOpen(true)} style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", marginLeft: 4, alignSelf: "stretch" }}>
                    <Text variant="bodyLarge" style={{ flex: 1, color: col, fontWeight: "bold" }}>{op.info.head}</Text>
                    <Text style={{ color: col }}>{Global.RS + " " + op.info.amount}</Text>
                    <Text style={{ paddingLeft: 13, paddingRight: 13, fontSize: 18 }}>⌵</Text>
                </View>
                <Text style={{ color: col }}>{op.info.desc}</Text>
                <Text style={{ color: col }}>{op.info.remarks}</Text>
                <Divider />
            </TouchableOpacity>

            <Portal>
                <Modal style={{ backgroundColor: "rgba(0,0,0,0.8)" }} visible={isOpen} contentContainerStyle={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width }} dismissable onDismiss={() => setOpen(false)}>
                    <View style={{ justifyContent: "center", backgroundColor: Global.THEME.surfaceVariant }}>
                        <Text style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{op.info.title}...</Text>
                        <TouchableOpacity onPress={() => {
                            onClose();
                            op.navigation.navigate('InExForm', { data: op.info }
                            )
                        }}  >
                            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                <IconButton icon="pencil-outline" />
                                <Text style={{ paddingLeft: 10 }} >Edit </Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { onClose(); setDeleConfirmation(true) }}  >
                            <View style={{ flexDirection: "row", alignItems: "center", }}>
                                <IconButton icon="delete" />
                                <Text style={{ paddingLeft: 10 }} >Delete</Text>
                            </View>
                        </TouchableOpacity>
                    </View>

                    <IconButton icon="close" style={{ position: "absolute", right: 0, top: 0 }} size={24} onPress={() => onClose()} />
                </Modal>
            </Portal>
            <Portal>
                <Modal style={{ backgroundColor: "rgba(0,0,0,0.8)" }} onDismiss={() => setDeleConfirmation(false)} visible={deleConfirmation} contentContainerStyle={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width }} >
                    <View style={{ justifyContent: "center", backgroundColor: Global.THEME.surfaceVariant }}>
                        <Text variant='headlineSmall'>Delete Entry ({op.info.title})? </Text>
                        <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent" }}>
                            <Button style={{ flex: 1 }} onPress={() => setDeleConfirmation(false)}>Cancel</Button>
                            <Button style={{ flex: 1 }} onPress={() => DeleteEntry(0)}>Delete Entry</Button>
                        </View>
                    </View>

                </Modal>
            </Portal>

        </View>)
}


const styles = StyleSheet.create({
    item: {
        textColor: 'white',
        fontWeight: '900',
        flex: 1,
        alignItems: "center",
        marginVertical: 2,
        marginHorizontal: 2,
        paddingLeft: 20,
        flexDirection: "row"
    },
    phone: {
        color: "teal"
    },
    title: {
        fontWeight: "bold"
    },
    city: {
        fontStyle: "italic",
    },
    subset: {
        fontSize: 9,
    },
    onletter: {
        fontSize: 20
    }
});
//const Bhudev = React.memo(OBhudev)
export default InExRow