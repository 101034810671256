import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, ScrollView, Platform, Text, Dimensions } from 'react-native';
import { Image } from "expo-image"
import * as Notifications from 'expo-notifications';
import { View } from '../components/Themed';
import { TextInput, Button, HelperText, ActivityIndicator } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux'
import { RootTabScreenProps } from '../types';
import { loginTasks } from '../task/loginTasks';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Global from '../constants/global';
import { useTheme } from 'react-native-paper';
import { doc, getFirestore, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { isMap } from 'immer/dist/internal';
import { useAssets } from 'expo-asset';

export default function Login({ navigation }) {
    const [loginid, setLoginid] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState({ message: "", isErr: false, processing: false });
    const [secure, setSecure] = useState(true);
    const [showScreen, setScreen] = useState(false);
    const [assets, error] = useAssets([require("../assets/images/login3.jpg"), require("../assets/images/login2.jpg")]);


    const dispatch = useDispatch();
    useEffect(() => {
        AsyncStorage.getItem('savedLoginId').then(e => {
            try {
                //console.log("ee", JSON.stringify(e))
                if (e) {
                    process(true, e)
                } else {
                    setScreen(true)
                }
            } catch { }
        })
    }, [])

    const storeData = async (value) => {
        try {
            await AsyncStorage.setItem('savedLoginId', value)
        } catch (e) {
            // saving error
        }
    }


    async function process(secure: boolean, lid: string) {

        if ((secure && lid) || (password && loginid)) {
            const db = getFirestore()
            const citiesRef = collection(db, "Users");

            // Create a query against the collection.
            let q = undefined
            if (secure) {
                q = await query(citiesRef, where("mobile", "==", lid));
            } else {
                q = await query(citiesRef, where("mobile", "==", loginid), where("password", "==", password));
            }
            let res = undefined
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                res = doc.data()
            });
            if (res) {
                dispatch(loginTasks.actions.allowLogin({ user: res }));
                storeData(res.mobile)
                setMessage({ message: "Login Successfull", isErr: false, processing: false })
                if (Platform.OS == "android") {
                    setTimeout(() => {
                        try {
                            Notifications.getExpoPushTokenAsync().then(token => {
                                // console.log("bhudevnoti 2", token)
                                if (token?.data) {
                                    if (!res?.token) {
                                        // console.log("bhudevnoti 3", token)
                                        res.token = "nothing"
                                    }
                                    if (token.data !== res.token) {
                                        // console.log("bhudevnoti 4", token)
                                        const db = getFirestore()
                                        setDoc(doc(db, "Users", res.id), { token: token.data }, { merge: true })
                                    }
                                }
                            }).catch(e =>
                                console.log("bhudevnoti 5", e)
                            )
                            //                    ExponentPushToken[pnxBpkM84_5-2sa-rLEeB9]
                        } catch (e) {
                            console.log("bhudevnoti 6", e)
                        }
                        // console.log("bhudevnoti 5")

                    })
                    //            navigation.navigate("Home")
                }
//                console.log(navigation)
                navigation?.navigate("Home")
            } else {
                if (secure) {
                    setScreen(true)
                } else {
                    dispatch(loginTasks.actions.rejectLogin(res));
                    setMessage({ message: "Wrong Credentials", isErr: true, processing: false })
                }
            }
        } else {
            setMessage({ message: "Credentials missing", isErr: true, processing: false })
        }
    }
    async function processLogin() {
        setMessage({ message: "Working", isErr: false, processing: true })
        setTimeout(() => {
            process(false, "")
        })
    }
    const { colors } = useTheme();
    const mob = Global.isMobile()
    const Welcome = () => <View style={{ alignItems: "center", justifyContent: "center", padding: 20, backgroundColor: "transparent" }}>
        <Text style={{ color: mob ? "white" : "gray", alignSelf: "stretch", textAlign: "center" }} variant="headlineSmall" >Welcome</Text>
        <Text style={{ fontSize: 30, paddingBottom: 20, color: mob ? "white" : "orange", alignSelf: "stretch", textAlign: "center" }} variant="headlineLarge" >Ashwmegh Member</Text>
        <Text style={{ color: mob ? "white" : "gray" }} variant="headlineSmall" >Lets get Started...</Text>

    </View>

    return (showScreen ?
        <SafeAreaProvider style={{ backgroundColor: colors.background }}>
            <ScrollView keyboardShouldPersistTaps="always" >
                <View style={{ flexDirection: mob ? "column" : "row" }}>
                    {assets && <Image contentFit={"fill"}
                        style={{
                            height: mob ? Dimensions.get("window").height / 2 : Dimensions.get("window").height,
                            width: mob ? (Dimensions.get("window").width) : Dimensions.get("window").width / 2.5
                        }} source={mob ? assets[0] : assets[1]} />}
                    {mob &&
                        <View
                            style={{
                                height: Dimensions.get("window").height / 2,
                                width: (Dimensions.get("window").width), position: "absolute", top: 0, backgroundColor: "rgba(0,0,0,0.6)"
                            }}
                        >
                        </View>}
                    {mob &&
                        <View style={{
                            position: mob ? "absolute" : "relative", backgroundColor: "transparent", alignItems: "center", justifyContent: "center", height: Dimensions.get("window").height / 2.5,
                            width: (Dimensions.get("window").width)
                        }}>
                            <Welcome />
                        </View>
                    }

                    <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
                        {!mob &&
                            <View style={{ position: "relative", alignItems: "center", justifyContent: "center" }}>
                                <Welcome />
                            </View>
                        }
                        <TextInput
                            autoCorrect={false}
                            autoFocus
                            value={loginid}
                            label='Login id (Mobile no / Email id)'
                            style={{ backgroundColor: "transparent", fontSize: 15, alignSelf: "stretch" }}
                            onChangeText={text => setLoginid(text)} />

                        <TextInput
                            value={password}
                            secureTextEntry={secure}
                            label='Password'
                            autoCorrect={false}
                            style={{ backgroundColor: "transparent", alignSelf: "stretch" }}
                            right={<TextInput.Icon
                                icon={secure ? "eye" : 'eye-off'}
                                size={20}
                                onPress={() => setSecure(!secure)} />
                            }
                            onChangeText={text => setPassword(text)} />
                        <HelperText type={message.isErr ? "error" : "info"} style={{ padding: 10 }} >{message.message}</HelperText>

                        <View style={{ flexDirection: "row", margin: 10, justifyContent: "center", alignItems: "center", backgroundColor: "transparent", alignSelf: "stretch" }}>
                            <Button mode="text" onPress={() => navigation.goBack()} style={{ flex: 1 }} >Back</Button>
                            <Button loading={message.processing} mode="contained" onPress={() => { processLogin() }} style={{ flex: 1 }} >Login</Button>
                        </View>

                    </View>
                </View>
                <View style={{ alignSelf: "flex-end", marginTop: 50, alignItems: "center", justifyContent: "center", width: Dimensions.get("window").width, background: "transparent", flexDirection: "row", flexWrap: "wrap" }}>
                    <Text style={{ textDecorationLine: "underline", paddingLeft: 4, paddingRight: 4 }}>• About Us</Text>
                    <Text style={{ textDecorationLine: "underline", paddingLeft: 4, paddingRight: 4 }}>• Terms & Conditions</Text>
                    <Text style={{ textDecorationLine: "underline", paddingLeft: 4, paddingRight: 4 }}>• Refund policy</Text>
                    <Text style={{ textDecorationLine: "underline", paddingLeft: 4, paddingRight: 4 }}>• Contact Us</Text>
                    <Text style={{ textDecorationLine: "underline", paddingLeft: 4, paddingRight: 4 }}>• Privacy Policy</Text>
                </View>
            </ScrollView>
        </SafeAreaProvider >
        : <View>
            <ActivityIndicator style={{ margin: 30 }} animating />
        </View>
    );

}


