import { useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { HelperText, Button } from "react-native-paper";
import { Text } from "react-native";
import { useSelector } from "react-redux";
import { View } from "./Themed";
import ScaledImage from "./ScaledImage";
import ScreenImage from "../screens/ScreenImage";
import Hemberger from "./Hemberger";

export default function Chart(op) {
    const acc = useSelector(state => state.list.Entries)
    const users = useSelector(state => state.list.Users)
    const properties = useSelector(state => state.list.Properties)
    const inex = useSelector(state => state.list.InEx)
    const [values, setValues] = useState({ list: [], max: 0 })
    const [data, setData] = useState({ properties: 0, office: 0, hotel: 0, hospital: 0, classes: 0, closed: 0, onRent: 0, utype: [] })

    useEffect(() => {
        if (acc && inex) {
            const yrs1 = [...new Set(acc.map(item => item.year))];
            const yrs = yrs1.filter(item => (item !== "0"))
            const yr = yrs.sort((a, b) => parseInt(b.replace("-", "")) - parseInt(a.replace("-", "")))

            const allData: [] = []
            let max = 0
            yr.map(y => {
                const ydata = acc.filter(e => e.year == y)
                let cr = 0
                let dr = 0
                ydata.map(e => {
                    if (e.side == 1) {
                        cr = cr + e.amount
                    } else {
                        dr = dr + e.amount
                    }
                    if (dr > max) {
                        max = dr
                    }
                    if (cr > max) {
                        max = cr
                    }
                })
                let income = 0
                let exp = 0
                inex.filter(e => {
                    if (e.year == y) {
                        if (e.frequency == 3) {
                            if (e.type == 0) {
                                exp = exp + e.amount
                            } else {
                                income = income + e.amount
                            }
                        } else {
                            e.approvals?.map(eyr => {
                                if (eyr.status == 1) {
                                    if (e.type == 0) {
                                        exp = exp + eyr.amount
                                    } else {
                                        income = income + eyr.amount
                                    }
                                }
                            })
                        }
                    }
                })

                allData.push({ year: y, charges: Math.round(dr), receipt: Math.round(cr), max: max, income: income, expence: exp })
            })

            setValues({ list: allData, max: max })
        }
    }, [acc, inex])
    useEffect(() => {
        let office = 0
        let hotel = 0
        let hospital = 0
        let classes = 0
        let hostel = 0
        if (users && properties) {
            const yrs1 = [...new Set(users.map(item => item?.type?.trim()))];
            const yrs = yrs1.filter(item => (item !== ""))
            const utypes: [] = []
            yrs.map(y => {
                if (y !== "Developer" && y !== "Guest" && y !== "Super Admin") {
                    utypes.push({ type: y, len: users.filter(u => u.type == y).length })
                }
            })

            let onRent = 0
            let closed = 0
            properties.map(u => {
                closed = closed + (u?.clMonths?.filter(f => f?.status > 0).length > 0 ? 1 : 0)
                onRent = onRent + (u?.rentalMonths?.filter(f => f?.status > 0).length > 0 ? 1 : 0)
                if (u.type == 0) {
                    office = office + 1
                } else if (u.type == 1) {
                    hotel = hotel + 1
                } else if (u.type == 2) {
                    classes = classes + 1
                } else if (u.type == 3) {
                    hospital = hospital + 1
                } else if (u.type == 4) {
                    hostel = hostel + 1
                }
            })
            setData({ office: office, hotel: hotel, hospital: hospital, classes: classes, utype: utypes, closed: closed, onRent: onRent })
        }
    }, [users, properties])
    return (
        <View>
            <ScreenImage />
            {/* <View style={{ position: "absolute", backgroundColor: "transparent" }}>
                <Hemberger color={"white"} navigation={op.navigation} />
            </View> */}
            <ScrollView horizontal>
                <View style={{ marginTop: 20, backgroundColor: "transparent" }}>
                    <Text style={{ fontWeight: "bold" }}>Shortcut </Text>
                    <View style={{ flexDirection: "row" }}>
                        <Button style={{ margin: 10 }} onPress={() => op.navigation.navigate("Properties")}>Properties</Button>
                        <Button style={{ margin: 10 }} onPress={() => op.navigation.navigate("Users")}>Users</Button>
                        <Button style={{ margin: 10 }} onPress={() => op.navigation.navigate("Payments")}>Payments</Button>
                    </View>
                    <Text style={{ fontWeight: "bold" }}>Charges Vs Collection </Text>
                    <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                        {
                            values.list.map((y,i) => {
                                return <View key={i+""} style={{ flex: 1, alignItems: "center", justifyContent: "flex-end", backgroundColor: "transparent", padding: 10 }}>
                                    <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                                        <View style={{ flexDirection: "row", alignItems: "flex-end", backgroundColor: "transparent" }}>
                                            <Text style={{ backgroundColor: "#ffbfbf", width: 50, height: ((y.charges * 100) / values.max) }}></Text>
                                            <Text style={{ backgroundColor: "red", width: 50, height: ((y.expence * 100) / values.max) }}></Text>
                                            <View style={{ backgroundColor: "transparent" }}>
                                                <Text style={{ textAlign: "center" }}>{Math.round(((y.receipt + y.income) * 100) / y.charges)} %</Text>
                                                <Text style={{ backgroundColor: "green", width: 50, height: ((y.income * 100) / values.max), textAlign: "center" }}></Text>
                                                <Text style={{ backgroundColor: "#52eb85", width: 50, height: ((y.receipt * 100) / values.max), textAlign: "center" }}></Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                                        <Text style={{ width: 50, textAlign: "center", fontSize: 10 }}>{y.charges}</Text>
                                        <Text style={{ width: 50, textAlign: "center", fontSize: 10 }}>{y.expence}</Text>
                                        <Text style={{ width: 50, textAlign: "center", fontSize: 10 }}>{y.receipt + y.income}</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                                        <Text style={{ width: 50, textAlign: "center", fontSize: 9 }}>{"Charges"}</Text>
                                        <Text style={{ width: 50, textAlign: "center", fontSize: 9 }}>{"Expences"}</Text>
                                        <Text style={{ width: 50, textAlign: "center", fontSize: 9 }}>{"Rec + Inco"}</Text>
                                    </View>
                                    <Text style={{ alignSelf: "stretch", borderTopColor: "black", borderTopWidth: 1, borderStyle: "solid", textAlign: "center" }}>{y.year}</Text>
                                </View>


                            })
                        }
                    </View>
                    <HelperText>Note : receipts may inclued amount of previous year ! </HelperText>
                </View>
            </ScrollView >
            {/* <ScrollView horizontal>
                <View style={{ backgroundColor: "transparent" }}>
                    <Text style={{ fontWeight: "bold" }}>Income Vs Expences </Text>
                    <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                        {
                            values.map(y => {
                                return <View style={{ flex: 1, padding: 8, backgroundColor: "transparent" }}>
                                    <View style={{ flex: 1, alignItems: "center", backgroundColor: "transparent" }}>
                                        <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                                            <View style={{ flexDirection: "row", alignItems: "flex-end", backgroundColor: "transparent" }}>
                                                <Text style={{ backgroundColor: "#ffbfbf", width: 50, height: ((y.charges * 100) / y.max) }}></Text>
                                                <View style={{ backgroundColor: "transparent" }}>
                                                    <Text style={{ textAlign: "center" }}>{Math.round((y.receipt * 100) / y.charges)} %</Text>
                                                    <Text style={{ backgroundColor: "#52eb85", width: 50, height: ((y.receipt * 100) / y.max), textAlign: "center" }}></Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                                            <View style={{ flexDirection: "row", alignItems: "flex-end", backgroundColor: "transparent" }}>
                                                <Text style={{ backgroundColor: "#ffbfbf", width: 50, height: ((y.charges * 100) / y.max) }}></Text>
                                                <View style={{ backgroundColor: "transparent" }}>
                                                    <Text style={{ textAlign: "center" }}>{Math.round((y.receipt * 100) / y.charges)} %</Text>
                                                    <Text style={{ backgroundColor: "#52eb85", width: 50, height: ((y.receipt * 100) / y.max), textAlign: "center" }}></Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                                            <Text style={{ width: 50, textAlign: "center" }}>{y.charges}</Text>
                                            <Text style={{ width: 50, textAlign: "center" }}>{y.receipt}</Text>
                                        </View>
                                        <Text style={{ alignSelf: "stretch", borderTopColor: "black", borderTopWidth: 1, borderStyle: "solid", textAlign: "center", flex: 1 }}>{y.year}</Text>
                                    </View>
                                </View>

                            })
                        }
                    </View>
                    <HelperText>Note : receipts may inclued amount of previous year ! </HelperText>
                </View>
            </ScrollView> */}

            < Text style={{ fontWeight: "bold" }
            }> Property Information </Text >
            <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                <Text style={{ width: 100, textAlign: "center" }}>{"Properties"}</Text>
                <Text style={{ width: 100, textAlign: "center" }}>{properties?.length}</Text>
            </View>
            <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                <Text style={{ width: 100, textAlign: "center" }}>{"Offies"}</Text>
                <Text style={{ width: 100, textAlign: "center" }}>{data.office}</Text>
            </View>
            <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                <Text style={{ width: 100, textAlign: "center" }}>{"Hotel"}</Text>
                <Text style={{ width: 100, textAlign: "center" }}>{data.hotel}</Text>
            </View>
            <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                <Text style={{ width: 100, textAlign: "center" }}>{"Hospital"}</Text>
                <Text style={{ width: 100, textAlign: "center" }}>{data.hospital}</Text>
            </View>
            <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                <Text style={{ width: 100, textAlign: "center" }}>{"Classes"}</Text>
                <Text style={{ width: 100, textAlign: "center" }}>{data.classes}</Text>
            </View>
            <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                <Text style={{ width: 100, textAlign: "center" }}>{"On Rent"}</Text>
                <Text style={{ width: 100, textAlign: "center" }}>{data.onRent}</Text>
            </View>
            <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                <Text style={{ width: 100, textAlign: "center" }}>{"Closed"}</Text>
                <Text style={{ width: 100, textAlign: "center" }}>{data.closed}</Text>
            </View>
            <Text style={{ marginTop: 20, fontWeight: "bold" }}>User Information </Text>
            {
                data.utype.map((t,i) => {
                    return <View key={i+""} style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                        <Text style={{ width: 150, textAlign: "center" }}>{t.type}</Text>
                        <Text style={{ width: 100, textAlign: "center" }}>{t.len}</Text>
                    </View>

                })
            }
        </View >
    );
};