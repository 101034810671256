import { Image, StyleSheet, ScrollView, Dimensions, TouchableOpacity } from 'react-native';

import Hemberger from '../components/Hemberger';
import { View } from '../components/Themed';
import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Text, Divider, ActivityIndicator, Card, IconButton, Portal, Modal, TextInput, Button } from 'react-native-paper';
import dayjs from 'dayjs';
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore';
import Global from '../constants/global';
import Toast from 'react-native-root-toast';
import ReactToPrint from 'react-to-print';
import EntryOptions from './EntryOptions';
import OpBalOptions from './OpBalOptions';
import BackButton from './BackButton';

interface options {
    navigation: any,
    user: string,
    party: string,
    pno: string,
    type: number,
    editable: boolean
}
const noprint = `@media print {
    .noprint {display: none}
}`

export default function CoreStatement(op: options) {
    const allUsers = useSelector(state => state.list.Users)
    const allProperties = useSelector(state => state.list.Properties)
    const allAccounts = useSelector(state => state.list.Entries)
    const payments = useSelector(state => state.list.Payments)

    const [printing, setPrinting] = React.useState({ print: false, resolve: undefined })
    const [showParty, setShowParty] = React.useState(false)
    const ref = React.createRef();
    const [expandYear, setExpandYear] = React.useState([
        { year: "2019-20", expand: false },
        { year: "2020-21", expand: false },
        { year: "2021-22", expand: false },
        { year: "2022-23", expand: false },
        { year: "2023-24", expand: false },
        { year: "2024-25", expand: false },
    ])

    const [user, setUser] = React.useState(op?.user)
    const [editMode, setEditMode] = React.useState(false)
    const [pno, setPno] = React.useState(op?.pno)
    const [apply, setApply] = React.useState({ plist: [], user: {}, userData: undefined, loading: true, error: "" })
    const [refresh, justRefresh] = React.useState(0)
    const [refreshWorking, setRefreshWorking] = React.useState(false)
    const [opbal, setOpbal] = React.useState({ show: false, year: "", property: undefined, opbal: 0, value: 0 })
    const [entry, setEntry] = React.useState(undefined)
    const [entryDialog, setEntryDialog] = React.useState(false)
    const printButton = useRef()
    useEffect(() => {
        if (printing.print) {
            setTimeout(() => {
                printButton.current.click()
            }, 1000)
        }
    }, [printing])

    function getUName(name, property, year) {
        let cname = ""
        property?.ownership?.map(on => {
            if (on.year == year) {
                cname = on.name
            }
        })
        return cname ? cname : name
    }

    function RefreshBalance(prop) {
        setRefreshWorking(true)
        console.log(prop)
        prop?.statement?.map(e => {
            delete e.property
        })
        const property = JSON.parse(JSON.stringify(prop))
        // const property = { ...prop }
        property.balance = property.balance.sort((a, b) => parseInt(a.year.replace("-", "")) - parseInt(b.year.replace("-", "")))

        property.balance.map((y, i) => {
            const lst = allAccounts.filter(a => a.property == prop.pno && a.year == y.year)
            let dr = 0
            let cr = 0
            lst.map(e => {
                if (e.side == 0) {
                    dr = dr + e.amount
                } else {
                    cr = cr + e.amount
                }
            })
            if (property.balance[i + 1]) {
                property.balance[i + 1].amount = y.amount + cr - dr
            }
        })
        const db = getFirestore()
        const ref = doc(db, "Properties", prop.id)
        setDoc(ref, { balance: property.balance }, { merge: true })
        setRefreshWorking(false)
        Toast.show("Balance Updated for " + prop.pno)
    }

    async function load() {
        let uObj;
        let properties: [] = []
        let accounts: [] = []
        let payments: [] = []
        const db = getFirestore()
        const userRef = collection(db, "Users");
        const userQuery = query(userRef, where("id", "==", op.party));
        const udata = await getDocs(userQuery)
        udata.forEach((doc) => {
            uObj = doc.data()
        });
        if (uObj) {
            const propRef = collection(db, "Properties");
            const propQuery = query(propRef, where("currentOwner", "==", op.party));
            const pdata = await getDocs(propQuery)
            pdata.forEach((pdoc) => {
                properties.push(pdoc.data())
            });

            if (properties.length == 0) {
                setApply(p => ({ ...p, loading: false, error: "Party does not own any property" }))
            } else {
                const paymentRef = collection(db, "Payments");
                const paymentQuery = query(paymentRef, where("paidBy", "==", op.party));
                const pydata = await getDocs(paymentQuery)
                pydata.forEach((pydoc) => {
                    payments.push(pydoc.data())
                });

                const pstr: [] = []
                properties.map(al => {
                    pstr.push(al.pno)
                })

                console.log("properties str", pstr)
                const accountRef = collection(db, "Entries");
                const accountQuery = query(accountRef, where("property", "in", pstr));
                const adata = await getDocs(accountQuery)
                adata.forEach((adoc) => {
                    accounts.push(adoc.data())
                });
                console.log("acc", accounts)

                const alls: [] = []
                properties.map(prop => {
                    const ar = pipeProperty(prop, accounts)
                    alls.push(ar)
                })
                let allcls = 0;
                alls.map(st => {
                    let maxyr = 0
                    let clbal = 0;
                    st.statement.map(yr => {
                        const syr = yr.year + ""
                        const oyr = syr.replace(/[^0-9]/g, '')
                        const nyr = parseInt(oyr)
                        if (nyr > maxyr) {
                            clbal = (yr.opbal + yr.cr - yr.dr)
                            maxyr = nyr
                        }
                    })
                    allcls = allcls + clbal
                })
                setApply({ plist: alls, user: uObj, userData: alls.length > 1 ? { clbal: allcls } : undefined, loading: false, error: "" })
            }
        } else {
            setApply(p => ({ ...p, loading: false, error: "Information Provided does not belong to any party" }))
        }

    }

    React.useEffect(() => {
        if (op.user) {
            console.log("setting user", op.user)
            setUser(op.user)
        }
        if (op.pno) {
            console.log("setting pno", op.pno)
            setPno(op.pno)
        }
        if (op.party) {
            console.log("setting user", op.party)
            load()
        }
        if (op?.editable) {
            setEditMode(true)
        }
    }, [op])

    React.useEffect(() => {
        if (allUsers && allProperties && allAccounts && (user || pno)) {
            if (user) {
                console.log("processing", user)
                const usrObj = allUsers.find(u => u.id == user)
                const f = allProperties.filter(p => p.currentOwner == user)
                const alls: [] = []
                f.map(prop => {
                    const ar = pipeProperty(prop, allAccounts)
                    alls.push(ar)
                })
                let allcls = 0;
                alls.map(st => {
                    let maxyr = 0
                    console.log("st", st)
                    let clbal = 0;
                    st.statement.map(yr => {
                        const syr = yr.year + ""
                        const oyr = syr.replace(/[^0-9]/g, '')
                        const nyr = parseInt(oyr)
                        if (nyr > maxyr) {
                            clbal = (yr.opbal + yr.cr - yr.dr)
                            maxyr = nyr
                        }
                    })
                    allcls = allcls + clbal
                })
                setApply({ plist: alls, user: usrObj, userData: alls.length > 1 ? { clbal: allcls } : undefined, loading: false, error: "" })
            } else if (pno) {
                console.log("processing pno", pno)
                const fno = allProperties.find(p => p.pno == pno)
                const ar = pipeProperty(fno, allAccounts)
                const usrObj = allUsers.find(u => u.id == fno.currentOwner)
                setApply({ plist: [ar], user: usrObj, userData: undefined, loading: false, error: "" })
            }
        }
    }, [allUsers, allProperties, allAccounts, payments, user, pno])

    function pipeProperty(fno, allAcc) {
        const f = { ...fno }
        f.expand = editMode ? true : false

        const yrs1 = [...new Set(allAcc.map(item => item.year))];
        const yrs = yrs1.filter(item => (item !== "0"))
        yrs.sort((a, b) => parseInt(a.replace("-", "")) - parseInt(b.replace("-", "")))

        const statement: [] = []
        yrs.map(y => {
            const ents = allAcc.filter(a => a.property == f.pno && a.year == y)

            let cr = 0
            let dr = 0
            ents.map(e => {
                if (e.side == 0) {
                    dr = dr + e.amount
                } else {
                    cr = cr + e.amount
                }
            })

            const opbalO = f.balance.find(fy => fy.year == y);
            let opbalamt = 0
            if (opbalO) {
                opbalamt = opbalO.amount
            }

            statement.push({ year: y, entries: ents, expand: editMode ? true : false, opbal: opbalamt, cr: cr, dr: dr, property: f })
        })
        f.statement = statement
        console.log("statement", statement)
        return f
    }


    function expandYr(yr) {
        const eyr = [...expandYear]
        eyr.map(eyy => {
            if (eyy.year == yr) {
                eyy.expand = !eyy.expand
            }
        })
        setExpandYear(eyr)
    }
    const Elem = React.forwardRef((props, ref) => (<ScrollView >
        <ScrollView horizontal ref={ref}>
            <View style={{ flex: 1, alignSelf: "stretch", backgroundColor: printing.print ? "white" : "#F2F2F2", minWidth: 768, maxWidth: 768, padding: 10 }}>
                {
                    apply.loading ?
                        <View style={{ backgroundColor: "transparent", flex: 1, alignSelf: "stretch", }}>
                            <ActivityIndicator style={{ padding: 30 }} animating />
                        </View>
                        :
                        <View style={styles.container}>
                            {
                                apply.error ? <Text style={{ color: "red", fontSize: 18, padding: 20 }}>{apply.error}</Text> :
                                    <View style={styles.container}>
                                        <View style={{ flexDirection: "row", alignSelf: "stretch", justifyContent: "space-between", backgroundColor: "transparent" }}>
                                            {!op?.party && <div className="noprint"><BackButton navigation={op.navigation} /></div>}
                                            {!op?.party && <button onClick={() => {
                                                setShowParty(true)
                                                setTimeout(() => { setPrinting({ print: true, resolve: undefined }) }, 1000)
                                            }

                                            } className="noprint" style={{ right: 50 }} >Print with Qr</button>}
                                            <button onClick={() => setPrinting({ print: true, resolve: undefined, showParty: true })} className="noprint" style={{ right: 10 }} >Print</button>


                                            <ReactToPrint trigger={() => <button ref={printButton} className="noprint" style={{ display: "none", right: 10 }} >Print</button>}
                                                pageStyle={noprint}
                                                onAfterPrint={() => setPrinting({ print: false, resolve: undefined })}
                                                content={() => ref.current}
                                            />
                                        </View>
                                        <Text variant="headlineSmall" style={{ color: "black", alignSelf: "stretch", textAlign: "center" }}>Ashwamegh Owner's Association - Tower A</Text>
                                        <Text style={{ color: "black", alignSelf: "stretch", textAlign: "center" }}>1st Floor, Ashwamegh Tower-A, Opp.Sayaji Vihar Club, Rajmahal Road, Vaodara-390 001</Text>
                                        <Text style={{ color: "black", alignSelf: "stretch", textAlign: "center" }}>Pan no. AAHAA87608</Text>

                                        {op.party || showParty && <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                            <Image style={{ marginLeft: 20, height: 150, width: 150 }} resizeMode={"contain"} source={{ uri: "https://gtech.co.in/ashwamegh/images/qrpic.jpeg" }} />
                                            <View style={{ marginLeft: 20, backgroundColor: "transparent" }}>
                                                <Text style={{ color: "black", fontWeight: "bold" }}>Pay Using QR Code OR below mentioned Bank Details</Text>
                                                <Text style={{ color: "black" }}>Bank       : Yes Bank Ltd</Text>
                                                <Text style={{ color: "black" }}>Account No : 093588700000333</Text>
                                                <Text style={{ color: "black" }}>IFSC Code  : YESB0000935</Text>
                                                <Text style={{ color: "black" }}>UPI Id  : 9898921034@okbizaxis</Text>
                                            </View>
                                        </View>}

                                        <Text variant="headlineSmall" style={{ color: "black", alignSelf: "stretch", textAlign: "center" }}>Account Statement</Text>

                                        {apply.plist.map(e => {
                                            return <Card style={{ borderBottomColor: "black", borderBottomWidth: 3, borderStyle: "solid", backgroundColor: "white", margin: 10, marginTop: 35, padding: 8, paddingLeft: 12 }} mode={printing.print ? "contained" : "elevated"} elevation={printing.print ? 0 : 5} > {
                                                < View style={{ backgroundColor: "transparent" }}>
                                                    <View style={{ flex: 1, flexDirection: "row", backgroundColor: "transparent" }}>
                                                        <Text variant="titleSmall" style={{ color: "black", flex: 1, fontWeight: "bold" }}>Property :{e.pno}</Text>
                                                        <Text style={{ color: "black", flex: 1 }}>Type : {Global.type[e.type]}</Text>
                                                    </View>
                                                    <View style={{ flex: 1, flexDirection: "row", backgroundColor: "transparent" }}>
                                                        <Text style={{ color: "black", flex: 1 }}>Size : {e.size}</Text>
                                                        <Text style={{ color: "black", flex: 1 }}>Floor : {e.floor}</Text>
                                                    </View>
                                                    <View style={{ flex: 1, flexDirection: "row", backgroundColor: "transparent" }}>
                                                        <Text style={{ color: "black", flex: 1 }}>Current Owner : {e.cowner}</Text>
                                                        <Text style={{ color: "black", flex: 1 }}>Current Tenant : {e.ctenant}</Text>
                                                    </View>
                                                    <View style={{ flex: 1, flexDirection: "row", backgroundColor: "transparent" }}>
                                                        <Text style={{ color: "black", flex: 1 }}>Depoist Pending : {e.depositPending}</Text>
                                                        {editMode && <div className="noprint"><Button loading={refreshWorking} onPress={() => RefreshBalance(e)}> Refresh Balances </Button></div>}

                                                    </View>
                                                </View>
                                            }
                                                <Divider style={{ borderColor: "black", borderWidth: 0.5, marginTop: 10 }} />
                                                <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                                                    <Text style={{ color: "black", width: 120 }}>Date</Text>
                                                    <Text style={{ color: "black", flex: 1 }}>Particulars</Text>
                                                    <Text style={{ color: "black", width: 120, textAlign: "right" }}>Amount    </Text>
                                                </View>
                                                <Divider style={{ borderColor: "black", borderWidth: 0.5, marginTop: 10 }} />
                                                {e.statement.map(year => {
                                                    return <View style={{ backgroundColor: "transparent" }}>
                                                        {expandYear.find(y => y.year == year.year).expand ?
                                                            <View style={{ backgroundColor: "transparent" }}>
                                                                <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                                                    <View style={{ width: 120, backgroundColor: "transparent" }}>
                                                                        <Text style={{ color: "black", fontWeight: "bold" }} onPress={() => expandYr(year.year)}>Year : {year.year} </Text>
                                                                        <Text style={{ color: "black", fontSize: 10, fontStyle: "italic" }} onPress={() => expandYr(year.year)}>({getUName(apply?.user?.name, e, year.year)}) </Text>
                                                                    </View>
                                                                    <Text style={{ color: "black", flex: 1, fontWeight: "bold" }} onPress={() => expandYr(year.year)}>Opening Balance </Text>
                                                                    <Text style={{ paddingRight: 10, textAlign: "right", width: 120, fontWeight: "bold", color: printing.print ? "black" : year.opbal < 0 ? "red" : "green" }} onPress={() => { editMode ? setOpbal({ show: true, year: year.year, property: e, value: year.opbal, opbal: year.opbal }) : expandYr(year.year) }}>{Math.round(year.opbal * 100) / 100} {year.opbal < 0 ? " Dr" : " Cr"}</Text>
                                                                    {editMode && <div className="noprint"><IconButton size={14} style={{ padding: 0, margin: 0 }} icon="pencil-outline" /></div>}
                                                                </View>

                                                                {
                                                                    year.entries.map(yi => {
                                                                        return <TouchableOpacity onPress={() => {
                                                                            if (editMode) {
                                                                                setEntryDialog(true)
                                                                                setEntry({ ...yi })
                                                                            } else {
                                                                                setExpandYear(year.year)
                                                                            }
                                                                        }}>
                                                                            <View style={{ flex: 1, flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                                                                <Text style={{ color: "black", width: 120 }}>{yi.date > 0 ? dayjs.unix(yi.date).format("DD-MM-YYYY") : ""}</Text>
                                                                                {editMode && yi.edited && <div className="noprint"><IconButton style={{ padding: 0, margin: 0 }} size={14} icon="circle-edit-outline" /></div>}
                                                                                <Text style={{ color: "black", }}>{yi.headName}</Text>
                                                                                <Text style={{ color: "black", flex: 1 }}>, {yi.remarks}</Text>
                                                                                <Text style={{ paddingRight: 10, textAlign: "right", width: 120, color: printing.print ? "black" : (yi.side == 0 ? "red" : "green") }}>{yi.side == 0 ? yi.amount + " Dr" : yi.amount + " Cr"}</Text>
                                                                                {editMode && <div className="noprint"><IconButton style={{ padding: 0, margin: 0 }} size={14} icon="pencil-outline" /></div>}
                                                                            </View>
                                                                        </TouchableOpacity>
                                                                    })
                                                                }
                                                                <View style={{ paddingTop: 3, paddingBottom: 3, flexDirection: "row", backgroundColor: printing.print ? "white" : year.opbal + year.cr - year.dr >= 0 ? "#52eb85" : "#ffbfbf" }}>
                                                                    <Text style={{ color: "black", width: 120, }}>{year.year} </Text>
                                                                    <Text style={{ color: "black", flex: 1, }}>Closing Balance </Text>
                                                                    <Text style={{ paddingRight: 10, textAlign: "right", width: 120, color: printing.print ? "black" : ((year.opbal + year.cr - year.dr) < 0 ? "red" : "green") }}>{(Math.round((year.opbal + year.cr - year.dr) * 100) / 100) + ""} {(year.opbal + year.cr - year.dr) < 0 ? " Dr" : " Cr"}</Text>
                                                                </View>

                                                            </View> :
                                                            <View style={{ paddingTop: 3, paddingBottom: 3, flexDirection: "row", backgroundColor: printing.print ? "white" : year.opbal + year.cr - year.dr >= 0 ? "#52eb85" : "#ffbfbf" }}>
                                                                <View style={{ alignSelf: "stretch", flex: 1, backgroundColor: "transparent" }}>
                                                                    <TouchableOpacity onPress={() => { expandYr(year.year) }}>
                                                                        <View style={{ flexDirection: "row", alignSelf: "stretch", flex: 1, backgroundColor: "transparent" }}>
                                                                            <Text style={{ color: "black", flex: 1, }}>{year.year}</Text>
                                                                            <Text style={{ color: "black", flex: 1, }}>Op :{Math.round(year.opbal * 100) / 100}</Text>
                                                                            <Text style={{ color: "black", flex: 1, textAlign: "right" }}>Dr :{year.dr} </Text>
                                                                            <Text style={{ color: "black", flex: 1, textAlign: "right" }}>Cr :{year.cr} </Text>
                                                                            <Text style={{ paddingRight: 10, width: 120, textAlign: "right", color: printing.print ? "black" : (year.opbal + year.cr - year.dr) < 0 ? "red" : "green" }}>{Math.round((year.opbal + year.cr - year.dr) * 100) / 100}</Text>
                                                                        </View>
                                                                    </TouchableOpacity>
                                                                </View>

                                                                {editMode && (op.type == 2 || op.type == 1) && <div className="noprint"><IconButton size={14} style={{ padding: 0, margin: 0 }} onPress={() => {
                                                                    const db = getFirestore()
                                                                    const ref = doc(db, "Properties", e.id)
                                                                    if (ref) {
                                                                        const evv = e?.verified ? [...e?.verified] : []
                                                                        const endd = evv.findIndex(ey => ey == year.year)
                                                                        if (endd == -1) {
                                                                            evv.push(year.year)
                                                                        } else {
                                                                            evv.splice(endd, 1); // 2nd parameter means remove one item only
                                                                        }
                                                                        setDoc(ref, { verified: evv }, { merge: true })
                                                                    }
                                                                }} iconColor={e?.verified?.find(sy => sy == year.year) ? "green" : "black"} icon={e?.verified?.find(sy => sy == year.year) ? "check-decagram" : "decagram-outline"} /></div>}
                                                            </View>

                                                        }

                                                    </View>
                                                })}
                                                <Divider style={{ borderColor: "black", borderWidth: 0.5, borderStyle: "solid" }} />

                                            </Card>
                                        })}
                                        {
                                            apply?.userData ?
                                                <View style={{ marginTop: 20, paddingTop: 3, paddingBottom: 3, flexDirection: "row", backgroundColor: (apply.userData.clbal >= 0) ? "#52eb85" : "#ffbfbf" }}>
                                                    <Text variant='headlineSmall' style={{ color: "black", flex: 1, padding: 10 }}>{"All Property Closing balance Total"}</Text>
                                                    <Text variant='headlineSmall' style={{ width: 120, padding: 10, color: printing.print ? "black" : apply.userData.clbal < 0 ? "red" : "green" }}>{Math.round(apply.userData.clbal * 100) / 100}</Text>
                                                </View>
                                                : <></>
                                        }
                                        <Text style={{ color: "black", flex: 1, padding: 10 }}>{"(-) Negative value indicates Dr, while Positive values indicates Cr"}</Text>

                                        <Divider />
                                    </View >

                            }

                        </View >
                }
            </View >
        </ScrollView>
        <Portal>
            <Modal style={{ backgroundColor: "rgba(0,0,0,0.8)" }} onDismiss={() => setOpbal(p => ({ ...p, show: false }))} visible={opbal.show} contentContainerStyle={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width }} >
                <OpBalOptions year={opbal.year} property={opbal.property} opbal={opbal.opbal} value={0} close={() => setOpbal(p => ({ ...p, show: false }))} />
            </Modal>
        </Portal>

        <Portal>
            <Modal style={{ backgroundColor: "rgba(0,0,0,0.8)" }}
                onDismiss={() => setEntryDialog(false)}
                visible={entryDialog}
                contentContainerStyle={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width }} >
                <EntryOptions detail={entry} close={() => setEntryDialog(false)} />
            </Modal>
        </Portal>
    </ScrollView >))
    return <Elem ref={ref} />;
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignSelf: "stretch",
        backgroundColor: "transparent"
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
