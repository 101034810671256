import { configureStore } from "@reduxjs/toolkit";
import listTasks from "./listTask";
import loginTasks from "./loginTasks";


const store = configureStore({
    reducer: {
        list: listTasks,          
        login: loginTasks,          
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: process.env.NODE_ENV !== 'production',
})

export default store;