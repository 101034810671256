import { collection,  doc, getFirestore, setDoc, writeBatch } from 'firebase/firestore';
import { ScrollView, StyleSheet } from 'react-native';
import { Button, Chip, FAB, IconButton, TextInput } from 'react-native-paper';
import { Text, View } from '../components/Themed';
import { useEffect, useState } from 'react';
import Toast from 'react-native-root-toast';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';



export default function UserForm({ route, navigation }) {
    const [data, setData] = useState(undefined)
    const [hidden, setShowpass] = useState(true)
    const [loading, setLoading] = useState(false)
    const ulist = useSelector(state => state.list.Users)
    useEffect(() => {
        console.log("Reccd",route?.params?.data)
        const dd = { ...route?.params?.data }
        setData(dd)
    }, [route?.params])

    function save() {
        if (!data?.name) {
            Toast.show("Error: name cannot be empty")
            return
        }
        if (data?.mobile.length !== 10) {
            Toast.show("Error: mobile no. should be only 10 digits")
            return
        }
        if (data?.password.length <= 3) {
            Toast.show("Error: Password length should be min 4 chars")
            return
        }
        setLoading(true)
        setTimeout(async () => {
            try {
                const db = getFirestore()
                const working = { ...data }
                let ref
                if (working?.id) {
                    ref = doc(db, "Users", working.id)
                } else {
                    working.stamp = dayjs().unix()
                    ref = doc(collection(db, "Users"))
                    working.id = ref.id
                }
                if (!working?.type) {
                    working.type = "Other"
                }
                await setDoc(ref, working, { merge: true })
                Toast.show("Saved")
                setTimeout(() => {
                    navigation.goBack()
                }, 1000)
            } catch (e) {
                Toast.show("Error while saving record : Try later again")
            }
            setLoading(false)
        })
    }

    return (
        <View style={{ flex: 1, alignSelf: "stretch" }}>
            <TextInput
                label="Name"
                style={{ alignSelf: "stretch", }}
                value={data?.name ? data.name : ""}
                onChangeText={(text) => setData(p => ({ ...p, name: text }))} />

            <TextInput
                label="Address"
                style={{ alignSelf: "stretch", }}
                value={data?.address ? data.address : ""}
                onChangeText={(text) => setData(p => ({ ...p, address: text }))} />

            <TextInput
                label="Mobile"
                style={{ alignSelf: "stretch", }}
                value={data?.mobile ? data.mobile : ""}
                onChangeText={(text) => setData(p => ({ ...p, mobile: text }))} />

            <TextInput
                label="Password"
                style={{ alignSelf: "stretch", }}
                secureTextEntry={hidden}
                value={data?.password ? data.password : ""}
                right={<TextInput.Icon icon="eye" onPress={() => setShowpass(!hidden)} />}
                onChangeText={(text) => setData(p => ({ ...p, password: text }))} />

            <ScrollView horizontal>
                <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                    <Text style={{ fontWeight: "bold" }}>Committee Member :</Text>
                    <Chip selected={data?.type == "Committee"} onPress={() => setData(p => ({ ...p, type: "Committee" }))}>Yes</Chip>
                    <Chip selected={data?.type !== "Committee"} onPress={() => setData(p => ({ ...p, type: "Committee" }))}>No</Chip>
                </View>
            </ScrollView>

            <TextInput
                label="Occupation"
                style={{ alignSelf: "stretch", }}
                value={data?.designation ? data.designation : ""}
                onChangeText={(text) => setData(p => ({ ...p, designation: text }))} />

            <TextInput
                label="Website"
                style={{ alignSelf: "stretch", }}
                value={data?.website ? data.website : ""}
                onChangeText={(text) => setData(p => ({ ...p, website: text }))} />

            <TextInput
                label="Email"
                style={{ alignSelf: "stretch", }}
                value={data?.email ? data.email : ""}
                onChangeText={(text) => setData(p => ({ ...p, email: text }))} />

            <TextInput
                label="Description"
                style={{ alignSelf: "stretch", }}
                value={data?.desc ? data.desc : ""}
                onChangeText={(text) => setData(p => ({ ...p, desc: text }))} />

            <TextInput
                label="Remarks"
                style={{ alignSelf: "stretch", }}
                value={data?.remarks ? data.remarks : ""}
                onChangeText={(text) => setData(p => ({ ...p, remarks: text }))} />

            <TextInput
                label="Remarks"
                style={{ alignSelf: "stretch", }}
                value={data?.remarks ? data.remarks : ""}
                onChangeText={(text) => setData(p => ({ ...p, remarks: text }))} />

            <View style={{ flexDirection: "row", paddingTop: 20 }}>
                <Button style={{ flex: 1 }} onPress={() => navigation.goBack()}>Back</Button>
                <Button loading={loading} mode="contained" style={{ flex: 1 }} onPress={() => save()}>Save</Button>
            </View>
        </View>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
