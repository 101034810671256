import { collection, doc, getFirestore, setDoc, writeBatch } from 'firebase/firestore';
import { Share, StyleSheet, TouchableOpacity } from 'react-native';
import { Dimensions, FlatList, ScrollView } from 'react-native';
import UserRow from "../components/UserRow"
import BackButton from '../components/BackButton';
import { View } from '../components/Themed';
import { TextInput, Text, IconButton, FAB, Button } from 'react-native-paper';
import { useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import Prferances from '../constants/Preferances';
import UserImage from '../components/UserImage';
import Global from '../constants/global';
import { RootStackScreenProps } from '../types';
import Modal from 'react-native-modal';
import Toast from 'react-native-root-toast';
import Hemberger from '../components/Hemberger';


// function upload() {
//   const db = getFirestore();
//   const batch = writeBatch(db);
//   try {
//     allData.map(u => {
//       if (u.id) {
//         const udp = u.userDp
//         if (udp) {

//           try {
//             const jdp = JSON.parse(udp)
//             u.userDp = jdp
//           } catch (e) { }
//         }
//         const ref = doc(db, "Users", u.id);
//         batch.set(ref, u)
//       }
//     })
//     batch.commit()
//   } catch (e) {
//     console.log("errorfdb", e)
//   }
//   alert("done")
// }
let baselist: [] = []
const mob = Global.isMobile()

export default function UserList({ route, navigation }: RootStackScreenProps<'Users'>) {
  const ulist = useSelector(state => state.list.Users)
  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);
  const [isOpen, setOpen] = useState(undefined);
  const [deleConfirmation, setDeleConfirmation] = useState(undefined);
  function onClose() { setOpen(undefined) }

  useEffect(() => {
    const e: [] = []
    const bulist = ulist.filter(e => !e?.dele)
    bulist.map(u => {
      const ele = { ...u }
      let s: string = ele.name + ele.mobile + ele.type
      if (ele?.isOwnerOf) {
        ele.isOwnerOf.map(o => s = s + o)
      }
      if (ele?.isTenantOf) {
        ele.isTenantOf.map(o => s = s + o)
      }
      ele.search = s ? s.toLowerCase() : ele.search
      e.push(ele)
    })
    baselist = [...e]
    setList(e)
  }, [ulist])

  useEffect(() => {
    const lsearch = search.toLowerCase()
    console.log("setting on")
    if (lsearch.length >= 3) {
      const flist = baselist.filter(e => e.search.includes(lsearch))
      setList(flist)
      console.log("setting search")
    } else {
      setList(baselist)
    }
  }, [search])

  const pref = useContext(Prferances)

  const isCommittee = pref?.current?.type == "Committee"
  const isSuper = pref?.current?.isSuperAdmin

  function DeleteEntry(type: number) {
    const db = getFirestore()
    const ref = doc(db, "Users", isOpen.id)
    setDoc(ref, { dele: true }, { merge: true })
    setDeleConfirmation(undefined)
    Toast.show("User Soft Deleted")
  }

  const copyToClipboard = async () => {
    await Clipboard.setStringAsync("https://ashwameghta.web.app/stat/" + isOpen.id);
    //        await Clipboard.setStringAsync("Hello, \nStatement Link\nhttps://ashwameghta.web.app/stat/" + isOpen.id + "\nThank You Rahul Joshi");
    Toast.show("Link Copied")
  };

  const onShare = async (url: string, title: string) => {
    try {
      const result = await Share.share({
        message:
          (title + '\n' + url)
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          alert("1")
          // shared with activity type of result.activityType
        } else {
          alert("2")
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: "row" }}>
        <Hemberger navigation={navigation} />
        <ScrollView style={{ flex: 1, alignSelf: "stretch" }}>
          <View>
            <View style={{ flexDirection: 'row', alignItems: "center", alignSelf: "stretch" }}>
              <TextInput
                autoFocus
                placeholder="Search... (min 3 chars)"
                style={{ alignSelf: "stretch", flex: 1 }}
                dense
                onChangeText={(text) => {
                  setSearch(text)
                }}
              />
              <View style={{ alignItems: "center", justifyContent: "center" }}>
                <IconButton icon='eye' />
                <Text>{ulist?.length}</Text>
              </View>
            </View>
            <FlatList
              data={list}
              renderItem={(u) => <UserRow
                navigation={navigation}
                info={u.item} index={u.index}
                committee={isCommittee}
                open={() => {
                  console.log("Reccd2", u.item)
                  setOpen(u.item)
                }}
                super={isSuper}
                key={u.index + ""} />
              }
            />
          </View>
        </ScrollView>


      </View>
      {
        isSuper && <FAB
          icon="plus"
          style={{
            position: 'absolute',
            margin: 16,
            right: 0,
            bottom: 0,
          }}
          onPress={() =>
            navigation.navigate('UserForm', { data: {} })}
        />
      }
      <Modal
        style={{ margin: 0 }}
        isVisible={isOpen !== undefined}
        onDismiss={() => setOpen(undefined)}>
        <View style={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width, justifyContent: "center", backgroundColor: Global.THEME.surfaceVariant }}>
          <Text style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{isOpen?.name}...</Text>

          <TouchableOpacity onPress={() => {
            onClose();
            navigation.navigate(
              'Statement', { user: isOpen?.id, editable: isSuper, pno: undefined, party: undefined, type: isSuper ? 2 : isCommittee ? 1 : 0 })
          }}>
            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
              <IconButton icon="format-list-bulleted" />
              <Text style={{ paddingLeft: 10 }}>{"View User Statement "}</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            onShare("Statement Sharing", "https://ashwameghta.web.app/stat/" + isOpen?.id)
          }}>
            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
              <IconButton icon="share" />
              <Text style={{ paddingLeft: 10 }}>{"Share User Statement "}</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => copyToClipboard()}>
            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
              <IconButton icon="content-copy" />
              <Text style={{ paddingLeft: 10 }}>Copy link :   {"https://ashwameghta.web.app/stat/" + isOpen?.id}</Text>
            </View>
          </TouchableOpacity>

          {(isSuper || isCommittee) && <TouchableOpacity onPress={() => {
            onClose();
            console.log("Reccd 1", isOpen.info)
            navigation.navigate(
              'UserForm', { data: isOpen.info }
            )
          }}  >
            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
              <IconButton icon="pencil-outline" />
              <Text style={{ paddingLeft: 10 }} >Edit</Text>
            </View>
          </TouchableOpacity>}

          {isSuper && <TouchableOpacity onPress={() => { onClose(); setDeleConfirmation(isOpen) }}  >
            <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
              <IconButton icon="account-circle" />
              <Text style={{ paddingLeft: 10 }} >Delete</Text>
            </View>
          </TouchableOpacity>}

          <IconButton icon="close" style={{ position: "absolute", right: 20, top: 0 }} size={24} onPress={() => onClose()} />
        </View>
      </Modal>
      <Modal style={{ margin: 0 }}
        onDismiss={() => setDeleConfirmation(undefined)} isVisible={deleConfirmation !== undefined}>
        <View style={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width, justifyContent: "center", backgroundColor: Global.THEME.surfaceVariant }}>
          <Text variant='headlineSmall'>Delete User ({isOpen?.name})? </Text>
          <Text style={{ padding: 10 }}>Note : Deleting User will not delete it's property and entries related to property</Text>
          <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent" }}>
            <Button style={{ flex: 1 }} onPress={() => setDeleConfirmation(undefined)}>Cancel</Button>
            <Button style={{ flex: 1 }} onPress={() => DeleteEntry(0)}>Delete User</Button>
          </View>
        </View>

      </Modal>

    </View >
  );
}

