import { Button, Divider, IconButton, Text } from 'react-native-paper';
import { View } from './Themed';
import { memo } from 'react';

const BackButton = (op) => {

    return <View>
        <IconButton icon="arrow-left"  onPress={()=>op.navigation.goBack()}/>
    </View >

}
export default memo(BackButton)