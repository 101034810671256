import { collection, doc, setDoc, Timestamp, writeBatch } from "firebase/firestore";
import DTheme from "./theme"
import * as FileSystem from 'expo-file-system';
import moment from "moment";
import dayjs from "dayjs";
//import L from "./L"
export default class Global {
  public static ALL = 0
  public static years = ["2019-20", "2020-21", "2021-22", "2022-23", "2023-24", "2024-25"]
  public static allyears = ["2019-20", "2020-21", "2021-22", "2022-23", "2023-24", "2024-25", "2025-26"]

  public static resetOpeningBalances(prop, accounts, saveFun) {
    const property = JSON.parse(JSON.stringify(prop))
    property.balance = property.balance.sort((a, b) => parseInt(a.year.replace("-", "")) - parseInt(b.year.replace("-", "")))
    const ys = Global.years[this.years.length]
    const has = property.balance.find(f => f.year == ys)
    if (!has) {
      property.balance.push({ year: ys, amount: 0 })
    }
    property.balance.map((y, i) => {
      const lst = accounts.filter(a => a.property == prop.pno && a.year == y.year)
      let dr = 0
      let cr = 0
      lst.map(e => {
        if (e.side == 0) {
          dr = dr + e.amount
        } else {
          cr = cr + e.amount
        }
      })
      if (property.balance[i + 1]) {
        property.balance[i + 1].amount = y.amount + cr - dr
      }
    })
    saveFun(property.balance)

  }
  public static getMonthName(nu: number) {
    if (nu == 0) {
      return "Jan"
    } else if (nu == 1) {
      return "Feb"
    } else if (nu == 2) {
      return "Mar"
    } else if (nu == 3) {
      return "Apr"
    } else if (nu == 4) {
      return "May"
    } else if (nu == 5) {
      return "Jun"
    } else if (nu == 6) {
      return "Jul"
    } else if (nu == 7) {
      return "Aug"
    } else if (nu == 8) {
      return "Sep"
    } else if (nu == 9) {
      return "Oct"
    } else if (nu == 10) {
      return "Nov"
    } else if (nu == 11) {
      return "Dec"
    }
  }

  public static select = ["All Properties",
    "Office ",
    "Floor",
    "Size exacty",
    "Size Above",
    "Size Below",
    "Deposit Paid",
    "Deposit Pending above ",
    "Transfer Fee Pending",
    "Transfer Fee Pending above",
    "Given on Rent",
    "Using Lift",
    "Using Parking",
    "Using Water Conn",
    "Is Office",
    "Is Hotel",
    "Is Classes",
    "Is Hospital",
    "Is Hostel",
    "Deposit Pending below ",
  ]

  public static type = ["Office", "Hotel", "Classes", "Hospital", "Hostel"]

  public static isRuleApplicable(property, charge) {
    switch (charge.selection) {
      case 0:
        return true;
      // all
      case 1:
        return (property.pno == charge.input);
      // off no
      case 2:
        return (property.floor == parseInt(charge.input) &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));
      /// floor
      case 3:
        return (property.size == parseInt(charge.input) &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));
      // size sq.mt
      case 4:
        return (property.size > parseFloat(charge.input) &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));
      //size sq.mt above
      case 5:
        return (property.size < parseFloat(charge.input) &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));
      // size sq.mt below
      case 6:
        return (property.depositPaid > parseFloat(charge.input) &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));
      //return (property.getDepositPending()>0);
      // deposit pendint
      case 7:
        const value11 = Math.abs(parseFloat(charge.input));
        const applicable1 = (property.depositPending > value11
          && (charge?.applyTo ? (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true))
        if (applicable1) {
          if (property?.depositPaidFrom) {
            const chargeYr = parseInt(charge.year.replace("-", ""))
            const dpyr = parseInt(property?.depositPaidFrom.replace("-", ""))
            return chargeYr < dpyr
          }
        }
        return applicable1

      // return (property.depositPending >= value &&
      //   (charge?.applyTo ?
      //     (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));

      case 8:
        return (property.transferFeePending > 0 &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));
      // transferfee pending
      case 9:
        return (property.transferFeePending > parseFloat(charge.input) &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));
      // transfer fee pending above
      case 10:
        return (property.rentalMonths.filter(e => e.status == 1).length > 0 &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));
      //                            selectedList = pmodel.getPropertyByNoTenant();
      // given on rent
      case 11:
        return (property.hasLift &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));
      // using lift
      case 12:
        return (property.hasParking &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));
      // using parking
      case 13:
        return (property.hasWaterConnection &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true));
      // using water
      case 14:
        return (property.type == 0);
      // is office
      case 15:
        return (property.type == 1);
        return false
      // no more in use => in lue of case 18
      // is hostel
      case 16:
        return (property.type == 2);
      // is classes
      case 17:
        return (property.type == 3);
      // is hospital
      case 18:
        // if (charge.id =="Charge165371443668194265362232" && property.type == 4){
        //   console.log("Applying prop",property.pno)

        // }
        return (property.type == 4);
      case 19:
        //        Deposit Pending below
        const value1 = Math.abs(parseFloat(charge.input));
        const applicable = (property.depositPending < value1 &&
          (charge?.applyTo ?
            (charge.applyTo.findIndex(pi => pi == property.type) > -1) : true))
        if (applicable) {
          if (property?.depositPaidFrom) {
            const chargeYr = parseInt(charge.year.replace("-", ""))
            const dpyr = parseInt(property?.depositPaidFrom.replace("-", ""))
            return chargeYr >= dpyr
          }
        }
        return applicable
    }
    return false;
  }

  public static getAmount(charge, property) {
    return (charge.apply_rate == 0 ? charge.rate * property.size : charge.rate);
  }
  public static getEntry(pno: string, charge: any, amount: number) {

    return {
      property: pno,                                   // Property No
      amount: amount,
      side: 0,                   // 0 is expences-Dr // 1 is Revenue cr

      edited: false,
      charge: true,
      isClosedRule: charge.isClosed, // setting whether this is close rule or not

      // set the status in the property to account entries.

      headName: charge.remarks,
      remarks: "Charge : " + charge.description,
      /// added on 6 july -20 hoping earlier this line was missing. added as to trace total of summary
      headId: charge.id,
      year: charge.year,
      stamp: dayjs().unix()
    }
  }
  public static contains(arr: [], val, year: string) {
    return arr.findIndex(rm => rm.month == val && rm.status == 1 && rm.year == year) > -1
  }

  public static saveEntry(account, db, batch, stage) {
    try {
      const ref = doc(collection(db, "Entries"));
      account.id = ref.id
      batch.set(ref, account, { merge: true })
      stage.push({ saved: true })
    } catch (e) {
      stage.push({ error: e })
      console.log("chareg entry err", e)
    }
  }

  public static applyRule(p, charge, db, batch, action) {
    this.applyRuleDB(p, charge, db, batch, action, true)
  }
  public static applyRuleView(p, charge, db, batch, action) {
    return this.applyRuleDB(p, charge, db, batch, action, false)
  }

  public static applyRuleDB(p, charge, db, batch, action, save) {
    const year = parseInt(charge.year.substring(0, 4))
    const osdate = dayjs("01-04-" + year, "DD-MM-YYYY")
    const stages = []

    if (Global.isRuleApplicable(p, charge)) {
      stages.push({ rule: true })
      const cmonths: [] = p?.clMonths ? p.clMonths : []
      const rmonths: [] = p?.rentalMonths ? p.rentalMonths : []

      if (charge.frequency == 0) {
        const start = charge?.startingFrom ? charge?.startingFrom : 0
        for (let i = start; i <= 11; i++) {
          const containsMonth = cmonths.filter(ci => charge.year == ci.year && ci.status == 1 && ci == i);  // yes means closed
          const rentMonths = rmonths.filter(ci => charge.year == ci.year && ci.status == 1 && ci == i);  // yes means closed
          // isClosed 1 = open , 2 = close
          if ((charge.isClosed == this.ALL) || (charge.isClosed == this.OPEN && !containsMonth) || (charge.isClosed == this.CLOSED && containsMonth)) { /// close charge;
            if (charge.selection != 10 || (rentMonths.findIndex(rm => rm == i) > -1)) {
              stages.push({ closeRent: true })
              const camount = (Global.getAmount(charge, p));
              const account = Global.getEntry(p.pno, charge, camount);
              account.date = osdate.unix()
              if (save) {
                Global.saveEntry(account, db, batch, stages)
              }
            }
          }
        }
      } else if (charge.frequency == 1) {
        for (let i = 0; i < 4; i++) {
          let toAdd = false;
          let osdate
          let rentMonths
          switch (i) {
            case 0:
              // 0,1,2
              {
                const containsMonth = Global.contains(cmonths, 3, charge.year) || Global.contains(cmonths, 4, charge.year) || Global.contains(cmonths, 5, charge.year);
                rentMonths = Global.contains(rmonths, 3, charge.year) || Global.contains(rmonths, 4, charge.year) || Global.contains(rmonths, 5, charge.year);
                if ((charge.isClosed == 0) || (charge.isClosed == 1 && !containsMonth) || (charge.isClosed == 2 && containsMonth)) { /// close charge;
                  stages.push({ closeRent: true })
                  toAdd = charge.startingFrom >= 0;
                  osdate = dayjs("01-04-" + year, "DD-MM-YYYY")
                }
              }
              break;
            case 1:
              // 3,4,5
              {
                const containsMonth = Global.contains(cmonths, 6, charge.year) || Global.contains(cmonths, 7, charge.year) || Global.contains(cmonths, 8, charge.year);
                rentMonths = Global.contains(rmonths, 6, charge.year) || Global.contains(rmonths, 7, charge.year) || Global.contains(rmonths, 8, charge.year);
                if ((charge.isClosed == 0) || (charge.isClosed == 1 && !containsMonth) || (charge.isClosed == 2 && containsMonth)) { /// close charge;
                  stages.push({ closeRent: true })
                  toAdd = charge.startingFrom >= 3;
                  osdate = dayjs("01-07-" + year, "DD-MM-YYYY")
                }
              }
              break;
            case 2:
              // 6,7,8
              {
                const containsMonth = Global.contains(cmonths, 9, charge.year) || Global.contains(cmonths, 10, charge.year) || Global.contains(cmonths, 11, charge.year);
                rentMonths = Global.contains(rmonths, 9, charge.year) || Global.contains(rmonths, 10, charge.year) || Global.contains(rmonths, 11, charge.year);
                if ((charge.isClosed == 0) || (charge.isClosed == 1 && !containsMonth) || (charge.isClosed == 2 && containsMonth)) { /// close charge;
                  stages.push({ closeRent: true })
                  toAdd = charge.startingFrom >= 6;
                  osdate = dayjs("01-10-" + year, "DD-MM-YYYY")
                }
              }
              break;
            case 3:
              // 9,10,11
              {
                const containsMonth = Global.contains(cmonths, 0, charge.year) || Global.contains(cmonths, 1, charge.year) || Global.contains(cmonths, 2, charge.year);
                rentMonths = Global.contains(rmonths, 0, charge.year) || Global.contains(rmonths, 1, charge.year) || Global.contains(rmonths, 2, charge.year);
                if ((charge.isClosed == 0) || (charge.isClosed == 1 && !containsMonth) || (charge.isClosed == 2 && containsMonth)) { /// close charge;
                  stages.push({ closeRent: true })
                  toAdd = charge.startingFrom >= 9;
                  osdate = dayjs("01-01-" + (year + 1), "DD-MM-YYYY")
                }
              }
              break;
          }
          if (toAdd) {
            if (charge.selection !== 10 || rentMonths) {
              const camount = (Global.getAmount(charge, p));

              const account = Global.getEntry(p.pno, charge, camount)
              account.date = osdate?.unix()
              stages.push({ closeRent: true })
              if (save) {
                Global.saveEntry(account, db, batch, stages)
              }
            }
          }
        }

      } if (charge.frequency == 2) {
        if (charge.startingFrom == 0) {
          const containsMonth = Global.contains(cmonths, 3, charge.year) || Global.contains(cmonths, 4, charge.year) || Global.contains(cmonths, 5, charge.year) || Global.contains(cmonths, 6, charge.year) || Global.contains(cmonths, 7, charge.year) || Global.contains(cmonths, 8, charge.year);
          const rentMonth = Global.contains(rmonths, 3, charge.year) || Global.contains(rmonths, 4, charge.year) || Global.contains(rmonths, 5, charge.year) || Global.contains(rmonths, 6, charge.year) || Global.contains(rmonths, 7, charge.year) || Global.contains(rmonths, 8, charge.year);
          if ((charge.isClosed == 0) || (charge.isClosed == 1 && !containsMonth) || (charge.isClosed == 2 && containsMonth)) { /// close charge;
            if (charge.selection != 10 || rentMonth) {
              stages.push({ closeRent: true })
              const camount = (Global.getAmount(charge, p));

              const account = Global.getEntry(p.pno, charge, camount);
              account.date = dayjs("01-03-" + (year), "DD-MM-YYYY")?.unix()
              if (save) {

                Global.saveEntry(account, db, batch, stages)
              }
            }
          }
        }
        if (charge.startingFrom > 0 && charge.startingFrom <= 5) {
          const containsMonth = Global.contains(cmonths, 9, charge.year) || Global.contains(cmonths, 10, charge.year) || Global.contains(cmonths, 11, charge.year) || Global.contains(cmonths, 0, charge.year) || Global.contains(cmonths, 1, charge.year) || Global.contains(cmonths, 2, charge.year);
          const rentMonths = Global.contains(rmonths, 9, charge.year) || Global.contains(rmonths, 10, charge.year) || Global.contains(rmonths, 11, charge.year) || Global.contains(rmonths, 0, charge.year) || Global.contains(rmonths, 1, charge.year) || Global.contains(rmonths, 2, charge.year)
          if ((charge.isClosed == 0) || (charge.isClosed == 1 && !containsMonth) || (charge.isClosed == 2 && containsMonth)) { /// close charge;
            if (charge.selection != 10 || rentMonths) {
              stages.push({ closeRent: true })
              const camount = (Global.getAmount(charge, property));
              const account1 = Global.getEntry(p.pno, charge, camount);
              account1.date = dayjs("01-09-" + (year), "DD-MM-YYYY")?.unix()
              if (save) {

                Global.saveEntry(account1, db, batch, stages)
              }
            }
          }
        }
      } else {

        // let month = charge.startingFrom + 3;
        // let yr2 = year;
        // if (month > 11) {
        //     month = month - 11;
        //     yr2 = yr2 - 1;
        // }
        // console.log(p.pno + " applied " + yr2, month)

        const containsMonth = Global.contains(cmonths, 3, charge.year) || Global.contains(cmonths, 4, charge.year) || Global.contains(cmonths, 5, charge.year) || Global.contains(cmonths, 6, charge.year) || Global.contains(cmonths, 7, charge.year) || Global.contains(cmonths, 8, charge.year) || Global.contains(cmonths, 9, charge.year) || Global.contains(cmonths, 10, charge.year) || Global.contains(cmonths, 11, charge.year) || Global.contains(cmonths, 0, charge.year) || Global.contains(cmonths, 1, charge.year) || Global.contains(cmonths, 2, charge.year);
        // if (p.pno == "102") {
        //   console.log("status", p.pno, charge.isClosed, containsMonth, cmonths)
        //   // cmonths.map(rm => {
        //   //   if ((rm.year == "2021-22")) {
        //   //     console.log("status", rm.status, rm.month)
        //   //   }
        //   // })
        // }

        if ((charge.isClosed == 0) ||
          (charge.isClosed == 1 && !containsMonth) ||
          (charge.isClosed == 2 && containsMonth)) { /// close charge;
          if (charge.selection != 10 || rmonths.length == 0) {
            // if (p.pno == "102") {
            //   console.log("status1", charge.isClosed, year, containsMonth)

            // }
            stages.push({ closeRent: true })
            const camount = (Global.getAmount(charge, p));

            const account = Global.getEntry(p.pno, charge, camount);
            account.date = dayjs("01-04-" + (year), "DD-MM-YYYY")?.unix()
            if (save) {

              Global.saveEntry(account, db, batch, stages)
            }

          }
        } else {
        }

      }

    } else {
      stages.push({ rule: false })
    }

    action.push({ action: "Apply", property: p.pno, headName: charge.remarks, stages: stages })
  }

  public static reapplyRule(p, charge, accountsList, db, batch, action) {
    const l = accountsList.filter(e => e.headId == charge.id && e.property == p.pno && e.year == charge.year)
    l.map(async (e) => {
      const ref = doc(db, "Entries", e.id)
      batch.delete(ref)
      action.push({ action: "Deleted", property: e.property, amount: e.amount, headName: e.headName })
    })

    Global.applyRule(p, charge, db, batch, action)
  }
  public static ALL_PROPERTIES = 0;
  public static OPEN = 1;
  public static CLOSED = 2;
  public static USING_OWN = 0;
  public static GIVEN_ON_RENT = 1;

  public static VERSION: number = 2.75;
  public static THEME = DTheme.blue_light
  public static ROW_DP: number = 0;
  public static FORM_DP: number = 1;
  public static FULL_DP: number = 2;
  public static SMALL_DP: number = 3;
  public static BIG_ROW_DP: number = 4;
  public static BOY: number = 1;
  public static GIRL: number = 0;
  public static USER_NORMAL: number = 0;
  public static USER_COMITTEE: number = 1;
  public static USER_PRAMUKH: number = 2;
  public static USER_MASTER: number = 10;
  public static MOBILE_WIDTH: number = 768;

  public static BIRTHDAY: string = "Birthday";
  public static ACHIEVEMENT: string = "Achievements";
  public static WISHES: string = "Wishes";
  public static DEATH: string = "Death";
  public static COMMINFO: string = "CommInfo";
  public static OURPEOPLE: string = "OurPeople";
  public static VOTTING: string = "Votting";
  public static GAURAV: string = "Gaurav";
  public static GROUPS: string = "Groups";
  public static ACCOUNTHEAD: string = "AccountHead";
  public static ACCOUNTENTRY: string = "AccountEntry";
  public static ACTIVITY: string = "Activity";

  public static HOMETOWN: string = "HomeTown";
  public static GOTRA: string = "Gotra";
  public static KULDEVI: string = "Kuldevi";
  public static KULDEVTA: string = "Kuldevta";
  public static EDUCATION: string = "Education";
  public static WORKAREA: string = "Workarea";
  public static CITY: string = "City";
  public static INDUSTRY: string = "Industry";
  public static SAMAJCITY: string = "SamajCity";
  public static FATHER: string = "FATHER";
  public static MOTHER: string = "MOTHER";
  public static ALL_LIST: string = "ALL";
  public static GENDER_BASED: string = "GENDER";

  public static WORK_EDUCATION: number = 0;
  public static WORK_GOVT_JOB: number = 1;
  public static WORK_PVT_JOB: number = 2;
  public static WORK_BUSINESS: number = 3;
  public static WORK_RETD: number = 4;
  public static WORK_HOUSE_WORK: number = 5;
  public static WORK_OTHER: number = 6;

  public static NOUSER_DP: string = "../assets/images/nouser.png";

  public static LOGIN_NOT_INITIATED: number = 0;
  public static LOGIN_PROCESSING: number = 0;
  public static LOGIN_DONE: number = 0;

  public static STATUS_NOT_INITIATED: number = 0;
  public static STATUS_PENDING: number = -1;
  public static STATUS_OK: number = 1;
  public static STATUS_OK_ERROR: number = -3;
  public static STATUS_OK_EXISTS: number = -4;
  public static STATUS_REJECTED: number = -2;

  public static LINK_UPLOAD: string = "https://gtech.co.in/bhudevData/upload.php";
  public static SNAP_LOADED = false
  public static WHAT_NEW_TILL: number = 1.150;
  public static CURRENT_USER = undefined
  public static CURRENT_CITY = undefined
  public static MASTER_URL = "https://img.freepik.com/premium-photo/buddha-statue-lotus-nature-background_313215-8699.jpg"
  public static MASTER_MESSAGE = "Be Happy, Spread Happiness"
  public static MASTER_MESSAGE1 = "- Steve Martin"
  public static FURL = "https://firebasestorage.googleapis.com/v0/b/samaj-3a020.appspot.com/o/XXX?alt=media&token=a2291856-912f-46e3-ac89-47098354ae42"
  public static getUrl(dp: string) {
    //    return Global.FURL.replace("XXX", dp)
    return "https://gtech.co.in/ashwamegh/images/" + dp
  }
  public static getFullName(data) {
    return data.dead == true || data.dod > 0 ? L.Ldata.SVARG : "" +
      data.name +
      (data.gender == Global.GIRL ? (data.spouseName ? " " + data.spouseName : (data.fatherName ? " " + data.fatherName : "")) : (data.fatherName ? " " + data.fatherName : "")) + " " + data.surname

  }

  public static getDate(d: number) {
    return Global.getDateString(d, "DD-MM-YYYY")
  }
  public static getDateString(d: number, s: string) {
    try {
      const d1 = d ? ((d + "").length > 10 ? parseInt(((d + "").substring(0, 10))) : d) : 0
      if (d1) {
        return dayjs.unix(d1).format(s)
      }

    } catch (e) { }
    return ""
  }

  public static sendNotification(title: string, body: string, data: any, users: []) {
    const lst = [...new Set(users.map(item => item.token))]
    const lst1 = lst.filter(r => (r))

    const message = {
      to: users,
      sound: 'default',
      title: title,
      body: body,
      data: data,
    };
    console.log("MYFCM msf", message)
    try {
      fetch('https://exp.host/--/api/v2/push/send', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Accept-encoding': 'gzip, deflate',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      }).then(e => console.log("fcm done", e)).catch(e => console.log("fcm err", e))
    } catch (e) { console.log("fcm escape", e) }

  }
  public static uploadName(res, newName: string) {
    let ext = "png"
    let type = "image"
    const stamp = Timestamp.fromDate(new Date()).toMillis()

    try {

      //                    ext = res.uri.substring(res.uri.indexOf("/") + 1, res.uri.indexOf(";"))
      ext = res.uri.substring(res.uri.lastIndexOf(".") + 1)
      if (!ext) {
        ext = "jpeg"
      }
      ext = ext.toLowerCase().trim()
      if (ext == "jpg") {
        ext = "jpeg"
      }
      if (ext == "jpeg" || ext == "png" || ext == "webp" || ext == "jiff" || ext == "bmp") {
        type == "image"
      } else {
        if (ext == "mov" || ext == "mp4" || ext == "mpeg" || ext == "wmv" || ext == "avi" || ext == "mkv") {
          type = "video"
        } else {
          if (ext == "gif") {
            type = "image"
          } else {
            type = "doc"
          }
        }
      }

      let name = newName ? newName + (newName.indexOf(".") == -1 ? "." + ext : "") :
        Global?.CURRENT_USER?.id ? Global?.CURRENT_USER.id + stamp + "." + ext : "unPic" + stamp + "." + ext
      return { name: name, ext: ext }
    } catch (e) { }
    let name =
      Global?.CURRENT_USER?.id ? Global?.CURRENT_USER.id + stamp + "." + ext : "unPic" + stamp + "." + ext
    return { name: name, ext: ext }
  }

  public static async upload(res, newName: string, callback: (n: string, r: boolean) => void, oldFileToDelete: string) {
    const onUri = res.localUri || res.uri

    const Oname = this.uploadName(res, newName)
    const name = Oname.name
    const ext = Oname.ext

    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        const trimmed = reader.result.trim()
        const t1 = trimmed.substring(trimmed.indexOf(",") + 1)
        FileSystem.uploadAsync(
          "https://gtech.co.in/bhudev/pic/upload.php",
          onUri,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
            uploadType: FileSystem.FileSystemUploadType.MULTIPART,
            fieldName: "name",
            parameters: { name: name, file: t1 }
          }).then(e => {
            //  console.log("Ori len "+reader.result.length+", trimmed = "+trimmed.length+" = ret #"+e.body+"#")
            callback(name, true)
            //            console.log("res", e.body)
          }
          )
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', onUri);
    xhr.responseType = 'blob';
    xhr.send();




  }

  public static getRuleName(selection: number) {
    return Global.select[selection]

  }
  public static getColor(ix: number) {
    let i = ix % 10
    switch (i) {
      case 0: return "teal"
      case 1: return "#6495ED"
      case 2: return "#D2691E"
      case 3: return "#008B8B"
      case 4: return "#9932CC"
      case 5: return "#FF7F50"
      case 6: return "#2F4F4F"
      case 7: return "#228B22"
      case 8: return "#DAA520"
      case 9: return "#CD5C5C"
    }
  }
  public static ROW_COLOR = {
    c0: { color: "teal", fontWeight: "bold" },
    c1: { color: "#6495ED", fontWeight: "bold" },
    c2: { color: "#D2691E", fontWeight: "bold" },
    c3: { color: "#008B8B", fontWeight: "bold" },
    c4: { color: "#9932CC", fontWeight: "bold" },
    c5: { color: "#FF7F50", fontWeight: "bold" },
    c6: { color: "#2F4F4F", fontWeight: "bold" },
    c7: { color: "#228B22", fontWeight: "bold" },
    c8: { color: "#DAA520", fontWeight: "bold" },
    c9: { color: "#CD5C5C", fontWeight: "bold" }
  }
  public static RS = "₹"


  public static NOUSER_DP: string = "../assets/images/nouser.png";
  static noteError(err, values) {
    const db = getFirestore();
    addDoc(collection(db, "Errors"), {
      UserId: Global.USER.id,
      UserName: Global.USER.name,
      OrgId: Global.USER.org_id,
      on: new Date().toString(),
      error: err.toString(),
      data: JSON.stringify(values)
    });

  }
  static getWord(nu) {
    let num = Math.abs(nu)
    if (Number.isInteger(num)) {
      return Global.getSingleWord(num) + " only"
    } else {
      let deci = num - Math.floor(num)
      let base = Math.floor(num)
      let deci1s = (deci + "").replace(".", "")
      let deci1 = parseInt(deci1s)
      let sdeci = this.getSingleWord(deci1)
      let sbase = this.getSingleWord(base)
      return sbase + " rupees " + sdeci + " paise only"
    }
  }

  static getSingleWord(num) {
    var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
    var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
    return str;
  }
  static isMobile() { return window.innerWidth < 768 }

  static sortedUsers(arr) {
    try {
      let nnewP = arr.sort((a, b) => a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 0)
      return nnewP
    } catch (e) {
      ////console.log("ARRAY", e)
    }
    return arr
  }
  static getFirebase() {

  }
  static getCity() {
    const s = {
      id: 0,
      name: "",
      dp: "",
      tag: "કોઈ ને હરાવવું સરળ છે, પરંતુ જીતવું ઘણુજ અઘરું છે.",
      tagby: "Dr. APJ Kalam",
      samaj: -1,
    }
    return { ...s }
  }

  static async DistributionSMS(cust: []) {
    let templateid = "1307164509550611005"; ///
    let senderid = "HRKJSH";
    let api = "djYRF0eK4UOdIp8PLpyYMQ";
    cust.map(c => {
      let message = "Hi, Auto-distribution finished. click to view " + location.origin + "/InvoicePrint?id=" + c.id + "  From VTCC, (HRKJSH)"
      var data = new URLSearchParams();
      data.append("APIKey", api)
      data.append("senderid", senderid)
      data.append("channel", "2")
      data.append("DCS", "0")
      data.append("flashsms", "0")
      data.append("number", c.mobile)
      data.append("text", message)
      data.append("route", "30")
      data.append("EntityId", "1301163117511620871")
      data.append("dlttemplateid", templateid)
      var url = new URL('https://www.smsgatewayhub.com/api/mt/SendSMS')
      /*
                  url.search = data.toString()
                  ////console.log("smsurl", url.toString())
                  fetch(url.toString()).then(response => {
                      ////console.log(response)
                  }).catch(error => {
                      //console.error("smserror", error);
                  })
      */
    })


  }

}


