import { useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { Button, Card, HelperText, TextInput } from "react-native-paper";
import { Text } from "react-native";
import { useSelector } from "react-redux";
import { View } from "./Themed";
import dayjs from "dayjs";
import { doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import Toast from "react-native-root-toast";

export default function Approvals() {
    const acc = useSelector(state => state.list.InEx)
    const [values, setValues] = useState([])

    useEffect(() => {
        const thisMonth = dayjs().month()
        let allData: [] = []
        acc.map(e => {
            if (e?.frequency == 0) {
                e.approvals?.map(m => {
                    if (m.month == thisMonth && m.status !== 1) {
                        allData.push(
                            {
                                id: e.id,
                                title: e.title,
                                head: e.head,
                                desc: e.desc,
                                init: true,
                                month: m.month,
                                actual: 0,
                                amount: e.amount
                            }
                        )
                    }
                })
            }
        })
        setValues(allData)
    }, [acc])

    function update(oldi, amt) {
        const v: [] = []
        values.map(i => {
            if (i.id == oldi.id) {
                const ii = { ...i }
                ii.actual = amt
                ii.init = false
                v.push(ii)
            } else {
                v.push(i)
            }
        })
        setValues(v)
    }

    function approve(entity: any, what: boolean) {
        const db = getFirestore()
        const ref = doc(db, "InEx", entity.id)
        const mn = acc.find(mn => mn.id == entity.id)
        const working = JSON.parse(JSON.stringify(mn))
        const ls: [] = []
        working.approvals.map(mn => {
            let le = mn
            if (mn.month == entity.month) {
                const ev = { ...le }
                if (!what) {
                    ev.amount = 0
                    ev.status = 0
                } else {
                    ev.amount = entity.actual
                    ev.status = 1
                }
                le = ev
            }
            ls.push(le)
        })

        setDoc(ref, { approvals: ls }, { merge: true })
        Toast.show("Saved")
    }

    return (
        values.length > 0 ?
            <View style={{ backgroundColor: "transparent", marginTop: 20 }}>
                <Text style={{ fontWeight: "bold" }}>Pending Approvals</Text>
                <ScrollView horizontal>

                    <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
                        {
                            values.map(i => {
                                return <Card style={{ width: 200, margin: 5 }}>
                                    <Text style={{ fontWeight: "bold" }}>{i?.head}</Text>
                                    <Text>{i?.desc}</Text>
                                    <TextInput
                                        value={i.init ? i.amount : i.actual}
                                        label="Amount"
                                        onChangeText={(text) => {
                                            const v = text.replace(/[^0-9.-]/g, '')
                                            let vn = 0
                                            if (v) { vn = parseFloat(v) }
                                            update(i, vn)
                                        }}
                                    />
                                    <View style={{ flexDirection: "row" }}>
                                        <Button style={{ flex: 1 }} onPress={() => approve(i, false)} >Reject</Button>
                                        <Button style={{ flex: 1 }} onPress={() => approve(i, true)} mode="contained-tonal">Approve</Button>
                                    </View>
                                </Card>
                            })
                        }
                    </View>
                </ScrollView>
            </View>
            : <></>
    );
};