import { collection, doc, getFirestore, setDoc, writeBatch } from 'firebase/firestore';
import { FlatList, ScrollView, StyleSheet } from 'react-native';
import { Button, Chip, FAB, IconButton, TextInput } from 'react-native-paper';
import { Text, View } from '../components/Themed';
import React, { useEffect, useState } from 'react';
import Toast from 'react-native-root-toast';
import DInput from '../components/DInput';
import { useSelector } from 'react-redux';
import Global from '../constants/global';
import { findDOMNode } from 'react-dom';

const m =
    [{ year: "2019-20", month: 0, status: 0 }, { year: "2019-20", month: 1, status: 0 }, { year: "2019-20", month: 2, status: 0 }, { year: "2019-20", month: 3, status: 0 }, { year: "2019-20", month: 4, status: 0 }, { year: "2019-20", month: 5, status: 0 }, { year: "2019-20", month: 6, status: 0 }, { year: "2019-20", month: 7, status: 0 }, { year: "2019-20", month: 8, status: 0 }, { year: "2019-20", month: 9, status: 0 }, { year: "2019-20", month: 10, status: 0 }, { year: "2019-20", month: 11, status: 0 },
    { year: "2020-21", month: 0, status: 0 }, { year: "2020-21", month: 1, status: 0 }, { year: "2020-21", month: 2, status: 0 }, { year: "2020-21", month: 3, status: 0 }, { year: "2020-21", month: 4, status: 0 }, { year: "2020-21", month: 5, status: 0 }, { year: "2020-21", month: 6, status: 0 }, { year: "2020-21", month: 7, status: 0 }, { year: "2020-21", month: 8, status: 0 }, { year: "2020-21", month: 9, status: 0 }, { year: "2020-21", month: 10, status: 0 }, { year: "2020-21", month: 11, status: 0 },
    { year: "2021-22", month: 0, status: 0 }, { year: "2021-22", month: 1, status: 0 }, { year: "2021-22", month: 2, status: 0 }, { year: "2021-22", month: 3, status: 0 }, { year: "2021-22", month: 4, status: 0 }, { year: "2021-22", month: 5, status: 0 }, { year: "2021-22", month: 6, status: 0 }, { year: "2021-22", month: 7, status: 0 }, { year: "2021-22", month: 8, status: 0 }, { year: "2021-22", month: 9, status: 0 }, { year: "2021-22", month: 10, status: 0 }, { year: "2021-22", month: 11, status: 0 },
    { year: "2022-23", month: 0, status: 0 }, { year: "2022-23", month: 1, status: 0 }, { year: "2022-23", month: 2, status: 0 }, { year: "2022-23", month: 3, status: 0 }, { year: "2022-23", month: 4, status: 0 }, { year: "2022-23", month: 5, status: 0 }, { year: "2022-23", month: 6, status: 0 }, { year: "2022-23", month: 7, status: 0 }, { year: "2022-23", month: 8, status: 0 }, { year: "2022-23", month: 9, status: 0 }, { year: "2022-23", month: 10, status: 0 }, { year: "2022-23", month: 11, status: 0 },
    { year: "2023-24", month: 0, status: 0 }, { year: "2023-24", month: 1, status: 0 }, { year: "2023-24", month: 2, status: 0 }, { year: "2023-24", month: 3, status: 0 }, { year: "2023-24", month: 4, status: 0 }, { year: "2023-24", month: 5, status: 0 }, { year: "2023-24", month: 6, status: 0 }, { year: "2023-24", month: 7, status: 0 }, { year: "2023-24", month: 8, status: 0 }, { year: "2023-24", month: 9, status: 0 }, { year: "2023-24", month: 10, status: 0 }, { year: "2023-24", month: 11, status: 0 },
    { year: "2024-25", month: 0, status: 0 }, { year: "2024-25", month: 1, status: 0 }, { year: "2024-25", month: 2, status: 0 }, { year: "2024-25", month: 3, status: 0 }, { year: "2024-25", month: 4, status: 0 }, { year: "2024-25", month: 5, status: 0 }, { year: "2024-25", month: 6, status: 0 }, { year: "2024-25", month: 7, status: 0 }, { year: "2024-25", month: 8, status: 0 }, { year: "2024-25", month: 9, status: 0 }, { year: "2024-25", month: 10, status: 0 }, { year: "2024-25", month: 11, status: 0 },
    { year: "2025-26", month: 0, status: 0 }, { year: "2025-26", month: 1, status: 0 }, { year: "2025-26", month: 2, status: 0 }, { year: "2025-26", month: 3, status: 0 }, { year: "2025-26", month: 4, status: 0 }, { year: "2025-26", month: 5, status: 0 }, { year: "2025-26", month: 6, status: 0 }, { year: "2025-26", month: 7, status: 0 }, { year: "2025-26", month: 8, status: 0 }, { year: "2025-26", month: 9, status: 0 }, { year: "2025-26", month: 10, status: 0 }, { year: "2025-26", month: 11, status: 0 },
    { year: "2026-27", month: 0, status: 0 }, { year: "2026-27", month: 1, status: 0 }, { year: "2026-27", month: 2, status: 0 }, { year: "2026-27", month: 3, status: 0 }, { year: "2026-27", month: 4, status: 0 }, { year: "2026-27", month: 5, status: 0 }, { year: "2026-27", month: 6, status: 0 }, { year: "2026-27", month: 7, status: 0 }, { year: "2026-27", month: 8, status: 0 }, { year: "2026-27", month: 9, status: 0 }, { year: "2026-27", month: 10, status: 0 }, { year: "2026-27", month: 11, status: 0 }
    ]

export default function PropertyForm({ route, navigation }) {
    const [data, setData] = useState(undefined)
    const ulist = useSelector(state => state.list.Users)
    const [userList, setUserList] = useState([])
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(0)

    function setup(e) {
        const l: [] = []
        data?.ownership.map(o => {
            if (o.year == e.year) {
                l.push(e)
            } else {
                l.push(o)
            }
        })
        setData(p => ({ ...p, ownership: l }))
    }

    function setStatus(entry, type, allNone) {
        const n: [] = []
        const isYear = entry == "2019-20" || entry == "2020-21" || entry == "2021-22" || entry == "2022-23" || entry == "2023-24" || entry == "2024-25"
        console.log("isYear", isYear)
        data[type == 0 ? "rentalMonths" : "clMonths"].map(ne => {
            const nee = { ...ne }
            if (isYear) {
                if (ne.year == entry) {
                    nee.status = allNone == 0 ? 1 : 0
                }
            } else {
                if (ne.year == entry.year && ne.month == entry.month) {
                    nee.status = ne.status == 0 ? 1 : 0
                }
            }
            n.push(nee)
        })
        console.log("data", n)

        if (type == 0) {
            setData(p => ({ ...p, rentalMonths: n }))
        } else {
            setData(p => ({ ...p, clMonths: n }))
        }

    }


    useEffect(() => {
        const dd = JSON.parse(JSON.stringify(route?.params?.data))
        let d = dd
        if (d?.frequency == undefined) {
            d.frequency = 1
        }
        if (d?.rentalMonths == undefined) {
            d.rentalMonths = m
        }
        if (d?.clMonths == undefined) {
            d.clMonths = JSON.parse(JSON.stringify(m))
        }
        if (!d.ownership) {
            d.ownership = [
                { year: "2019-20", doc: "", docdt: "", noc: "", name: "", id: "" },
                { year: "2020-21", doc: "", docdt: "", noc: "", name: "", id: "" },
                { year: "2021-22", doc: "", docdt: "", noc: "", name: "", id: "" },
                { year: "2022-23", doc: "", docdt: "", noc: "", name: "", id: "" },
                { year: "2023-24", doc: "", docdt: "", noc: "", name: "", id: "" },
                { year: "2024-25", doc: "", docdt: "", noc: "", name: "", id: "" },
                { year: "2025-26", doc: "", docdt: "", noc: "", name: "", id: "" },
                { year: "2026-27", doc: "", docdt: "", noc: "", name: "", id: "" }
            ]
        }
        if (d.clMonths) {
            m.map(c => {
                const got = d.clMonths.find(f => f.year == c.year && f.month == c.month)
                if (!got) {
                    d.clMonths.push({ ...c })
                }
            })
        }
        if (d.rentalMonths) {
            m.map(c => {
                const got = d.rentalMonths.find(f => f.year == c.year && f.month == c.month)
                if (!got) {
                    d.rentalMonths.push({ ...c })
                }
            })
        }
        setData(d)
    }, [route?.params])

    useEffect(() => {
        const d = ulist.filter(e => !e.dele)
        setUserList(d)
    }, [ulist])



    function save() {
        if (!data.pno) {
            Toast.show("Error: Property no cannot be empty")
            return
        }
        if (!data.size) {
            Toast.show("Error: Size cannot be empty")
            return
        }
        setLoading(true)
        setTimeout(async () => {
            try {
                const db = getFirestore()
                const working = { ...data }
                let ref
                if (working?.id) {
                    ref = doc(db, "Properties", working.id)
                } else {
                    ref = doc(collection(db, "Properties"))
                    working.id = ref.id

                }
                console.log("working entry", working)
                await setDoc(ref, working, { merge: true })
                Toast.show("Saved")
                setTimeout(() => {
                    navigation.goBack()
                }, 1000)
            } catch (e) {
                Toast.show("Error while saving record : Try later again")
            }
            setLoading(false)
        })
    }
    return (
        <View style={{ flex: 1, alignSelf: "stretch" }}>
            <TextInput
                label="Property No"
                style={{ alignSelf: "stretch", }}
                value={data?.pno ? data.pno : ""}
                onChangeText={(text) => setData(p => ({ ...p, pno: text }))} />

            <TextInput
                label="Size"
                style={{ alignSelf: "stretch", }}
                value={data?.size ? "" + data.size : ""}
                keyboardType="number-pad"
                onChangeText={(text) => {
                    const oyr = text.replace(/[^0-9]/g, '')
                    let vn = 0
                    if (oyr) {
                        vn = parseFloat(oyr)
                    }
                    setData(p => ({ ...p, size: vn }))
                }} />
            {data && ulist ?
                <DInput
                    data_list={ulist}
                    isObject={true}
                    key={data?.currentOwner}
                    value={data?.cowner}
                    placeholder="Current Owner"
                    onSelection={(i) => {
                        setData(p => ({ ...p, currentOwner: i.item.id, cowner: i.item.name }))
                    }}
                    renderItem={(i) =>
                        <Text style={{ padding: 10 }}>{i.item.name}</Text>
                    }
                />
                : <></>}

            {data && ulist ?
                <DInput
                    data_list={ulist}
                    isObject={true}
                    value={data?.ctenant}
                    searchOn="name"
                    key={data?.currentTenant}
                    placeholder="Current Tenant"
                    onSelection={(i) => setData(p => ({ ...p, currentTenant: i.item.id, ctenant: i.item.name }))}
                    renderItem={(i) =>
                        <Text style={{ padding: 10 }}>{i.item.name}</Text>
                    }
                />
                : <></>}

            <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Property Type :</Text>
                <Chip selected={data?.type == 0} onPress={() => setData(p => ({ ...p, type: 0 }))}>Office</Chip>
                <Chip selected={data?.type == 1} onPress={() => setData(p => ({ ...p, type: 1 }))}>Hotel</Chip>
                <Chip selected={data?.type == 2} onPress={() => setData(p => ({ ...p, type: 2 }))}>Classes</Chip>
                <Chip selected={data?.type == 3} onPress={() => setData(p => ({ ...p, type: 3 }))}>Hospital</Chip>
                <Chip selected={data?.type == 4} onPress={() => setData(p => ({ ...p, type: 4 }))}>Hostel</Chip>
            </View>

            <ScrollView horizontal>
                <Text>Floor :</Text>
                <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                    <Chip selected={data?.floor == 0} onPress={() => setData(p => ({ ...p, floor: 0 }))}>   0   </Chip>
                    <Chip selected={data?.floor == 1} onPress={() => setData(p => ({ ...p, floor: 1 }))}>   1   </Chip>
                    <Chip selected={data?.floor == 2} onPress={() => setData(p => ({ ...p, floor: 2 }))}>   2   </Chip>
                    <Chip selected={data?.floor == 3} onPress={() => setData(p => ({ ...p, floor: 3 }))}>   3   </Chip>
                    <Chip selected={data?.floor == 4} onPress={() => setData(p => ({ ...p, floor: 4 }))}>   4   </Chip>
                    <Chip selected={data?.floor == 5} onPress={() => setData(p => ({ ...p, floor: 5 }))}>   5   </Chip>
                    <Chip selected={data?.floor == 6} onPress={() => setData(p => ({ ...p, floor: 6 }))}>   6   </Chip>
                    <Chip selected={data?.floor == 7} onPress={() => setData(p => ({ ...p, floor: 7 }))}>   7   </Chip>
                    <Chip selected={data?.floor == 8} onPress={() => setData(p => ({ ...p, floor: 8 }))}>   8   </Chip>
                    <Chip selected={data?.floor == 9} onPress={() => setData(p => ({ ...p, floor: 9 }))}>   9   </Chip>
                </View>
            </ScrollView>
            <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Given On Rent :</Text>
                {/* <Chip selected={data?.givenOnRent == 1} onPress={() => setData(p => ({ ...p, givenOnRent: 1 }))}>   Yes   </Chip>
                <Chip selected={data?.givenOnRent == 0} onPress={() => setData(p => ({ ...p, givenOnRent: 0 }))}>   No   </Chip> */}
            </View>
            {/* {
                data?.givenOnRent == 1 ? */}
            <View>
                <ScrollView horizontal>
                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2019-20"}</Text>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2019-20" && r.status == 1).length == 12} onPress={() => setStatus("2019-20", 0, 0)}>All</Chip>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2019-20" && r.status == 1).length == 0} onPress={() => setStatus("2019-20", 0, 1)}>None</Chip>
                        {data?.rentalMonths?.filter(y => y.year == "2019-20").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 0, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView>
                <ScrollView horizontal>

                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2020-21"}</Text>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2020-21" && r.status == 1).length == 12} onPress={() => setStatus("2020-21", 0, 0)}>All</Chip>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2020-21" && r.status == 1).length == 0} onPress={() => setStatus("2020-21", 0, 1)}>None</Chip>
                        {data?.rentalMonths?.filter(y => y.year == "2020-21").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 0, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView>

                <ScrollView horizontal>

                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2021-22"}</Text>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2021-22" && r.status == 1).length == 12} onPress={() => setStatus("2021-22", 0, 0)}>All</Chip>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2021-22" && r.status == 1).length == 0} onPress={() => setStatus("2021-22", 0, 1)}>None</Chip>
                        {data?.rentalMonths?.filter(y => y.year == "2021-22").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 0, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView >

                <ScrollView horizontal>
                    {/*TODO SERIOUS*/}
                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2022-23"}</Text>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2022-23" && r.status == 1).length == 12} onPress={() => setStatus("2022-23", 0, 0)}>All</Chip>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2022-23" && r.status == 1).length == 0} onPress={() => setStatus("2022-23", 0, 1)}>None</Chip>
                        {data?.rentalMonths?.filter(y => y.year == "2022-23").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 0, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView>
                <ScrollView horizontal>
                    {/*TODO SERIOUS*/}
                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2023-24"}</Text>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2023-24" && r.status == 1).length == 12} onPress={() => setStatus("2023-24", 0, 0)}>All</Chip>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2023-24" && r.status == 1).length == 0} onPress={() => setStatus("2023-24", 0, 1)}>None</Chip>
                        {data?.rentalMonths?.filter(y => y.year == "2023-24").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 0, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView>
                <ScrollView horizontal>
                    {/*TODO SERIOUS*/}
                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2024-25"}</Text>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2024-25" && r.status == 1).length == 12} onPress={() => setStatus("2024-25", 0, 0)}>All</Chip>
                        <Chip selected={data?.rentalMonths?.filter(r => r.year == "2024-25" && r.status == 1).length == 0} onPress={() => setStatus("2024-25", 0, 1)}>None</Chip>
                        {data?.rentalMonths?.filter(y => y.year == "2024-25").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 0, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView>
            </View>
            {/* : <></>
            } */}


            <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Is Closed :</Text>
                {/* <Chip selected={data?.isClosed == 1} onPress={() => setData(p => ({ ...p, isClosed: 1 }))}>   Open   </Chip>
                <Chip selected={data?.isClosed == 2} onPress={() => setData(p => ({ ...p, isClosed: 2 }))}>   Closed   </Chip> */}
            </View>
            {/* {
                data?.isClosed == 2 ? */}
            <View>
                <ScrollView horizontal>

                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2019-20"}</Text>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2019-20" && r.status == 1).length == 12} onPress={() => setStatus("2019-20", 1, 0)}>All</Chip>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2019-20" && r.status == 1).length == 0} onPress={() => setStatus("2019-20", 1, 1)}>None</Chip>
                        {data?.clMonths?.filter(y => y.year == "2019-20").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 1, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView>
                <ScrollView horizontal>

                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2020-21"}</Text>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2020-21" && r.status == 1).length == 12} onPress={() => setStatus("2020-21", 1, 0)}>All</Chip>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2020-21" && r.status == 1).length == 0} onPress={() => setStatus("2020-21", 1, 1)}>None</Chip>
                        {data?.clMonths?.filter(y => y.year == "2020-21").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 1, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView>

                <ScrollView horizontal>

                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2021-22"}</Text>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2021-22" && r.status == 1).length == 12} onPress={() => setStatus("2021-22", 1, 0)}>All</Chip>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2021-22" && r.status == 1).length == 0} onPress={() => setStatus("2021-22", 1, 1)}>None</Chip>
                        {data?.clMonths?.filter(y => y.year == "2021-22").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 1, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView >

                <ScrollView horizontal>

                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2022-23"}</Text>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2022-23" && r.status == 1).length == 12} onPress={() => setStatus("2022-23", 1, 0)}>All</Chip>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2022-23" && r.status == 1).length == 0} onPress={() => setStatus("2022-23", 1, 1)}>None</Chip>
                        {data?.clMonths?.filter(y => y.year == "2022-23").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 1, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView>
                <ScrollView horizontal>

                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2023-24"}</Text>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2023-24" && r.status == 1).length == 12} onPress={() => setStatus("2023-24", 1, 0)}>All</Chip>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2023-24" && r.status == 1).length == 0} onPress={() => setStatus("2023-24", 1, 1)}>None</Chip>
                        {data?.clMonths?.filter(y => y.year == "2023-24").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 1, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView>
                <ScrollView horizontal>
                    {console.log(data?.clMonths?.filter(r => r.year == "2024-25" && r.status == 1))}
                    <View style={{ flexDirection: "row" }}>
                        <Text>{"2024-25"}</Text>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2024-25" && r.status == 1).length == 12} onPress={() => setStatus("2024-25", 1, 0)}>All</Chip>
                        <Chip selected={data?.clMonths?.filter(r => r.year == "2024-25" && r.status == 1).length == 0} onPress={() => setStatus("2024-25", 1, 1)}>None</Chip>
                        {data?.clMonths?.filter(y => y.year == "2024-25").map(m =>
                            <Chip selected={m.status == 1} onPress={() => setStatus(m, 1, -1)}>{Global.getMonthName(m.month)}</Chip>
                        )}
                    </View>
                </ScrollView>

            </View>
            {/* : <></>
            } */}

            <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Has Lift :</Text>
                <Chip selected={data?.hasLift == 1} onPress={() => setData(p => ({ ...p, hasLift: 1 }))}>   Yes   </Chip>
                <Chip selected={data?.hasLift == 0} onPress={() => setData(p => ({ ...p, hasLift: 0 }))}>   No   </Chip>
            </View>

            <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Has Parking :</Text>
                <Chip selected={data?.hasParking == 1} onPress={() => setData(p => ({ ...p, hasParking: 1 }))}>   Yes   </Chip>
                <Chip selected={data?.hasParking == 0} onPress={() => setData(p => ({ ...p, hasParking: 0 }))}>   No   </Chip>
            </View>
            <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Has Water :</Text>
                <Chip selected={data?.hasWaterConnection == 1} onPress={() => setData(p => ({ ...p, hasWaterConnection: 1 }))}>   Yes   </Chip>
                <Chip selected={data?.hasWaterConnection == 0} onPress={() => setData(p => ({ ...p, hasWaterConnection: 0 }))}>   No   </Chip>
            </View>

            <View>
                <Text style={{ fontWeight: "bold" }}>Deposit Pending : {data?.depositPending}</Text>
                <TextInput
                    label="Deposit Required"
                    style={{ alignSelf: "stretch", }}
                    value={data?.depositRequire ? "" + data.depositRequire : ""}
                    keyboardType="number-pad"
                    onChangeText={(text) => {
                        const oyr = text.replace(/[^0-9]/g, '')
                        let vn = 0
                        if (oyr) {
                            vn = parseInt(oyr)
                        }
                        setData(p => ({ ...p, depositRequire: vn, depositPending: data?.depositPaid - data?.depositRequire }))
                    }} />

                <TextInput
                    label="Deposit Paid"
                    style={{ alignSelf: "stretch", }}
                    value={data?.depositPaid ? "" + data.depositPaid : ""}
                    keyboardType="number-pad"
                    onChangeText={(text) => {
                        const oyr = text.replace(/[^0-9]/g, '')
                        let vn = 0
                        if (oyr) {
                            vn = parseFloat(oyr)
                        }
                        setData(p => ({ ...p, depositPaid: vn, depositPending: (vn - data?.depositRequire) }))
                    }} />

                <Text style={{ fontWeight: "bold" }}>Deposit Payment Year: {data?.depositPaidFrom}</Text>
                <ScrollView horizontal>
                    <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                        {
                            Global.years.map(year =>
                                <Chip selected={data?.depositPaidFrom == year} onPress={() => setData(p => ({ ...p, depositPaidFrom: year }))}>{year}</Chip>
                            )
                        }
                    </View>
                </ScrollView>

                <TextInput
                    label="Deposit Remarks"
                    style={{ alignSelf: "stretch", }}
                    value={data?.depositRemarks ? "" + data.depositRemarks : ""}
                    onChangeText={(text) => {
                        setData(p => ({ ...p, depositRemarks: text }))
                    }} />
            </View>
            <View>
                <Text style={{ fontWeight: "bold" }}>Transferfee Pending : {data?.transferFeePaid}</Text>
                <TextInput
                    label="Transferfee Required"
                    style={{ alignSelf: "stretch", }}
                    value={data?.transferFeeRequire ? "" + data.transferFeeRequire : ""}
                    keyboardType="number-pad"
                    onChangeText={(text) => {
                        const oyr = text.replace(/[^0-9]/g, '')
                        let vn = 0
                        if (oyr) {
                            vn = parseFloat(oyr)
                        }
                        setData(p => ({ ...p, transferFeeRequire: vn, depositPending: Math.abs(vn - data?.transferFeeRequire) }))
                    }} />

                <TextInput
                    label="Transferfee Paid"
                    style={{ alignSelf: "stretch", }}
                    value={data?.transferFeePaid ? "" + data.transferFeePaid : ""}
                    keyboardType="number-pad"
                    onChangeText={(text) => {
                        const oyr = text.replace(/[^0-9]/g, '')
                        let vn = 0
                        if (oyr) {
                            vn = parseFloat(oyr)
                        }
                        setData(p => ({ ...p, transferFeePaid: vn, depositPending: Math.abs(vn - data?.transferFeeRequire) }))
                    }} />
                <TextInput
                    label="Transfer Fees Remarks"
                    style={{ alignSelf: "stretch", }}
                    value={data?.trRemarks ? "" + data.trRemarks : ""}
                    onChangeText={(text) => {
                        setData(p => ({ ...p, trRemarks: text }))
                    }} />

            </View>
            <Text style={{ marginTop: 20, fontWeight: "bold" }}>Ownership Record</Text>
            {
                data?.ownership?.map(o => {
                    return <View style={{ marginTop: 10 }}>

                        <DInput
                            data_list={ulist}
                            isObject={true}
                            value={o.name}
                            placeholder={o.year + " - Owner"}
                            onSelection={(i) => {
                                console.log("444", i)
                                const oo = { ...o }
                                oo.id = i.item.id
                                oo.name = i.item.name
                                setup(oo)
                            }}
                            renderItem={(i) =>
                                <Text style={{ padding: 10 }}>{i.item.name}</Text>
                            }
                        />
                        <View style={{ flexDirection: "row" }}>
                            <TextInput
                                value={o.docno}
                                style={{ flex: 1 }}
                                label="Doc No"
                                onChangeText={(t) => {
                                    const oo = { ...o }
                                    oo.docno = t
                                    setup(oo)
                                }}
                            />
                            <TextInput
                                style={{ flex: 1 }}
                                value={o.docdt}
                                label="Doc Date"
                                onChangeText={(t) => {
                                    const oo = { ...o }
                                    oo.docdt = t
                                    setup(oo)
                                }}
                            />
                        </View>
                        <View style={{ flexDirection: "row" }}>
                            <TextInput
                                style={{ flex: 1 }}
                                value={o.noc}
                                label="NOC"
                                onChangeText={(t) => {
                                    const oo = { ...o }
                                    oo.noc = t
                                    setup(oo)
                                }} />

                            <TextInput
                                style={{ flex: 1 }}
                                value={o.remarks}
                                label="remarks"
                                onChangeText={(t) => {
                                    const oo = { ...o }
                                    oo.remarks = t
                                    setup(oo)
                                }} />

                        </View>
                    </View>
                })
            }


            <View style={{ flexDirection: "row", paddingTop: 20 }}>
                <Button style={{ flex: 1 }} onPress={() => navigation.goBack()}>Back</Button>
                <Button loading={loading} mode="contained" style={{ flex: 1 }} onPress={() => save()}>Save</Button>
            </View>
        </View >
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
