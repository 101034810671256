import { useState, useEffect } from 'react';
import { ActivityIndicator, SafeAreaView, View, Text, VirtualizedList, StyleSheet, Pressable, FlatList, Image } from 'react-native';
import { Provider, Button, Searchbar, Divider, Avatar } from 'react-native-paper';
import * as React from 'react';

import { RootStackScreenProps } from '../types';

import { useSelector } from 'react-redux';
import moment from 'moment';

export default function Backup({ route, navigation }) {
    const list = useSelector((state) => state.list)

    const [str, setStr] = useState("");
    const [fileName, setFileName] = useState("");
    const [loading, setLoging] = useState(true);
    const [counts, setCounts] = useState({ Payments: 0, Users: 0, Charges: 0, Entries: 0, Properties: 0 });

    useEffect(() => {
        let j: { backupDate: number, Payments: [], Users: [], Charges: [], Entries: [], Properties: [] } =
            { backupDate: 0, Payments: [], Users: [], Charges: [], Entries: [], Properties: [] }
        j.Payments = list.Payments
        j.Users = list.Users
        j.Charges = list.Charges
        j.Entries = list.Entries
        j.Properties = list.Properties

        j.backupDate = Math.floor(new Date().getTime() / 1000)
        let t = JSON.stringify(j)
        setCounts({
            Payments: j.Payments.length + 1,
            Charges: j.Entries.length + 1,
            Entries: j.Entries.length + 1,
            Users: j.Users.length + 1,
            Properties: j.Properties.length + 1
        })
        let td = moment().format('DDMMMYY')
        setStr(t)
        setFileName(td + ".ashwamegh")
        setLoging(false)
    }, [])


    return (
        <SafeAreaView style={{ backgroundColor: "white", flex: 1 }}>
            <View style={{ justifyContent: "center" }}>

                {
                    loading ?
                        <View style={{ flexDirection: "row", justifyContent: "center" }}><Text>Generating Data   </Text>
                            <ActivityIndicator animating={loading} /> </View> :
                        <>
                            <Text>Charges :{counts.Charges}</Text>
                            <Text>Payments :{counts.Payments}</Text>
                            <Text>Entries :{counts.Entries}</Text>
                            <Text>Users :{counts.Users}</Text>
                            <Text>Properties :{counts.Properties}</Text>
                            <a download={fileName} href={
                                URL.createObjectURL(new Blob([str],
                                    { type: 'text/plain;charset=utf-8' }))}>Download</a>
                            <Button style={{ marginTop: 15 }} onPress={() => navigation.goBack()}>Back</Button>
                        </>
                }


            </View>
        </SafeAreaView>

    );

}

