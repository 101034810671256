import { Button, Divider, IconButton, Text } from 'react-native-paper';
// import Modal from "react-native-modal";
import { Dimensions, Platform, ScrollView, TouchableOpacity, View } from 'react-native';
import React, { memo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DAvatar from './DAvatar';
import Toast from 'react-native-root-toast';
import Modal from "react-native-modal"
// return <IconButton iconColor={op?.color ? op.color : "gray"} icon="menu" onPress={() => op.navigation.toggleDrawer()} />;

import Prferances from '../constants/Preferances';
import Global from '../constants/global';

const h = Dimensions.get("window").height
const Hemberger = React.memo((op) => {
    // const login = useSelector(s => s.login)

    const [show, setShow] = React.useState(false)
    const col = "white"
    const navigation = op.navigation
    // console.log("nav", navigation)
    // const dispatch = useDispatch()
    const login = useContext(Prferances)

    const ModalCore = () => {
        // return <View style={{ position: "absolute", left: 0, backgroundColor: "#36454F", height: h }}>

        return <View style={{ backgroundColor: "#36454F", flex: 1, minHeight: h }}>
            <ScrollView>
                <View style={{ alignSelf: "stretch", padding: 20, backgroundColor: "#485d6a" }}>
                    <View style={{ paddingStart: 10, flexDirection: "row", alignItems: "center", }}>
                        <DAvatar type={Global.BIG_ROW_DP} title={login?.current?.name} file={login?.current.dp} />
                        <View style={{ paddingStart: 10 }}>
                            <Text style={{ color: "#ffcc66", fontSize: 24 }}>{login?.current?.name}</Text>
                            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <Text style={{ color: col }}>{login?.current?.type}</Text>
                                <IconButton style={{ padding: 0, margin: 0 }} iconColor={col} size={14} icon="pencil" onPress={() => {
                                    setShow(false);
                                    if (login?.current?.type == Global.TYPE_BUSINESS) {
                                        // let merged = new User()
                                        // let newone = { ...merged, ...login.user };
                                        // navigation.navigate("Profile", { data: newone, update: true })
                                    }
                                }} />
                            </View>
                        </View>
                    </View>
                    {/* <Text style={{ color: "white", flex: 1, textAlign: "center" }}>Year {Global.SUPPLIER.current_yr}-{Global.SUPPLIER.current_yr + 1 - 2000}</Text> */}
                </View>
                <TouchableOpacity onPress={() => {
                    setShow(false);
                    navigation.navigate("Users")
                }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <IconButton iconColor={col} icon={"account-circle-outline"} />
                        <View style={{ flexDirection: "row", padding: 10 }}>
                            <Text style={{ color: col }}> Users</Text>
                        </View>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                    setShow(false);
                    navigation.navigate("Properties")
                }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <IconButton iconColor={col} icon={"office-building-marker"} />
                        <View style={{ flexDirection: "row", padding: 10 }}>
                            <Text style={{ color: col }}> Properties</Text>
                        </View>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                    setShow(false);
                    navigation.navigate("Charges")
                }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <IconButton iconColor={col} icon={"file-sign"} />
                        <View style={{ flexDirection: "row", padding: 10 }}>
                            <Text style={{ color: col }}> Charges</Text>
                        </View>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                    setShow(false);
                    navigation.navigate("Payments")
                }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <IconButton iconColor={col} icon={"cash-check"} />
                        <View style={{ flexDirection: "row", padding: 10 }}>
                            <Text style={{ color: col }}> Payments</Text>
                        </View>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                    setShow(false);
                    navigation.navigate("Statement")
                }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <IconButton iconColor={col} icon={"clipboard-list-outline"} />
                        <View style={{ flexDirection: "row", padding: 10 }}>
                            <Text style={{ color: col }}> Statement</Text>
                        </View>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                    setShow(false);
                    navigation.navigate("InEx")
                }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <IconButton iconColor={col} icon={"currency-inr"} />
                        <View style={{ flexDirection: "row", padding: 10 }}>
                            <Text style={{ color: col }}> Income Expences</Text>
                        </View>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                    setShow(false);
                    navigation.navigate("Backup")
                }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <IconButton iconColor={col} icon={"cloud-upload-outline"} />
                        <View style={{ flexDirection: "row", padding: 10 }}>
                            <Text style={{ color: col }}> Backup</Text>
                        </View>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                    setShow(false);
                    navigation.navigate("Logout")
                }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <IconButton iconColor={col} icon={"logout-variant"} />
                        <View style={{ flexDirection: "row", padding: 10 }}>
                            <Text style={{ color: col }}> Logout</Text>
                        </View>
                    </View>
                </TouchableOpacity>

            </ScrollView >
        </View >
    }

    return <View tabIndex={-1}>
        {login?.current?.id &&
            <>
                {Global.isMobile() ?
                    <IconButton iconColor={col} icon="menu" onPress={() => setTimeout(() => { setShow(!show) })} /> :
                    <ModalCore />
                }
            </>
        }

        <Modal
            propagateSwipe
            backdropOpacity={0.5}
            style={{ margin: 0 }}
            isVisible={show}
            onBackdropPress={() => setTimeout(() => { setShow(false) })}
            animationIn={"slideInLeft"}
            animationOut={"slideOutLeft"}>
            <ModalCore />
        </Modal>
    </View >

})
export default memo(Hemberger)