import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dimensions, Share, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import Global from "../constants/global"
import { useSelector } from 'react-redux';
import { doc, getFirestore, setDoc, writeBatch } from 'firebase/firestore';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import DAvatar from './DAvatar'
import { Text, Card, IconButton, Modal, Portal, Dialog, Button, Divider, Chip } from 'react-native-paper';
import * as Clipboard from 'expo-clipboard';
import Toast from 'react-native-root-toast';


interface option {
    info: any
    callback(user: any): void,
    reapply(prop: any, year: string): void,
    navigation: any
    extra: any
    index: number
}
const PropertyRow = (op: option) => {
    const [isOpen, setOpen] = useState(false);
    function onClose() { setOpen(false) }
    const col = Global.getColor(op.index)
    const [deleConfirmation, setDeleConfirmation] = useState(false);
    const allAcc = useSelector(state => state.list.Entries)

    function DeleteEntry(type: number) {
        const db = getFirestore()
        const ref = doc(db, "Properties", op.info.id)
        const batch = writeBatch(db)
        batch.delete(ref)

        const ents = allAcc.filter(e => e.property == op.info.pno)
        ents.map(e => {
            const ref = doc(db, "Entries", e.id)
            batch.delete(ref)
        })
        batch.commit()
        setDeleConfirmation(false)
        Toast.show("Property and Its Account Entries Hard Deleted")
    }
    const nav = useNavigation()

    const onShare = async (url: string, title: string) => {
        try {
            const result = await Share.share({
                message:
                    (title + '\n' + url)
            });
            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    alert("1")
                    // shared with activity type of result.activityType
                } else {
                    alert("2")
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {
            alert(error.message);
        }
    }
    const copyToClipboard = async () => {
        await Clipboard.setStringAsync("https://ashwameghta.web.app/stat/" + op.info.currentOwner);
        //        await Clipboard.setStringAsync("Hello, \nStatement Link\nhttps://ashwameghta.web.app/stat/" + op.info.id + "\nThank You Rahul Joshi");
        Toast.show("Link Copied")
    };

    return (
        <View style={{ flexDirection: "row", marginTop: 8, marginBottom: 8, flex: 1 }}>
            <TouchableOpacity onPress={() => setOpen(true)} style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", marginLeft: 4, alignSelf: "stretch", flex: 1 }}>
                    <DAvatar title={op.info.pno.trim()} color={col} file={op.info?.dp} type={Global.ROW_DP} />
                    <View style={{ alignSelf: "stretch", marginLeft: 10, flex: 1 }}>
                        <Text variant="bodyLarge" style={{ color: col, flex: 1, fontWeight: "bold" }}>{op.info.pno}</Text>
                        {
                            op.info.cowner ? <Text variant="bodyLarge" style={{ color: col, }}>Owner: {op.info.cowner}</Text> : <></>
                        }
                        {
                            op.info.ctenant ? <Text variant="bodyLarge" style={{ color: col, }}>Tenant: {op.info.ctenant}</Text> : <></>
                        }

                    </View>
                    <Text>{"floor : " + op.info.floor}  </Text>
                    <Text>{op.info.depositPending > 0 ? "Deposit Pending :" + op.info.depositPending + ", " : ""} Size :{op.info.size} </Text>
                    <Text>{op.info?.verified ? "Entries varified :" + op.info.verified.map(v => v + ", ") : ""}  </Text>

                </View>
                <Divider />
            </TouchableOpacity>

            <Portal>
                <Modal style={{ backgroundColor: "rgba(0,0,0,0.8)" }} visible={isOpen} contentContainerStyle={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width }} dismissable onDismiss={() => setOpen(false)}>
                    <View style={{ justifyContent: "center", backgroundColor: Global.THEME.surfaceVariant }}>
                        <Text style={{ padding: 13, fontWeight: "bold", flex: 1 }}>{op.info.pno}...</Text>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <TouchableOpacity onPress={() => {
                                setOpen(false);
                                op.navigation.navigate(
                                    'Statement', { pno: op.info.pno, editable: op?.super, user: undefined, party: undefined, type: op?.super ? 2 : op?.committee ? 1 : 0 }
                                )
                                // op.navigation.navigate('Statement', {pno: op.info.pno, editable: op?.super, user: undefined, party: undefined });
                            }}>
                                <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "transparent" }}>
                                    <IconButton icon="format-list-bulleted" />
                                    <Text>View Statement</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity onPress={() => {
                            onShare("Statement Sharing", "https://ashwameghta.web.app/stat/" + op.info.currentOwner)
                        }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton icon="share" />
                                <Text style={{}}>{"Share User Statement "}</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => copyToClipboard()}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton icon="content-copy" />
                                <Text style={{ paddingLeft: 10 }}>Copy link :   {"https://ashwameghta.web.app/stat/" + op.info.currentOwner}</Text>
                            </View>
                        </TouchableOpacity>

                        {op?.super && <TouchableOpacity onPress={() => {
                            onClose();
                            op.navigation.navigate(
                                'PropertiesForm', { data: op.info }
                            )
                        }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton icon="pencil-outline" />
                                <Text style={{ paddingLeft: 10 }}>Edit Property</Text>
                            </View>
                        </TouchableOpacity>}

                        {op?.super && <TouchableOpacity onPress={() => { onClose(); setDeleConfirmation(true) }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton icon="delete" />
                                <Text style={{ paddingLeft: 10 }}>Delete Property</Text>
                            </View>
                        </TouchableOpacity>}
{/* 
                        {op?.super && <TouchableOpacity onPress={() => { onClose(); setDeleConfirmation(true) }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <IconButton icon="cog-play-outline" />
                                <Text>ReApply Charge </Text>
                                <ScrollView horizontal>
                                    <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                                        {
                                            Global.years.map(year =>
                                                <Chip selected={op.info?.depositPaidFrom == year} onPress={() => op.reapply(op.info, year)}>{year}</Chip>
                                            )
                                        }
                                    </View>
                                </ScrollView>
                            </View>
                        </TouchableOpacity>} */}


                    </View>
                    <IconButton icon="close" style={{ position: "absolute", right: 0, top: 0 }} size={24} onPress={() => onClose()} />
                </Modal>
            </Portal>
            <Portal>
                <Modal style={{ backgroundColor: "rgba(0,0,0,0.8)" }} onDismiss={() => setDeleConfirmation(false)} visible={deleConfirmation} contentContainerStyle={{ position: "absolute", bottom: 0, width: Dimensions.get("window").width }} >
                    <View style={{ justifyContent: "center", backgroundColor: Global.THEME.surfaceVariant }}>
                        <Text variant='headlineSmall'>Delete Property ({op.info.pno})? </Text>
                        <Text style={{ padding: 10 }}>It is highly advisable to Take Backup before proceding</Text>
                        <Text style={{ padding: 10 }}>Note : Beaware, Deleting Property will delete it's property and  related entries. And cannot be recoverd</Text>

                        <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent" }}>
                            <Button style={{ flex: 1 }} onPress={() => setDeleConfirmation(false)}>Cancel</Button>
                            <Button style={{ flex: 1 }} onPress={() => DeleteEntry(0)}>Delete Property & Its Accounting Entries</Button>
                        </View>
                    </View>

                </Modal>
            </Portal>

        </View >)
}


const styles = StyleSheet.create({
    item: {
        textColor: 'white',
        fontWeight: '900',
        flex: 1,
        alignItems: "center",
        marginVertical: 2,
        marginHorizontal: 2,
        paddingLeft: 20,
        flexDirection: "row"
    },
    phone: {
        color: "teal"
    },
    title: {
        fontWeight: "bold"
    },
    city: {
        fontStyle: "italic",
    },
    subset: {
        fontSize: 9,
    },
    onletter: {
        fontSize: 20
    }
});
//const Bhudev = React.memo(OBhudev)
export default PropertyRow