// DONE CHARGE FORM
// DONE PAYMENT form, auto increment
// DONE PAYMENT status
// DONE INEX  APPROVAL
// done inex  head selection
// DONE INEX  reminder
// DONE  PROPERTY
// DONE DONE USER FORM
// USER DP & (not now,RIGHTS)
// PENDING ASHWAMEGH STATEMENT => OPENING ETC


import { FontAwesome } from '@expo/vector-icons';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from 'react';
import { ColorSchemeName, Pressable } from 'react-native';
// import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';

import Preferances from '../constants/Preferances';
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';

const Stack = createNativeStackNavigator<RootStackParamList>();

import Home from '../screens/Home';
import Users from '../screens/Users';
import Properties from '../screens/Properties';
import Doctor from '../screens/Docotor';
import Payments from '../screens/Payments';
import Charges from '../screens/Charges';
import Statement from '../screens/Statement';
import Backup from '../screens/Backup';
import PropertiesForm from '../screens/PropertiesForm';
import InExForm from '../screens/InExForm';
import LinkingConfiguration from './LinkingConfiguration';
import InEx from '../screens/InEx';
import PaymentForm from '../screens/PaymentForm';
import ChargeForm from '../screens/ChargeForm';
import UserForm from '../screens/UserForm';
import Login from '../screens/Login';
import { useSelector } from 'react-redux';
import Logout from '../screens/Logout';
import { View } from '../components/Themed';
import Receipt from '../components/Receipt';
import WhatsApp from '../screens/WhatsApp';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const pref = useSelector(state => state.login)
  //console.log("pref?.current?.type", pref?.current)
  return pref?.current?.id ? <Preferances.Provider value={pref}>
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator comitte={pref?.current?.type == "Committee"} SuperAdmin={pref?.current?.isSuperAdmin} userid={pref?.current?.id} />

    </NavigationContainer>
  </Preferances.Provider> : <Login />

}

const Drawer = createStackNavigator();

function RootNavigator(op) {
  return (
    <Stack.Navigator animationEnabled={true} initialRouteName="Home">
      <Stack.Screen name="Home" component={Home} options={{ headerShown: false }} />
      <Stack.Screen name="Users" component={Users} options={{ headerShown: false }} />
      <Stack.Screen name="Properties" component={Properties} options={{ headerShown: false }} />
      {op.SuperAdmin && <Stack.Screen name="Charges" component={Charges} options={{ headerShown: false }} />}
      <Stack.Screen name="Payments" component={Payments} options={{ headerShown: false }} />
      <Stack.Screen name="Statement" component={Statement} options={{ headerShown: false }} initialParams={{ user: op.userid, editable: false, pno: undefined, party: undefined, type: op.SuperAdmin ? 2 : op.comitte ? 1 : 0 }} />
      <Stack.Screen name="Receipt" component={Receipt} options={{ headerShown: false, drawerItemStyle: { display: 'none' } }} initialParams={{ receipt: undefined }} />
      {(op.SuperAdmin || op.comitte) && <Stack.Screen name="InEx" component={InEx} options={{ headerShown: false }} />}
      {(op.SuperAdmin || op.comitte) && <Stack.Screen name="InExForm" component={InExForm} options={{ headerShown: true, title: "Income / Expence", drawerItemStyle: { display: 'none' } }} />}
      {(op.SuperAdmin) && <Stack.Screen name="Doctor" component={Doctor} options={{ headerShown: true, title: "Income / Expence", drawerItemStyle: { display: 'none' } }} />}
      {(op.SuperAdmin || op.comitte) && <Stack.Screen name="PaymentForm" component={PaymentForm} options={{ headerShown: true, title: "Payment", drawerItemStyle: { display: 'none' } }} />}
      {op.SuperAdmin && <Stack.Screen name="ChargeForm" component={ChargeForm} options={{ headerShown: true, title: "Charges", drawerItemStyle: { display: 'none' } }} />}
      <Stack.Screen name="UserForm" component={UserForm} options={{ headerShown: true, title: "User", drawerItemStyle: { display: 'none' } }} />
      <Stack.Screen name="PropertiesForm" component={PropertiesForm} options={{ headerShown: true, title: "Property", drawerItemStyle: { display: 'none' } }} />
      {op.SuperAdmin && <Drawer.Screen name="Backup" component={Backup} options={{ headerShown: true }} />}
      {op.SuperAdmin && <Drawer.Screen name="WhatsApp" component={WhatsApp} options={{ headerShown: true }} />}
      <Stack.Screen name="Login" component={Login} options={{ headerShown: false, drawerItemStyle: { display: 'none' } }} />
      <Stack.Screen name="Logout" component={Logout} options={{ headerShown: true }} />
    </Stack.Navigator>);
}
