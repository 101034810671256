import React, { memo, useContext, useEffect, useMemo, useState } from 'react';

export const type = {
    lang: "e",
    user:undefined,
    env:undefined
}



export const Prferances = React.createContext(type);

//const prefs = memo(Prferances)
export default Prferances


