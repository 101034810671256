import { Image, StyleSheet, ScrollView, Dimensions, TouchableOpacity } from 'react-native';

import Hemberger from '../components/Hemberger';
import { View } from '../components/Themed';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Text, Divider, ActivityIndicator, Card, IconButton, Portal, Modal, TextInput, Button } from 'react-native-paper';
import ReactToPrint from 'react-to-print';
import dayjs from 'dayjs';
import Global from '../constants/global';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { CommonActions } from '@react-navigation/native';
import BackButton from './BackButton';

const noprint = `@media print {
    .noprint {display: none}
}`

export default function Receipt(op) {
    const payments = useSelector(state => state.list.Payments)
    const ref = React.createRef();

    const [receiptId, setReceiptId] = React.useState("")

    const [data, setData] = React.useState({ payment: {} })
    const [loading, setLoading] = React.useState({ error: "", working: true, isApp: false })
    console.log("opions", op)


    async function load(rece: string) {
        const db = getFirestore()
        const userRef = collection(db, "Payments");
        console.log("doccc rece", rece)

        const userQuery = query(userRef, where("id", "==", rece));
        const udata = await getDocs(userQuery)
        let uObj
        udata.forEach((doc) => {
            console.log("doccc", doc)
            uObj = doc.data()
        });
        console.log("uboj", uObj)
        if (uObj) {
            setData({ payment: uObj })
            setLoading(p => ({ ...p, working: false, isApp: false }))
        } else {
            setLoading(p => ({ ...p, error: "Error : Unable for fetch Payment data. Please contact admin", working: false, isApp: false }))
        }
    }
    React.useEffect(() => {
        if (op?.route?.params?.receipt) {
            setReceiptId(op?.route?.params?.receipt)
        } else if (op?.rece) {
            console.log("doccc rece", op)
            load(op.rece)
        }
    }, [op])


    React.useEffect(() => {
        if (receiptId && payments) {
            const pay = payments.find(p => p.id == receiptId)
            setData({ payment: pay })
            setLoading(p => ({ ...p, working: false, isApp: true }))
        }
    }, [receiptId, payments])

    const Elem = React.forwardRef((props, ref) => (<ScrollView >
        <ScrollView horizontal ref={ref}>
            <View style={{ alignSelf: "stretch", minWidth: 768, maxWidth: 768, padding: 10 }}>
                {
                    loading.working ?
                        <View style={{ backgroundColor: "transparent", flex: 1, alignSelf: "stretch", }}>
                            <ActivityIndicator style={{ padding: 30 }} animating />
                        </View>
                        :
                        <View style={styles.container}>
                            {
                                loading.error ? <Text style={{ color: "red", fontSize: 18, margin: 20 }}>{loading.error}</Text> :
                                    <View >
                                        <View style={{ flexDirection: "row", alignSelf: "stretch", justifyContent: "space-between", backgroundColor: "transparent" }}>
                                            {loading.isApp && <div className="noprint">
                                                <BackButton navigation={op.navigation} /></div>}
                                            <View style={{ flexDirection: "row", alignItems: "baseline", paddingRight: 10 }}>
                                                {op?.navigation && <div className="noprint"><Text onPress={() =>
                                                    op.navigation.navigate(
                                                        'Statement', { receipt: data.payment.paidBy }
                                                    )} >View Statement</Text></div>}
                                                <ReactToPrint trigger={() => <button className="noprint" style={{ right: 10 }} >Print</button>}
                                                    pageStyle={noprint}
                                                    content={() => ref.current}
                                                />
                                            </View>
                                        </View>
                                        <Text variant="headlineSmall" style={{ color: "black", alignSelf: "stretch", textAlign: "center" }}>Ashwamegh Owner's Association - Tower A</Text>
                                        <Text style={{ color: "black", alignSelf: "stretch", textAlign: "center" }}>1st Floor, Ashwamegh Tower-A, Opp.Sayaji Vihar Club, Rajmahal Road, Vaodara-390 001</Text>
                                        <Text style={{ color: "black", alignSelf: "stretch", textAlign: "center" }}>Pan no. AAHAA87608</Text>
                                        <View style={{ borderStyle: "solid", borderTopWidth: 1, borderBottomWidth: 1, borderColor: "black", flexDirection: 'row', backgroundColor: "transparent" }}>
                                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "transparent" }}>
                                                <Text style={{ color: "black", flex: 1, textAlign: "center" }}>Receipt No</Text>
                                                <Text style={{ fontWeight: "bold", color: "black", flex: 1, textAlign: "center" }}>{data.payment.receiptNo}</Text>
                                            </View>
                                            <View style={{ borderStyle: "solid", borderRightWidth: 1, borderLeftWidth: 1, borderColor: "black", flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "transparent" }}>
                                                <Text style={{ color: "black", flex: 1, textAlign: "center" }}>Receipt</Text>
                                                <Text style={{ fontWeight: "bold", color: "black", flex: 1, textAlign: "center" }}>{
                                                    data?.payment?.paymentMode == 1 ? "Cash" :
                                                        data?.payment?.paymentMode == 2 ? "Cheque" :
                                                            data?.payment?.paymentMode == 3 ? "Online" : "DD"}</Text>
                                            </View>
                                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "transparent" }}>
                                                <Text style={{ color: "black", flex: 1, textAlign: "center" }}>Date</Text>
                                                <Text style={{ fontWeight: "bold", color: "black", flex: 1, textAlign: "center" }}>{data.payment.date > 0 ? dayjs.unix(data.payment.date).format("DD-MM-YYYY") : ""}</Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: "row", backgroundColor: "transparent", padding: 5 }}>
                                            <Text>Received with thanks from </Text>
                                            <Text style={{ fontWeight: "bold", textDecorationLine: "underline" }}>M/s {data?.payment?.paidByName}</Text>
                                        </View>
                                        <View style={{ flexDirection: "row", backgroundColor: "transparent", padding: 5 }}>
                                            <Text>The sum of rupees  </Text>
                                            <Text style={{ fontWeight: 'bold' }}>{Global.getWord(data?.payment?.amount)}</Text>
                                            {/* <Text> for financial year {data?.payment?.year} </Text> */}
                                        </View>
                                        <View style={{ flexDirection: "row", backgroundColor: "transparent", padding: 5 }}>
                                            <Text>Amount : </Text>
                                            <Text style={{ fontWeight: 'bold' }}>{Global.RS + " " + data?.payment?.amount}</Text>
                                        </View>
                                        {data?.payment?.instrumentNo && <Text style={{ padding: 5 }}>Instrument No. : {data?.payment?.instrumentNo}</Text>}
                                        {data?.payment?.instrument && <Text style={{ padding: 5 }}>Drawn on : {data.payment.instrument}</Text>}
                                        <Text style={{ padding: 5 }}>Status : {data?.payment?.status == 11 ? "Successfull" : "failed"}</Text>
                                        <Text style={{ padding: 5 }}>Received / Entry by : {data?.payment?.amount}</Text>
                                        <Text style={{ padding: 5 }}>Remarks : {data?.payment?.remarks}</Text>
                                        {data?.payment?.appliedTo && <Text style={{ padding: 5 }}>Allocated to property {data?.payment?.appliedTo?.map(m => m.Amount > 0 ? m.Property + "= " + Global.RS + " " + m.Amount + ", " : "")}</Text>}

                                        <Text style={{ borderTopWidth: 1, borderColor: "black", borderStyle: "solid", padding: 5, fontWeight: "bold", flex: 1, alignSelf: "stretch", textAlign: "right" }}>For Ashwamegh Owner's Association Tower 'A'</Text>
                                        <Text style={{ flex: 1, alignSelf: "stretch", textAlign: "right", padding: 5, marginTop: 20 }}>Authorized Signatory</Text>
                                        <Text style={{ flex: 1, alignSelf: "stretch" }}>This receipt is valid subject to realization</Text>
                                        <Text style={{ flex: 1, alignSelf: "stretch", textAlign: "center" }}>(This receipt is for information only and cannot be used as evidence)</Text>
                                    </View >

                            }
                        </View >
                }
            </View >
        </ScrollView>
    </ScrollView >))
    return <Elem ref={ref} />;
}

const styles = StyleSheet.create({
    container: {
        alignSelf: "stretch",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "black"
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
