import { DeviceType, getDeviceTypeAsync } from 'expo-device';
import React, { useRef, PureComponent, useEffect } from 'react';
import { Keyboard, StyleSheet, Text, View, FlatList, Button, ScrollView, Dimensions, Pressable, TouchableOpacity } from 'react-native';
import { Card, Modal, Portal, TextInput } from 'react-native-paper';
import Global from '../constants/global';


//Define a color for toolbar
//global.backgroundColor = '#176abf';


export default function DInput(op) {
    const [measure, setMeasure] = React.useState({ top: 0, left: 0, height: 0, width: 0 });

    const [state, setState] = React.useState({
        search: "",
        onSelection: undefined,
        onChange: undefined,
        list: [],
        orilist: [],
        isObject: false,
        renderItem: undefined,
        open: false,
        scrollToHeight: 0,
        vheight: 0,
        vwidth: 0,
        vpx: 0,
        vpy: 0,
        vfx: 0,
        vfy: 0,
        vx: 0,
        vy: 0,
        nativeEvent: null,
        onBlur: undefined,
        label: 'name',     //search indes
        searchOn: 'name',     //search indes
        keyField: 'id',                      // key
        isMixSearch: false,
        mixSearch: [],
        placeholder: "Search",
        multi: false,
        measured: false,
        multiValue: [],
        focusColor: '#00FFFF',
        position: "bottom"

    })
    const selectorRef = useRef(null)

    const base = useRef(null)

    useEffect(() => {
        setState({
            search: op.value,
            onSelection: op.onSelection,
            onChange: op.onChange,
            list: op.data_list ? op.data_list : [],
            orilist: op.data_list ? [...op.data_list] : [],
            isObject: op?.isObject ? op.isObject : false,
            renderItem: op.renderItem,
            open: false,
            onBlur: op.onBlur,
            scrollToHeight: 0,
            vheight: 0,
            vwidth: 0,
            vpx: 0,
            vpy: 0,
            vfx: 0,
            vfy: 0,
            vx: 0,
            vy: 0,
            nativeEvent: null,
            label: op?.label ? op.label : 'name',     //search indes
            searchOn: op?.searchOn ? op.searchOn : 'name',     //search indes
            keyField: op?.keyField ? op.keyField : 'id',                      // key
            isMixSearch: false,
            mixSearch: [],
            placeholder: op?.placeholder ? op.placeholder : "Search",
            multi: false,
            measured: false,
            multiValue: [],
            focusColor: '#00FFFF',
            position: "bottom"
        })
    }, [op])


    // function measure() {
    //     // this.refs.Marker.measureInWindow((x, y, width, height) => {
    //     //     console.log("x=" + x + ", y=" + y + ", width=" + width + ", hei =" + height + ", pagex=" + pagex + ", pagey=" + pagey)
    //     //     if (x !== state.vx || y !== state.vy) {
    //     //         this.setState(p => ({ ...p, vheight: height, vwidth: width, vx: x, vy: y, position: y > 180 && Global.DEVICE_TYPE == DeviceType.PHONE ? "top" : "bottom", measured: true }));
    //     //     }
    //     // }
    //     // );
    // };
    function doMeasure() {
        // console.log("measured", state.measured)
        if (selectorRef.current && !state.measured) {
            // console.log("measured in")
            selectorRef.current.measureLayout(base.current,
                (left, top, width, height) => {
                    // console.log("measured left=" + left + ", top=" + top + ", width=" + width + ", height=" + height)

                    //                    if (Math.abs(top - measure.top) > 20 || (measure.top == 0 && measure.height == 0 && measure.width == 0 && measure.left == 0)) {
                    setMeasure({ left, top, width, height });
                    setState(p => ({ ...p, measured: true }))
                    //                  }
                }, () => { }
            );
        }
    }

    return (
        <View ref={base} style={{ alignSelf: "stretch" }}>
            {state.position == "top" ?
                row(
                    state, measure,
                    () => { if (state?.onBlur) { state.onBlur() }; setState(p => ({ ...p, open: false })) },
                    state.renderItem,
                    (obj) => { setState(p => ({ ...p, obj })) }) : <></>}

            <View ref={selectorRef}>

                <TextInput
                    value={state.search}
                    style={{ alignSelf: "stretch" }}
                    label={state.placeholder}

                    onChangeText={(value) => {
                        setState(p => ({ ...p, search: value }))
                        let l = []
                        // console.log("value =" + value + ", searchOn = " + state.searchOn)
                        if (state.isObject) {
                            l = state.orilist.filter(e => e[state.searchOn].toLowerCase().includes(value.toLowerCase()))
                            if (state.onChange && l.length == 0) {
                                state.onChange(value)
                            }
                        } else {
                            l = state.orilist.filter(e => e.toLowerCase().includes(value.toLowerCase()))
                            if (state.onChange) {
                                state.onChange(value)
                            }
                        }

                        setState(p => ({ ...p, list: l }))
                    }}

                    onKeyPress={(e) => {
                        if (e.code == "Enter") {
                            let val
                            // console.log("111")
                            if (state.list[0]?.id) {
                                // console.log("222")
                                val = state.list[0]
                                state.onSelection({ item: val });
                                setState(p => ({ ...p, search: val.name, open: false }))
                            } else {
                                // console.log("333")

                                setState(p => ({ ...p, open: false }))

                            }
                            if (state?.onBlur) { state.onBlur() };
                        } else if (e.code == "Esc") {
                            setState(p => ({ ...p, false: false }))
                        } else {
                            setState(p => ({ ...p, false: true }))

                        }
                    }
                    }
                    onBlur={() => {
                        if (state.search){
                            setState(p => ({ ...p, open: false }))
                        }
                    }}
                    onFocus={() => { doMeasure(); setState(p => ({ ...p, open: true })) }} />

            </View>

            {state.position == "bottom" ?
                row(
                    state, measure,
                    () => { if (state?.onBlur) { state.onBlur() }; setState(p => ({ ...p, open: false })) },
                    state.renderItem,
                    (obj) => { setState(p => ({ ...p, obj })) }) : <></>}

        </View >
    );
}

const row = (state, meas, dismiss, render, me) => {
    const sty = { position: "absolute", width: Dimensions.get("window").width, maxHeight: (Global.DEVICE_TYPE == DeviceType.PHONE ? 180 : 250), backgroundColor: Global.THEME.dropwDownBackground }
    const styb = state.position == "bottom" ? { ...sty, top: (meas?.top + meas?.height) } : { ...sty, bottom: 0 }
    const styleh = state.position == "top" ? { height: meas?.height } : {}
    return <FlatList
        style={{ ...styleh, display: state.open ? "flex" : "none" }}
        contentContainerStyle={{ ...styleh, maxHeight: 200 }}
        renderItem={(e) =>
            <TouchableOpacity
                onPress={() => {
                    state.onSelection(e);
                    setTimeout(() => me({ search: state.isObject ? e.item[state.label] : e.item }))
                }}>
                {render({ ...e, dismiss: dismiss })}
            </TouchableOpacity>
        }
        keyExtractor={(e, i) => i.toString()}
        keyboardShouldPersistTaps="always"
        inverted={state.position == "top" ? true : false}
        data={state.list}
    />
}


