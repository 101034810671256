import { collection, doc, getFirestore, setDoc, writeBatch } from 'firebase/firestore';
import { FlatList, ScrollView, StyleSheet } from 'react-native';
import { Button, Chip, FAB, IconButton, TextInput } from 'react-native-paper';
import { Text, View } from '../components/Themed';
import { useEffect, useState } from 'react';
import Toast from 'react-native-root-toast';
import dayjs from 'dayjs';
import Global from '../constants/global';



export default function ChargeForm({ route, navigation }) {
    const [data, setData] = useState(undefined)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        let d = route!.params?.data
        if (d?.frequency == undefined) {
            d = { ...d, frequency: 3 }
        }
        if (d?.selection == undefined) {
            d = { ...d, selection: 0 }
        }
        if (d?.isClosed == undefined) {
            d = { ...d, isClosed: 0 }
        }
        if (d?.apply_rate == undefined) {
            d = { ...d, apply_rate: 0 }
        }
        if (d?.startingFrom == undefined) {
            d = { ...d, startingFrom: 3 }
        }
        setData(d)
    }, [route?.params])

    function save() {
        if (!data.year) {
            Toast.show("Error: year not selected")
            return
        }
        if (!data.rate) {
            Toast.show("Error: Amount cannot be empty")
            return
        }
        try {
            data.rate = parseFloat(data.rate)
            if (!parseFloat(data.rate)) {
                Toast.show("Error: Amount cannot be empty")
                return
            }
            if (!data.remarks) {
                Toast.show("Error: Title cannot be empty")
                return
            }
            setLoading(true)
            setTimeout(() => {
                try {
                    const db = getFirestore()
                    const working = { ...data }
                    let ref
                    if (working?.id) {
                        ref = doc(db, "Charges", working.id)
                    } else {
                        working.stamp = dayjs().unix()
                        ref = doc(collection(db, "Charges"))
                        working.id = ref.id
                    }
                    setDoc(ref, working, { merge: true })
                    Toast.show("Saved")
                    setTimeout(() => {
                        navigation.goBack()
                    }, 1000)
                } catch (e) {
                    Toast.show("Error while saving record : Try later again")
                }
                setLoading(false)
            })

        } catch (e) {

        }
    }
    function applyTo(str: number) {
        return <Chip selected={data?.applyTo?.findIndex(e => e == str) > -1} onPress={() => {
            if (!data?.applyTo) {
                data.applyTo = []
            }
            const dataT = { ...data }
            if (dataT.applyTo){
                dataT.applyTo = [...dataT.applyTo]
            }else{
                dataT.applyTo = []
            }
            const indx = dataT?.applyTo?.findIndex(e => e == str)
            if (indx > -1) {
                dataT?.applyTo?.splice(indx, 1); // 2nd parameter means remove one item only
            } else {
                dataT?.applyTo.push(str)
            }
            setData(e => ({ ...e, applyTo: [...dataT.applyTo] }))
        }}> {Global.type[str]}</Chip >

    }
    return (
        <ScrollView>
            <View style={{ flex: 1, alignSelf: "stretch" }}>
                <ScrollView horizontal>
                    <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                        <Chip selected={data?.year == "2019-20"} onPress={() => setData(p => ({ ...p, year: "2019-20" }))}>2019-20</Chip>
                        <Chip selected={data?.year == "2020-21"} onPress={() => setData(p => ({ ...p, year: "2020-21" }))}>2020-21</Chip>
                        <Chip selected={data?.year == "2021-22"} onPress={() => setData(p => ({ ...p, year: "2021-22" }))}>2021-22</Chip>
                        <Chip selected={data?.year == "2022-23"} onPress={() => setData(p => ({ ...p, year: "2022-23" }))}>2022-23</Chip>
                        <Chip selected={data?.year == "2023-24"} onPress={() => setData(p => ({ ...p, year: "2023-24" }))}>2023-24</Chip>
                        <Chip selected={data?.year == "2024-25"} onPress={() => setData(p => ({ ...p, year: "2024-25" }))}>2024-25</Chip>
                    </View>
                </ScrollView>

                <TextInput
                    placeholder="Charge Title"
                    style={{ alignSelf: "stretch", }}
                    value={data?.remarks ? data.remarks : ""}
                    onChangeText={(text) => setData(p => ({ ...p, remarks: text }))} />

                <Text style={{ fontWeight: "bold" }}>Criteria</Text>
                <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                    <ScrollView horizontal>
                        {
                            Global.select.map((e, i) => {
                                return <Chip selected={data?.selection == i} onPress={() => setData(p => ({ ...p, selection: i }))}>{e}</Chip>
                            })
                        }
                    </ScrollView>
                </View>
                {
                    data?.selection == 0 || data?.selection == 1 || data?.selection == 10 || data?.selection == 11 || data?.selection == 12 || data?.selection == 13 || data?.selection == 14 || data?.selection == 15 || data?.selection == 16 || data?.selection == 17 ? <></> :
                        <TextInput
                            label={data?.selection == 2 ? `floor` : (data?.selection == 3 || data?.selection == 4 || data?.selection == 5 ? `Sq.ft.` : data?.selection == 1 ? "Office No" : `₹`)}
                            style={{ alignSelf: "stretch", }}
                            value={data?.input ? data.input + "" : ""}
                            onChangeText={(t) => {
                                if (data.selection == 1) {
                                    setData(p => ({ ...p, input: t }))
                                } else {
                                    const v = t.replace(/[^0-9.-]/g, '')
                                    // let vn = 0
                                    // if (v) {
                                    //     vn = parseFloat(v)
                                    // }
                                    setData(p => ({ ...p, input: v }))
                                }
                            }} />

                }

                {((data?.selection >= 2 && data?.selection <= 13) || data?.selection == 19) &&
                    <View><Text style={{ fontWeight: "bold" }}>Apply To Property Type :</Text>
                        <ScrollView horizontal>
                            <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                                {Global.type.map((e, i) => applyTo(i))}
                            </View>
                        </ScrollView>
                    </View>
                }

                <Text style={{ fontWeight: "bold" }}>Applied To :</Text>
                <ScrollView horizontal>
                    <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                        <Chip selected={data?.isClosed == 0} onPress={() => setData(p => ({ ...p, isClosed: 0 }))}>All</Chip>
                        <Chip selected={data?.isClosed == 1} onPress={() => setData(p => ({ ...p, isClosed: 1 }))}>Open</Chip>
                        <Chip selected={data?.isClosed == 2} onPress={() => setData(p => ({ ...p, isClosed: 2 }))}>Closed</Chip>
                    </View>
                </ScrollView>
                <Text style={{ fontWeight: "bold" }}>Rate type :</Text>
                <ScrollView horizontal>
                    <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                        <Chip selected={data?.apply_rate == 0} onPress={() => setData(p => ({ ...p, apply_rate: 0 }))}>Per Sq. Ft</Chip>
                        <Chip selected={data?.apply_rate == 1} onPress={() => setData(p => ({ ...p, apply_rate: 1 }))}>Flat Rs</Chip>
                    </View>
                </ScrollView>

                <TextInput
                    label={`Rate ₹`}
                    style={{ alignSelf: "stretch", }}
                    value={data?.rate ? data.rate : ""}
                    onChangeText={(t) => {
                        const v = t.replace(/[^0-9.]/g, '')
                        const d2 = v.lastIndexOf(".")
                        const d1 = v.indexOf(".")
                        console.log("LI =" + d1, "L2=" + d2)
                        if (d1 !== d2 && d1 !== -1 && d2 !== -1) {
                            const v2 = v.substring(d2 + 1)
                            const v3 = v.replace("." + v2, v2)
                            console.log("inside " + v2 + "=", v3)
                            setData(p => ({ ...p, rate: v3 }))
                        } else {
                            setData(p => ({ ...p, rate: v }))
                        }

                    }} />


                <Text style={{ fontWeight: "bold" }}>Frequency :</Text>
                <ScrollView horizontal>
                    <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                        <Chip selected={data?.frequency == 0} onPress={() => setData(p => ({ ...p, frequency: 0 }))}>Monthly</Chip>
                        <Chip selected={data?.frequency == 1} onPress={() => setData(p => ({ ...p, frequency: 1 }))}>Quaterly</Chip>
                        <Chip selected={data?.frequency == 2} onPress={() => setData(p => ({ ...p, frequency: 2 }))}>Half Yearly</Chip>
                        <Chip selected={data?.frequency == 3} onPress={() => setData(p => ({ ...p, frequency: 3 }))}>Yearly</Chip>
                    </View>
                </ScrollView>

                <Text style={{ fontWeight: "bold" }}>Starting From :</Text>
                <ScrollView horizontal>
                    <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                        <Chip selected={data?.startingFrom == -1} onPress={() => setData(p => ({ ...p, startingFrom: -1 }))}>None</Chip>
                        <Chip selected={data?.startingFrom == 3} onPress={() => setData(p => ({ ...p, startingFrom: 3 }))}>Apr</Chip>
                        <Chip selected={data?.startingFrom == 4} onPress={() => setData(p => ({ ...p, startingFrom: 4 }))}>May</Chip>
                        <Chip selected={data?.startingFrom == 5} onPress={() => setData(p => ({ ...p, startingFrom: 5 }))}>Jun</Chip>
                        <Chip selected={data?.startingFrom == 6} onPress={() => setData(p => ({ ...p, startingFrom: 6 }))}>Jul</Chip>
                        <Chip selected={data?.startingFrom == 7} onPress={() => setData(p => ({ ...p, startingFrom: 7 }))}>Aug</Chip>
                        <Chip selected={data?.startingFrom == 8} onPress={() => setData(p => ({ ...p, startingFrom: 8 }))}>Sep</Chip>
                        <Chip selected={data?.startingFrom == 9} onPress={() => setData(p => ({ ...p, startingFrom: 9 }))}>Oct</Chip>
                        <Chip selected={data?.startingFrom == 10} onPress={() => setData(p => ({ ...p, startingFrom: 10 }))}>Nov</Chip>
                        <Chip selected={data?.startingFrom == 11} onPress={() => setData(p => ({ ...p, startingFrom: 11 }))}>Dec</Chip>
                        <Chip selected={data?.startingFrom == 0} onPress={() => setData(p => ({ ...p, startingFrom: 0 }))}>Jan</Chip>
                        <Chip selected={data?.startingFrom == 1} onPress={() => setData(p => ({ ...p, startingFrom: 1 }))}>Feb</Chip>
                        <Chip selected={data?.startingFrom == 2} onPress={() => setData(p => ({ ...p, startingFrom: 2 }))}>Mar</Chip>
                    </View>
                </ScrollView>

                <TextInput
                    placeholder="Description"
                    style={{ alignSelf: "stretch", }}
                    value={data?.description ? data.description : ""}
                    onChangeText={(text) => setData(p => ({ ...p, description: text }))} />

                <View style={{ flexDirection: "row", paddingTop: 20 }}>
                    <Button style={{ flex: 1 }} onPress={() => navigation.goBack()}>Back</Button>
                    <Button loading={loading} mode="contained" style={{ flex: 1 }} onPress={() => save()}>Save</Button>
                </View>
            </View>
        </ScrollView>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
