import { useEffect, useState } from "react";
import { Text } from "react-native";
import { View } from "../components/Themed";
import { RootStackScreenProps } from "../types";
// import { MessageSendOptions, MessageMedia, List, Buttons, Client, LocalAuth } from 'whatsapp-web.js'
// const wppconnect = require('@wppconnect-team/wppconnect');

// async function start() {

//     client.onMessage(msg => {
//         console.log(msg.from, msg.body)
//         if (msg.body == "Hiii") {
//             client.sendText(msg.from, "How r you Tushti Today ?")
//         }
//         if (msg.body == "image") {
//             client.sendImage("918866844411@c.us", "https://5.imimg.com/data5/QP/VA/GR/SELLER-53478227/baby-laughing-buddha-colorful-cute-child-monk-reading-book-statue-marble-finish-handcrafted.jpg", "buddha.jpg", "Buddha")
//         }
//         if (msg.body == "america") {
//             client.sendText(msg.from, "https://america.com")
//         }
//         if (msg.body == "darshan") {
//             client.sendText(msg.from, "how are you Darshan kumar ?")
//         }

//     })
// }


export default function WhatsApp({ route, navigation }: RootStackScreenProps<'WhatsApp'>) {
    const [qr, setQr] = useState("")
    const [initialized, setInitialized] = useState(false)
    const [authenticated, setAuthenticated] = useState(false)
    async function connect() {
        // const client = new Client({
        //     authStrategy: new LocalAuth({ clientId: "client-two" })
        // });
        // client.on('qr', (qrr) => {
        //     setQr(qrr)
        // });
        // client.initialize();

        // client.on('message', async (msg) => {
        //     if (msg.body == 'test') {
        //         console.log('ponging');
        //         const media = await MessageMedia.fromUrl('https://gtech.co.in/res.pdf', { filename: "receipt" });
        //         client.sendMessage("919428692236@c.us", media)
        //     }
        // });
        // setInitialized(true)
    }
    useEffect(() => {
        if (!initialized) {
            connect()
        }
    }, [initialized])

    return <View>
        {/* <Text>{authenticated ? "Authenticated" : "Not-Connected"}</Text>
        <QRCode value={qr} /> */}
    </View>
};