import { collection, doc, FieldValue, getDoc, getFirestore, increment, setDoc, writeBatch } from 'firebase/firestore';
import { FlatList, ScrollView, StyleSheet } from 'react-native';
import { Text, Button, Chip, FAB, IconButton, TextInput } from 'react-native-paper';
import { View } from '../components/Themed';
import { useEffect, useRef, useState } from 'react';
import Toast from 'react-native-root-toast';
import dayjs from 'dayjs';
import Global from '../constants/global';
import DInput from '../components/DInput';
import { useSelector } from 'react-redux';
import DCalendar from '../components/DCalendar';



export default function PaymentForm({ route, navigation }) {
    const [data, setData] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [userList, setUList] = useState(false)
    const ulist = useSelector(state => state.list.Users)

    useEffect(() => {
        const f = ulist.filter(e => !e.dele)
        setUList(f)
    }, [ulist])

    useEffect(() => {
        const data = route?.params?.data
        if (data?.date) {
            data.date = dayjs().unix()
        }
        setData(route?.params?.data)
    }, [route?.params])

    function save() {
        if (!data.paidBy) {
            Toast.show("Error: Paid By not selected")
            return
        }
        if (!data.amount) {
            Toast.show("Error: Amount cannot be empty")
            return
        }
        if (!data.year) {
            Toast.show("Error: Year Not selected")
            return
        }
        if (!data.date) {
            Toast.show("Error: Date not selected")
            return
        }
        setLoading(true)
        setTimeout(async () => {
            try {
                const db = getFirestore()
                const working = { ...data }
                const batch = writeBatch(db)
                let ref
                working.stamp = dayjs().unix()
                if (working?.id) {
                    ref = doc(db, "Payments", working.id)
                    console.log("editing", working.id)
                } else {
                    ref = doc(collection(db, "Payments"))
                    working.id = ref.id
                    console.log("new paykment", working.id)
                    if (working.paymentMode == 1) {
                        working.status == 11
                    } else {
                        working.status = 0
                    }
                    console.log("status", working.status)
                    const autoNo = doc(db, "AutoNos", "AutoNo")
                    const docSnap = await getDoc(autoNo);
                    const ano = docSnap.data()
                    working.receiptNo = "Receipt/" + ano.Receipt + ano.Suffix
                    batch.set(autoNo, { ...ano, Receipt: increment(1) }, { merge: true })

                    // if (working.paymentMode == 1) {
                    //     working.receiptNo = "Cash/" + ano.Receipt
                    //     batch.set(autoNo, { "Cash": increment(1) }, { merge: true })
                    // } else if (working.paymentMode == 2) {
                    //     working.receiptNo = "Cheque/" + ano.Cheque
                    //     batch.set(autoNo, { "Cheque": increment(1) }, { merge: true })
                    // } else if (working.paymentMode == 3) {
                    //     working.receiptNo = "Online/" + ano.Online
                    //     batch.set(autoNo, { "Online": increment(1) }, { merge: true })
                    // } else if (working.paymentMode == 4) {
                    //     working.receiptNo = "DD/" + ano.DD
                    //     batch.set(autoNo, { "DD": increment(1) }, { merge: true })
                    // }
                    // console.log("receipt no", working.receiptNo)
                }
                batch.set(ref, working, { merge: true })
                batch.commit()
                Toast.show("Saved")
                setTimeout(() => {
                    navigation.goBack()
                }, 1000)

            } catch (e) {
                Toast.show("Error while saving record : Try later again")
            }
            setLoading(false)
        })
    }
    const aref = useRef()
    return (
        <ScrollView>
            <View style={{ flex: 1, alignSelf: "stretch" }}>
                <Text style={{ padding: "10" }} variant="headlineSmall" style={{ padding: 10 }}>{data?.paymentMode == 1 ? "Cash" : data?.paymentMode == 2 ? "Cheque" : data?.paymentMode == 3 ? "Online" : "DD"}</Text>

                <ScrollView horizontal>
                    <View style={{ marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
                        <Chip selected={data?.year == "2019-20"} onPress={() => setData(p => ({ ...p, year: "2019-20" }))}>2019-20</Chip>
                        <Chip selected={data?.year == "2020-21"} onPress={() => setData(p => ({ ...p, year: "2020-21" }))}>2020-21</Chip>
                        <Chip selected={data?.year == "2021-22"} onPress={() => setData(p => ({ ...p, year: "2021-22" }))}>2021-22</Chip>
                        <Chip selected={data?.year == "2022-23"} onPress={() => setData(p => ({ ...p, year: "2022-23" }))}>2022-23</Chip>
                        <Chip selected={data?.year == "2023-24"} onPress={() => setData(p => ({ ...p, year: "2023-24" }))}>2023-24</Chip>
                        <Chip selected={data?.year == "2024-25"} onPress={() => setData(p => ({ ...p, year: "2024-25" }))}>2024-25</Chip>
                    </View>
                </ScrollView>

                {userList && data &&
                    <DInput
                        data_list={userList}
                        isObject={true}
                        searchOn={"name"}
                        key={data?.paidBy}
                        label={"name"}
                        value={data?.paidByName}
                        placeholder={"Payment From"}
                        onSelection={(i) => {
                            setData(p => ({ ...p, paidBy: i.item.id, paidByName: i.item.name }))
                            aref?.current?.focus()
                        }}
                        onBlur={() => {
                            aref?.current?.focus()
                        }}
                        renderItem={(i) =>
                            <Text style={{ padding: 10 }}>{i.item.name}</Text>
                        }
                    />
                }
                <TextInput
                    label={`Amount ₹`}
                    ref={aref}
                    style={{ alignSelf: "stretch", }}
                    value={data?.amount ? data.amount : ""}
                    onChangeText={(t) => {
                        const v = t.replace(/[^0-9.]/g, '')
                        let vn = 0
                        if (v) {
                            vn = parseFloat(v)
                        }
                        setData(p => ({ ...p, amount: vn }))
                    }} />
                <DCalendar
                    label={"Date"}
                    current={data?.date ? data.date : dayjs().unix()}
                    callback={(stamp: number, isValid: boolean, obj: any) => {
                        if (isValid) {
                            setData(prev => ({ ...prev, date: stamp }))
                        }
                    }} />

                <TextInput
                    label={`Remarks`}
                    style={{ alignSelf: "stretch", }}
                    value={data?.remarks ? data.remarks : ""}
                    onChangeText={(t) => {
                        setData(p => ({ ...p, remarks: t }))
                    }} />
                <Text style={{ padding: 10 }}>{data?.status == 11 ? " Payment Status : Successfull" : ""}</Text>


                <View style={{ flexDirection: "row", paddingTop: 20 }}>
                    <Button style={{ flex: 1 }} onPress={() => navigation.goBack()}>Back</Button>
                    <Button loading={loading} mode="contained" style={{ flex: 1 }} onPress={() => save()}>Save</Button>
                </View>
            </View>
        </ScrollView>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
