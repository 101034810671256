
//    "react-native-paper": "^5.0.0-rc.5",
//  "main": "node_modules/expo/AppEntry.js",

//DONE DEPOSIT FROM YEAR
// VERIFIED NOT VERIFIED TAG PROPERTY YEARWISE
//DONE  CHARGES APPLIED LIST
//DONE SINGLE PROPERTY APPLY CHARGE
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { AppRegistry, Platform } from "react-native"
// import 'expo-firestore-offline-persistence'
import { initializeApp } from "firebase/app";
import { enableIndexedDbPersistence, initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import store from './task/store';
import { Provider, useDispatch, useSelector } from 'react-redux'
import { MD3LightTheme as DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import Global from './constants/global';
import DTheme from './constants/theme';
import CoreStatement from './components/CoreStatement';
import Receipt from './components/Receipt';

try {
  const firebaseConfig = {
    apiKey: "AIzaSyBJLBl6uJwgIGpaaPT-WwGb4P68x1boRmA",
    authDomain: "ashwameghta.firebaseapp.com",
    projectId: "ashwameghta",
    storageBucket: "ashwameghta.appspot.com",
    messagingSenderId: "752804400570",
    appId: "1:752804400570:web:54be7560747119c4afa183",
    measurementId: "G-C7ZD178QCK"
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const firestoreDb = initializeFirestore(app, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
  });


  enableIndexedDbPersistence(firestoreDb)
    .catch((err) => {
      if (err.code == 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code == 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
  //const storage = getStorage(app);
} catch (e) { console.log("startup error", e) }


export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  Global.THEME = DTheme.sherine[colorScheme == "dark" ? 1 : 0]
  const theme = {
    ...DefaultTheme,
    roundness: 2,
    version: 3,
    colors: {
      ...DefaultTheme, ...Global.THEME, elevation: {
        level0: "0",
        level1: "1",
        level2: "2",
        level3: "3",
        level4: "4",
        level5: "5",
      }
    }


  };

  let param = ""

  if (Platform.OS == "web" || Platform.OS == "windows") {
    try {
      const sr = location.href.indexOf("/stat/")
      if (sr > -1) {
        param = location.href.substring(sr + 6)
      }
    } catch (e) { }
  }
  let rece = ""

  if (Platform.OS == "web" || Platform.OS == "windows") {
    try {
      const sr = location.href.indexOf("/rec/")
      if (sr > -1) {
        rece = location.href.substring(sr + 5)
      }
    } catch (e) { }
  }

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <PaperProvider theme={theme}>
          <SafeAreaProvider>
            {param && <CoreStatement user={undefined} pno={undefined} party={param} navigation={undefined} />}
            {rece && <Receipt rece={rece} />}
            {!rece && !param && <Navigation colorScheme={colorScheme} />}
            <StatusBar />
          </SafeAreaProvider>
        </PaperProvider>
      </Provider>
    );
  }
}

// AppRegistry.registerComponent("ashwamegh", () => App);

