import { collection, deleteDoc, doc, getFirestore, setDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import { Button, Text, TextInput } from "react-native-paper"
import Toast from "react-native-root-toast"
import Global from "../constants/global"
import { View } from "./Themed"

export default function EntryOptions(op) {
    const [entry, setEntry] = useState(undefined)

    useEffect(() => {
        setEntry(op.detail)
    }, [op])


    function saveEntry() {
        const tosave = { ...entry }
        tosave.edited = true
        const db = getFirestore()
        const ref = doc(db, "Entries", tosave.id)
        setDoc(ref, tosave, { merge: true })
        Toast.show("Entry Updated")
    }

    function DeleteEntry() {
        const tosave = { ...entry }
        const db = getFirestore()
        const ref = doc(db, "Entries", tosave.id)
        deleteDoc(ref)
        Toast.show("Entry Delete")
    }
    function saveClone() {
        const tosave = { ...entry }
        const db = getFirestore()
        const ref = doc(collection(db, "Entries"));
        tosave.headId = ""
        tosave.id = ref.id
        tosave.edited = true
        setDoc(ref, tosave, { merge: true })
        Toast.show("New Entry Created")
    }

    return <View style={{ justifyContent: "center", backgroundColor: Global.THEME.surfaceVariant }}>
        <Text variant='headlineSmall'>Set Accounting Entry</Text>
        <Text>Property : {entry?.property}</Text>
        <Text>Side : {entry?.side == 0 ? "DR" : "CR"}</Text>
        <TextInput
            value={entry?.headName}
            onChangeText={(t) =>
                setEntry(p => ({ ...p, headName: t }))}
            label={"Head Name"} />

        <TextInput
            value={entry?.remarks}
            onChangeText={(t) =>
                setEntry(p => ({ ...p, remarks: t }))}
            label={"Remarks"} />

        <TextInput
            value={entry?.amount}
            onChangeText={(t) => {
                const v = t.replace(/[^0-9.-]/g, '')
                let vn = 0
                if (v) {
                    vn = parseFloat(v)
                }
                setEntry(p => ({ ...p, amount: vn }))
            }}
            label={"New Value"} />

        <TextInput
            value={(entry?.note ? entry?.note : "")}
            onChangeText={(t) => setEntry(p => ({ ...p, note: t }))}
            label={"Special Note for internal use"}
        />
        <Text>Note : You should refresh balance after saving </Text>
        <View style={{ flexDirection: "row", padding: 10, backgroundColor: "transparent" }}>
            <Button style={{ flex: 1 }} onPress={() => op.close()}>Cancel</Button>
            <Button style={{ flex: 1 }} onPress={() => { saveEntry(); op.close() }}>Save</Button>
            <Button style={{ flex: 1 }} onPress={() => { saveClone(); op.close() }}>Save as New</Button>
            <Button style={{ flex: 1 }} onPress={() => { DeleteEntry(); op.close() }}>Delete</Button>
        </View>
    </View>


}