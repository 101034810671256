export default class DTheme {

    public static RED_LIGHT = {
        primary: "#B40082",
        onPrimary: "#FFFFFF",
        primaryContainer: "#FFD8E9",
        onPrimaryContainer: "#3C0029",
        secondary: "#824E6A",
        onSecondary: "#FFFFFF",
        secondaryContainer: "#FFD8E9",
        onSecondaryContainer: "#340C25",
        tertiary: "#954914",
        onTertiary: "#FFFFFF",
        tertiaryContainer: "#FFDBC9",
        onTertiaryContainer: "#321200",
        error: "#BA1A1A",
        onError: "#FFFFFF",
        errorContainer: "#FFDAD6",
        onErrorContainer: "#410002",
        background: "#FFFBFF",
        onBackground: "#1F1A1C",
        surface: "#FFFBFF",
        onSurface: "#1F1A1C",
        surfaceVariant: "#F0DEE4",
        onSurfaceVariant: "#504349",
        outline: "#827379",
        shadow: "#000000",
        inverseSurface: "#352F31",
        inverseOnSurface: "#F9EEF1",
        inversePrimary: "#FFAFD8",
        surfaceTint: "#B40082",
        surfaceTintColor: "#B40082"
    }

    public static RED_DARK = {
        primary: "#FFAFD8",
        onPrimary: "#610045",
        primaryContainer: "#890062",
        onPrimaryContainer: "#FFD8E9",
        secondary: "#F4B4D4",
        onSecondary: "#4D213B",
        secondaryContainer: "#673752",
        onSecondaryContainer: "#FFD8E9",
        tertiary: "#FFB68D",
        onTertiary: "#532200",
        tertiaryContainer: "#763300",
        onTertiaryContainer: "#FFDBC9",
        error: "#FFB4AB",
        onError: "#690005",
        errorContainer: "#93000A",
        onErrorContainer: "#FFB4AB",
        background: "#1F1A1C",
        onBackground: "#EBE0E2",
        surface: "#1F1A1C",
        onSurface: "#EBE0E2",
        surfaceVariant: "#504349",
        onSurfaceVariant: "#D4C2C8",
        outline: "#9C8D93",
        shadow: "#000000",
        inverseSurface: "#EBE0E2",
        inverseOnSurface: "#352F31",
        inversePrimary: "#B40082",
        surfaceTint: "#FFAFD8",
        surfaceTintColor: "#FFAFD8"
    }


    public static blue_light = {
        primary: "#B40082",
        onPrimary: "#FFFFFF",
        primaryContainer: "#FFD8E9",
        onPrimaryContainer: "#3C0029",
        secondary: "#824E6A",
        onSecondary: "#FFFFFF",
        secondaryContainer: "#FFD8E9",
        onSecondaryContainer: "#340C25",
        tertiary: "#954914",
        onTertiary: "#FFFFFF",
        tertiaryContainer: "#FFDBC9",
        onTertiaryContainer: "#321200",
        error: "#BA1A1A",
        onError: "#FFFFFF",
        errorContainer: "#FFDAD6",
        onErrorContainer: "#410002",
        background: "#FFFBFF",
        onBackground: "#1F1A1C",
        surface: "#FFFBFF",
        onSurface: "#1F1A1C",
        surfaceVariant: "#F0DEE4",
        onSurfaceVariant: "#504349",
        outline: "#827379",
        shadow: "#000000",
        inverseSurface: "#352F31",
        inverseOnSurface: "#F9EEF1",
        inversePrimary: "#FFAFD8",
        surfaceTint: "#B40082",
        surfaceTintColor: "#B40082"
    }

    public static BLUE_DARK = {
        primary: "#FFAFD8",
        onPrimary: "#610045",
        primaryContainer: "#890062",
        onPrimaryContainer: "#FFD8E9",
        secondary: "#F4B4D4",
        onSecondary: "#4D213B",
        secondaryContainer: "#673752",
        onSecondaryContainer: "#FFD8E9",
        tertiary: "#FFB68D",
        onTertiary: "#532200",
        tertiaryContainer: "#763300",
        onTertiaryContainer: "#FFDBC9",
        error: "#FFB4AB",
        onError: "#690005",
        errorContainer: "#93000A",
        onErrorContainer: "#FFB4AB",
        background: "#1F1A1C",
        onBackground: "#EBE0E2",
        surface: "#1F1A1C",
        onSurface: "#EBE0E2",
        surfaceVariant: "#504349",
        onSurfaceVariant: "#D4C2C8",
        outline: "#9C8D93",
        shadow: "#000000",
        inverseSurface: "#EBE0E2",
        inverseOnSurface: "#352F31",
        inversePrimary: "#B40082",
        surfaceTint: "#FFAFD8",
        surfaceTintColor: "#FFAFD8",
    }

    public static MAROON_LIGHT: {
        primary: "#B40082",
        onPrimary: "#FFFFFF",
        primaryContainer: "#FFD8E9",
        onPrimaryContainer: "#3C0029",
        secondary: "#824E6A",
        onSecondary: "#FFFFFF",
        secondaryContainer: "#FFD8E9",
        onSecondaryContainer: "#340C25",
        tertiary: "#954914",
        onTertiary: "#FFFFFF",
        tertiaryContainer: "#FFDBC9",
        onTertiaryContainer: "#321200",
        error: "#BA1A1A",
        onError: "#FFFFFF",
        errorContainer: "#FFDAD6",
        onErrorContainer: "#410002",
        background: "#FFFBFF",
        onBackground: "#1F1A1C",
        surface: "#FFFBFF",
        onSurface: "#1F1A1C",
        surfaceVariant: "#F0DEE4",
        onSurfaceVariant: "#504349",
        outline: "#827379",
        shadow: "#000000",
        inverseSurface: "#352F31",
        inverseOnSurface: "#F9EEF1",
        inversePrimary: "#FFAFD8",
        surfaceTint: "#B40082",
        surfaceTintColor: "#B40082"
    }
    public static MAROON_DARK = {
        primary: "#FFAFD8",
        onPrimary: "#610045",
        primaryContainer: "#890062",
        onPrimaryContainer: "#FFD8E9",
        secondary: "#F4B4D4",
        onSecondary: "#4D213B",
        secondaryContainer: "#673752",
        onSecondaryContainer: "#FFD8E9",
        tertiary: "#FFB68D",
        onTertiary: "#532200",
        tertiaryContainer: "#763300",
        onTertiaryContainer: "#FFDBC9",
        error: "#FFB4AB",
        onError: "#690005",
        errorContainer: "#93000A",
        onErrorContainer: "#FFB4AB",
        background: "#1F1A1C",
        onBackground: "#EBE0E2",
        surface: "#1F1A1C",
        onSurface: "#EBE0E2",
        surfaceVariant: "#504349",
        onSurfaceVariant: "#D4C2C8",
        outline: "#9C8D93",
        shadow: "#000000",
        inverseSurface: "#EBE0E2",
        inverseOnSurface: "#352F31",
        inversePrimary: "#B40082",
        surfaceTint: "#FFAFD8",
        surfaceTintColor: "#FFAFD8",
    }

    public static EMBER_LIGHT = {
        primary: "#466733",
        onPrimary: "#FFFFFF",
        primaryContainer: "#C7EEAC",
        onPrimaryContainer: "#082100",
        secondary: "#586151",
        onSecondary: "#FFFFFF",
        secondaryContainer: "#DDE6D1",
        onSecondaryContainer: "#161E11",
        tertiary: "#476364",
        onTertiary: "#FFFFFF",
        tertiaryContainer: "#C9E9E8",
        onTertiaryContainer: "#012020",
        error: "#BA1A1A",
        onError: "#FFFFFF",
        errorContainer: "#FFDAD6",
        onErrorContainer: "#410002",
        background: "#FEFCF7",
        onBackground: "#1B1C19",
        surface: "#FEFCF7",
        onSurface: "#1B1C19",
        surfaceVariant: "#E1E3D9",
        onSurfaceVariant: "#454841",
        outline: "#757870",
        shadow: "#000000",
        inverseSurface: "#30312D",
        inverseOnSurface: "#F2F1EB",
        inversePrimary: "#ABD292",
        surfaceTint: "#466733",
        surfaceTintColor: "#466733"
    }
    public static EMBER_DARK = {
        primary: "#ABD292",
        onPrimary: "#193708",
        primaryContainer: "#2F4F1D",
        onPrimaryContainer: "#C7EEAC",
        secondary: "#C1CAB6",
        onSecondary: "#2B3325",
        secondaryContainer: "#41493A",
        onSecondaryContainer: "#DDE6D1",
        tertiary: "#ADCCCC",
        onTertiary: "#183535",
        tertiaryContainer: "#2F4C4C",
        onTertiaryContainer: "#C9E9E8",
        error: "#FFB4AB",
        onError: "#690005",
        errorContainer: "#93000A",
        onErrorContainer: "#FFB4AB",
        background: "#1B1C19",
        onBackground: "#E4E2DD",
        surface: "#1B1C19",
        onSurface: "#E4E2DD",
        surfaceVariant: "#454841",
        onSurfaceVariant: "#C5C8BE",
        outline: "#8F9289",
        shadow: "#000000",
        inverseSurface: "#E4E2DD",
        inverseOnSurface: "#30312D",
        inversePrimary: "#466733",
        surfaceTint: "#ABD292",
        surfaceTintColor: "#ABD292"
    }

    public static PEACH_LIGHT = {
        primary: "#B40082",
        onPrimary: "#FFFFFF",
        primaryContainer: "#FFD8E9",
        onPrimaryContainer: "#3C0029",
        secondary: "#824E6A",
        onSecondary: "#FFFFFF",
        secondaryContainer: "#FFD8E9",
        onSecondaryContainer: "#340C25",
        tertiary: "#954914",
        onTertiary: "#FFFFFF",
        tertiaryContainer: "#FFDBC9",
        onTertiaryContainer: "#321200",
        error: "#BA1A1A",
        onError: "#FFFFFF",
        errorContainer: "#FFDAD6",
        onErrorContainer: "#410002",
        background: "#FFFBFF",
        onBackground: "#1F1A1C",
        surface: "#FFFBFF",
        onSurface: "#1F1A1C",
        surfaceVariant: "#F0DEE4",
        onSurfaceVariant: "#504349",
        outline: "#827379",
        shadow: "#000000",
        inverseSurface: "#352F31",
        inverseOnSurface: "#F9EEF1",
        inversePrimary: "#FFAFD8",
        surfaceTint: "#B40082",
        surfaceTintColor: "#B40082"
    }
    public static PEACH_DARK = {

        primary: "#FFAFD8",
        onPrimary: "#610045",
        primaryContainer: "#890062",
        onPrimaryContainer: "#FFD8E9",
        secondary: "#F4B4D4",
        onSecondary: "#4D213B",
        secondaryContainer: "#673752",
        onSecondaryContainer: "#FFD8E9",
        tertiary: "#FFB68D",
        onTertiary: "#532200",
        tertiaryContainer: "#763300",
        onTertiaryContainer: "#FFDBC9",
        error: "#FFB4AB",
        onError: "#690005",
        errorContainer: "#93000A",
        onErrorContainer: "#FFB4AB",
        background: "#1F1A1C",
        onBackground: "#EBE0E2",
        surface: "#1F1A1C",
        onSurface: "#EBE0E2",
        surfaceVariant: "#504349",
        onSurfaceVariant: "#D4C2C8",
        outline: "#9C8D93",
        shadow: "#000000",
        inverseSurface: "#EBE0E2",
        inverseOnSurface: "#352F31",
        inversePrimary: "#B40082",
        surfaceTint: "#FFAFD8",
        surfaceTintColor: "#FFAFD8"
    }
    /*
            primary: "#006e1b",
            accent: "#84967f",
            text: "#FFFFFF",
            background: "#fcfdf6",
            paper: "#d5e8ce",
            placeholder :"#002204"
    
    */
    public static GREEN_LIGHT = {
        primary: "#036D37",
        onPrimary: "#FFFFFF",
        primaryContainer: "#9CF6B2",
        onPrimaryContainer: "#00210C",
        secondary: "#506352",
        onSecondary: "#FFFFFF",
        secondaryContainer: "#D2E8D3",
        onSecondaryContainer: "#0E1F12",
        tertiary: "#3A656E",
        onTertiary: "#FFFFFF",
        tertiaryContainer: "#BDEAF5",
        onTertiaryContainer: "#001F25",
        error: "#BA1A1A",
        errorContainer: "#FFDAD6",
        onError: "#FFFFFF",
        onErrorContainer: "#410002",
        background: "#f5faeb",
        onBackground: "#191C19",
        surface: "#f5faeb",
        onSurface: "#191C19",
        surfaceVariant: "#DDE5DA",
        onSurfaceVariant: "#414941",
        outline: "#717971",
        inverseOnSurface: "#F0F1EC",
        inverseSurface: "#2E312E",
        inversePrimary: "#80D998",
        shadow: "#000000",
        surfaceTint: "#036D37",
        surfaceTintColor: "#036D37"
    }

    public static GREEN_DARK = {
        primary: "#80D998",
        onPrimary: "#00391A",
        primaryContainer: "#005228",
        onPrimaryContainer: "#9CF6B2",

        secondary: "#B7CCB8",
        onSecondary: "#223526",
        secondaryContainer: "#384B3C",
        onSecondaryContainer: "#D2E8D3",

        tertiary: "#A2CED9",
        onTertiary: "#01363F",
        tertiaryContainer: "#204D56",
        onTertiaryContainer: "#BDEAF5",

        error: "#FFB4AB",
        errorContainer: "#93000A",
        onError: "#690005",
        onErrorContainer: "#FFDAD6",

        background: "#191C19",
        onBackground: "#E2E3DE",
        surface: "#191C19",
        onSurface: "#E2E3DE",
        surfaceVariant: "#414941",
        onSurfaceVariant: "#C1C9BF",

        outline: "#8B938A",
        inverseOnSurface: "#191C19",
        inverseSurface: "#E2E3DE",
        inversePrimary: "#036D37",
        shadow: "#000000",
        surfaceTint: "#80D998",
        surfaceTintColor: "#80D998",
    }
    public static MY_LIGHT = {
        primary: "#ffa000",
        background: "#e0f7fa",
        surface: "#fff8e1",

        accent: "#49c9db",
        error: "#ba1a1a",
        text: "#000000",

        onSurface: "#000000",
        disabled: "gray",
        placeholder: "#fbbd00",
        backdrop: "darkgray",
        notification: "#fbbd00",

    }

    public static MY_LIGHT1 = {
        primary: "#006685",
        onPrimary: "#FFFFFF",
        primaryContainer: "#BFE8FF",
        onPrimaryContainer: "#001F2A",
        secondary: "#8B5000",
        onSecondary: "#FFFFFF",
        secondaryContainer: "#FFDCBF",
        onSecondaryContainer: "#2D1600",
        tertiary: "#5E5A7D",
        onTertiary: "#FFFFFF",
        tertiaryContainer: "#E4DFFF",
        onTertiaryContainer: "#1A1836",
        error: "#BA1A1A",
        errorContainer: "#FFDAD6",
        onError: "#FFFFFF",
        onErrorContainer: "#410002",
        background: "#FBFCFE",
        onBackground: "#191C1E",
        surface: "#FBFCFE",
        onSurface: "#191C1E",
        surfaceVariant: "#DCE3E9",
        onSurfaceVariant: "#40484C",
        outline: "#70787D",
        inverseOnSurface: "#F0F1F3",
        inverseSurface: "#2E3133",
        inversePrimary: "#6DD2FF",
        shadow: "#000000",
        surfaceTint: "#006685",
        surfaceTintColor: "#006685"
    }


    public static MY_DARK1 = {
        primary: "#6DD2FF",
        onPrimary: "#003547",
        primaryContainer: "#004D65",
        onPrimaryContainer: "#BFE8FF",
        secondary: "#FFB872",
        onSecondary: "#4A2800",
        secondaryContainer: "#6A3B00",
        onSecondaryContainer: "#FFDCBF",
        tertiary: "#C7C2EA",
        onTertiary: "#2F2D4C",
        tertiaryContainer: "#464364",
        onTertiaryContainer: "#E4DFFF",
        error: "#FFB4AB",
        errorContainer: "#93000A",
        onError: "#690005",
        onErrorContainer: "#FFDAD6",
        background: "#191C1E",
        onBackground: "#E1E2E5",
        surface: "#191C1E",
        onSurface: "#E1E2E5",
        surfaceVariant: "#40484C",
        onSurfaceVariant: "#C0C8CD",
        outline: "#8A9297",
        inverseOnSurface: "#191C1E",
        inverseSurface: "#E1E2E5",
        inversePrimary: "#006685",
        shadow: "#000000",
        surfaceTint: "#6DD2FF",
        surfaceTintColor: "#6DD2FF",
    }
    public static ORANGE_LIGHTE = {
        primary: "#fbbd00",
        background: "#fffbff",
        surface: "#fffbff",
        accent: "#b0cfa9",
        error: "#ba1a1a",
        text: "#402d00",
        onSurface: "#1e1b16",
        disabled: "#4a6547",
        placeholder: "#fbbd00",
        backdrop: "#6b5d3f",
        notification: "#fbbd00",

    }
    /*
        public static ORANGE_LIGHTE = {
            primary: "#795900",
            onPrimary: "#FFFFFF",
            primaryContainer: "#FFDF9E",
            onPrimaryContainer: "#261A00",
            secondary: "#6B5D3F",
            onSecondary: "#FFFFFF",
            secondaryContainer: "#F5E0BB",
            onSecondaryContainer: "#241A04",
            tertiary: "#4A6547",
            onTertiary: "#FFFFFF",
            tertiaryContainer: "#CCEBC4",
            onTertiaryContainer: "#082008",
            error: "#BA1A1A",
            errorContainer: "#FFDAD6",
            onError: "#FFFFFF",
            onErrorContainer: "#410002",
            background: "#FFFBFF",
            onBackground: "#1E1B16",
            surface: "#FFFBFF",
            onSurface: "#1E1B16",
            surfaceVariant: "#EDE1CF",
            onSurfaceVariant: "#4D4639",
            outline: "#7F7667",
            inverseOnSurface: "#F7EFE7",
            inverseSurface: "#34302A",
            inversePrimary: "#FBBD00",
            shadow: "#000000",
            surfaceTint: "#795900",
            surfaceTintColor: "#795900",
        }
        */
    public static ORANGE_DARK = {
        primary: "#FBBD00",
        onPrimary: "#402D00",
        primaryContainer: "#5B4300",
        onPrimaryContainer: "#FFDF9E",
        secondary: "#D8C4A0",
        onSecondary: "#3B2F15",
        secondaryContainer: "#52452A",
        onSecondaryContainer: "#F5E0BB",
        tertiary: "#B0CFA9",
        onTertiary: "#1D361C",
        tertiaryContainer: "#334D30",
        onTertiaryContainer: "#CCEBC4",
        error: "#FFB4AB",
        errorContainer: "#93000A",
        onError: "#690005",
        onErrorContainer: "#FFDAD6",
        background: "#1E1B16",
        onBackground: "#E9E1D8",
        surface: "#1E1B16",
        onSurface: "#E9E1D8",
        surfaceVariant: "#4D4639",
        onSurfaceVariant: "#D0C5B4",
        outline: "#998F80",
        inverseOnSurface: "#1E1B16",
        inverseSurface: "#E9E1D8",
        inversePrimary: "#795900",
        shadow: "#000000",
        surfaceTint: "#FBBD00",
        surfaceTintColor: "#FBBD00",
/*        primary: "#344955",
        onPrimary: "#FFFFFF",
        primaryContainer: "#FFDBD0",
        onPrimaryContainer: "#2E140C",

        secondary: "#F9AA33",
        onSecondary: "#FFFFFF",
        secondaryContainer: "#F1DFD9",
        onSecondaryContainer: "#231917",
        tertiary: "#645E4D",
        onTertiary: "#FFFFFF",
        tertiaryContainer: "#EBE2CC",
        onTertiaryContainer: "#1F1B0E",
        error: "#BA1A1A",
        onError: "#FFFFFF",
        errorContainer: "#FFDAD6",
        onErrorContainer: "#410002",
        background: "#FFFBFF",
        onBackground: "#1E1B1A",
        surface: "#FFFBFF",
        onSurface: "#1E1B1A",
        surfaceVariant: "#EBE0DD",
        onSurfaceVariant: "#4C4544",
        outline: "#7D7573",
        shadow: "#000000",
        inverseSurface: "#33302F",
        inverseOnSurface: "#F7EFED",
        inversePrimary: "#EBBCAD",
        surfaceTint: "#7A564A",
        surfaceTintColor: "#7A564A"
*/
    }
    public static sherine = [{
        primary: "#344955",
        onPrimary: "#FFFFFF",
        primaryContainer: "#FFDBD0",
        onPrimaryContainer: "#2E140C",

        secondary: "#F9AA33",
        onSecondary: "#FFFFFF",
        secondaryContainer: "#F1DFD9",
        onSecondaryContainer: "#231917",
        tertiary: "#645E4D",
        onTertiary: "#FFFFFF",
        tertiaryContainer: "#EBE2CC",
        onTertiaryContainer: "#1F1B0E",
        error: "#BA1A1A",
        onError: "#FFFFFF",
        errorContainer: "#FFDAD6",
        onErrorContainer: "#410002",
        background: "#FFFBFF",
        onBackground: "#1E1B1A",
        surface: "#FFFBFF",
        onSurface: "#1E1B1A",
        surfaceVariant: "#EBE0DD",
        onSurfaceVariant: "#708090",
        outline: "#7D7573",
        shadow: "#000000",
        inverseSurface: "#33302F",
        inverseOnSurface: "#F7EFED",
        inversePrimary: "#EBBCAD",
        surfaceTint: "#7A564A",
        surfaceTintColor: "#7A564A",
        primaryVarient : "dodgerblue",
        dropwDownBackground : "#F9F6EE"
       },

    {
        primary: "#4682B4",
        onPrimary: "#ffffff",
        primaryContainer: "#004d68",
        onPrimaryContainer: "#c2e8ff",
        secondary: "#ffb959",
        onSecondary: "#462a00",
        secondaryContainer: "#643f00",
        onSecondaryContainer: "#ffddb6",
        tertiary: "#c9c1ea",
        onTertiary: "#312c4c",
        tertiaryContainer: "#484364",
        onTertiaryContainer: "#e5deff",
        error: "#FFB4AB",
        onError: "#690005",
        errorContainer: "#93000A",
        onErrorContainer: "#FFB4AB",
        background: "#000f00", // greenish black
        onBackground: "#e1e2e5",
        surface: "#36454F",
        onSurface: "#e1e2e5",
        surfaceVariant: "#36454f",
        onSurfaceVariant: "#ffffff",
        outline: "#978F8D",
        shadow: "#000000",
        inverseSurface: "#E8E1DF",
        inverseOnSurface: "#33302F",
        inversePrimary: "#7A564A",
        surfaceTint: "#EBBCAD",
        surfaceTintColor: "#EBBCAD",
        primaryVarient : "#4682B4",
        dropwDownBackground : "#495049"

    }]
}

// onPrimaryContainer : Not Selected DCHip text color
// onPrimary : Selected DCHip text, bottom tabs
// surface : card background
// onSurfaceVariant : textinput place holder
// surfaceVariant : textinput background color
// onSurfaceVariant ;; input text
// onBackground :: screen title and back button
// onSurface : all text's color in card