import { useEffect, useState } from "react";
import { HelperText } from "react-native-paper";
import { Dimensions, Image, Text } from "react-native";
import { useSelector } from "react-redux";
import ScaledImage from "../components/ScaledImage";
import { View } from "../components/Themed";

export default function ScreenImage() {
    const acc = useSelector(state => state.list.Dashboard)
    const [back, setBack] = useState(undefined)
    useEffect(() => {
        const d = acc.find(e => e.id == "Dashboard")
        setBack(d)
    }, [acc])
    return <View >
        <View style={{ flex: 1, backgroundColor: "black" }}>
            <Image
                source={{ uri: "https://i.pinimg.com/236x/72/5a/7c/725a7cab8f8328a6343f6d270d64435b.jpg" }}
                resizeMode="contain"
                style={{ height: (Dimensions.get("window").height / 2) }} />
        </View>
        <View style={{ padding: 10, backgroundColor: "transparent", position: "absolute", bottom: 0 }}>
            <Text style={{ fontSize: 24, color: back?.messageColor ? back?.messageColor : "black" }}>{back?.message}</Text>
            <Text style={{ color: back?.message1color ? back?.message1color : "black", textAlign: "right" }}>{back?.message1}</Text>
        </View>
    </View>


};